<template>
  <section>
    <div class="wrapper">
      <div>
        <h3>Account Settings</h3>
        <p>Manage your subscription, personal information and access chat room.</p>
      </div>
      <subscription />
      <div class="section-split">
        <discord />
        <creditcard />
      </div>
      <div class="section-split">
        <password />
        <email />
      </div>
    </div>
  </section>
</template>

<script>
import subscription from '@/components/account/subscription';
import discord from '@/components/account/discord';
import creditcard from '@/components/account/creditcard';
import email from '@/components/account/email';
import password from '@/components/account/password';

export default {
  name: "Account",
  components: {
    subscription,
    discord,
    creditcard,
    email,
    password
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 800px;
    margin: 0 auto;
    padding-bottom: 57px;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  h3 {
    margin: 45px 0 10px 0;
    font-size: 24px;
    font-size: 30px;
  }
  p {
    margin: 0 0 25px 0;
  }
  .section-split {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 25px 0 25px 0;
    @media(max-width: 868px) {
      flex-direction: column;
      margin: 25px 0 0 0 !important;
      &:last-child {
        margin: 0 0 25px 0 !important;
      }
      section {
        width: 100% !important;
        margin: 0 0 25px 0;
      }
    }
    section {
      width: 48%;
    }
  }
</style>
