var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$auth.isAuthenticated()),expression:"$auth.isAuthenticated()"}],staticClass:"journal"},[_c('div',{staticClass:"stats"},[_c('div',{staticClass:"table-card"},[_c('h5',[_vm._v("Current Trading Streak")]),_c('h3',[_vm._v("12 Green Days")]),_c('div',{staticClass:"grid-chart"},[_vm._m(0),_vm._l((Array(260).fill(1)),function(item,index){return _c('span',{key:index,class:{
              green: index % 4 === 0 || index % 5 === 0,
              red: index % 3 === 0 || index % 7 === 0,
              day: true
            }})})],2)]),_vm._m(1),_vm._m(2),_c('span',{staticClass:"beta"},[_vm._v("BETA")])]),_c('aside',[_c('header',[_c('h4',[_vm._v("Trade History")]),_c('button',{staticClass:"add-button"},[_vm._v("Add Trade "),_c('ion-icon',{attrs:{"name":"add-circle-outline"}})],1),_c('button',[_vm._v("Import Trades "),_c('ion-icon',{attrs:{"name":"cloud-upload-outline"}})],1)]),_c('tradelist',{attrs:{"trades":_vm.trades}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('span',[_vm._v("December")]),_c('span',[_vm._v("January")]),_c('span',[_vm._v("February")]),_c('span',[_vm._v("March")]),_c('span',[_vm._v("April")]),_c('span',[_vm._v("May")]),_c('span',[_vm._v("June")]),_c('span',[_vm._v("July")]),_c('span',[_vm._v("August")]),_c('span',[_vm._v("September")]),_c('span',[_vm._v("October")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-card"}),_c('div',{staticClass:"table-card"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-card"}),_c('div',{staticClass:"table-card"}),_c('div',{staticClass:"table-card"})])
}]

export { render, staticRenderFns }