<template>
  <section id="footer-videos">
    <div class="wrapper">
      <div class="main-content">
        <landing-call></landing-call>
        <div id="video-container">
          <div class="video-card">
            <div>
              <img src="https://i.vimeocdn.com/video/786885928-77b9b9ea6d514c8e7a2d6df9154dc8b8441a12056ffe6dc6386afef68d1a1b4c-d_640x360?r=pad">
            </div>
            <div class="description">
              <h3>Truly Understanding Level 2</h3>
              <p>Learn the basics of how to read level 2 and use it to your advantage in placing the right orders.</p>
            </div>
            <div class="tags">
              <span>Level 2</span>
              <span>Charting</span>
            </div>
          </div>
          <div class="video-card">
            <div>
              <img src="https://i.vimeocdn.com/video/828779733-d3e49a0583fa992a036dd9b736d5f04ca240ac8ac92ba036e5e4e7b5952a4a1e-d_640x360?r=pad">
            </div>
            <div class="description">
              <h3>How to get a Nevada SOS Filing</h3>
              <p>Accessing SOS filings is no longer a mystery. Get a step by step guide on how to purchase a filing to verify recent SOS changes.</p>
            </div>
            <div class="tags">
              <span>Filings</span>
              <span>SOS Activity</span>
              <span>Reverse Splits</span>
            </div>
          </div>
          <div class="video-card">
            <div>
              <img src="https://i.vimeocdn.com/video/786885479-594bb97f0e06a8713372ecd5026f87c42e3bfb679c832e831c187f96730ae760-d_640x360?r=pad">
            </div>
            <div class="description">
              <h3>Charting Introduction</h3>
              <p>Learn how to read charts like a pro and learn which types of indicators you should be looking at.</p>
            </div>
            <div class="tags">
              <span>Charting</span>
              <span>Essentials</span>
            </div>
          </div>
          <div class="video-card">
            <div>
              <img src="https://i.vimeocdn.com/video/786503142-5c394bb084a4d7d4aff66f06fa2d119f1e7080362cbafa33a03e538d49382df7-d_640x360?r=pad">
            </div>
            <div class="description">
              <h3>The Original Penny Stock Steps</h3>
              <p>One of my very first videos where it all started. Learn to run the OTCMethod steps in sequence right here.</p>
            </div>
            <div class="tags">
              <span>DD</span>
              <span>Steps</span>
            </div>
          </div>
          <div class="video-card">
            <div>
              <img src="https://i.vimeocdn.com/video/1292856536-eba80a2507334cf386e7e5d9bc460c87d5be131654faa7f82_1920x1080?r=pad">
            </div>
            <div class="description">
              <h3>How to Navigate Dilution Plays</h3>
              <p>A "Dilution Play" is a stock that has fallen to extreme lows on very small amounts of dilution due to a lack fo bid support. Here is what you need to know.</p>
            </div>
            <div class="tags">
              <span>Dilution</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import landingCall from '@/components/home/landing/landing-cta.vue'

export default {
  name: 'landingVideos',
  components: {
    landingCall
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 4rem auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 991px) {
      width: 868px;
  }
  @media(max-width: 868px) {
      width: 676px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
  .main-content {
    height: 43rem;
    @media(max-width: 768px) {
      height: auto;
    }
  }

  #footer-videos {
    position: relative;
  }
  #video-container {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0 5rem 0;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width: 768px) {
      flex-direction: column;
      align-items: center;
      position: static;
      transform: none;
    }
    .video-card {
      text-align: left;
      flex: 0 0 calc(20% - 1.5rem);
      position: relative;
      margin: 0 .75rem;
      @media(max-width: 1100px) {
        flex: 0 0 calc(25% - 2rem);
      }
      &:last-child {
        @media(max-width: 1100px) {
          display: none;
        }
      }
      img {
        width: 100%;
        height: 200px;
        border-radius: 8px;
        border: none;
        background: #E4E4E4;
        object-fit: cover;
        @media(max-width: 768px) {
          height: 400px;
        }
      }
      .description {
        font-size: 14px;
        margin: 0 0 1rem 0;
        h3 {
          font-size: 1rem;
          font-weight: 700;
          margin: .5rem 0 .5rem 0;
          line-height: 1.5rem;
        }
        p {
          margin: 0 0 0 0;
          line-height: 1.5rem;
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .tags {
        white-space: nowrap;
        display: flex;
        flex-wrap: wrap;
        span {
          margin: 0 .5rem .5rem 0;
          padding: 5px 10px 5px 10px;
          background: rgba(121, 191, 255, 0.27);
          color: #104475;
          font-size: 12px;
          font-weight: 600;
          border-radius: 4px;
        }
      }
    }
  }
</style>
