<template>
  <div class="videos">
    <div class="wrapper">
      <videos :hideHeaders="hideHeaders" :video-data="video" :ajax-more="true" :limit="20"  />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import videos from '@/components/home/default/videos.vue'

export default {
  name: 'VideosList',
  data() {
    return {
      hideHeaders: false,
      category: this.$route.path.split('/')[2],
      filter: this.$route.path.split('/')[3]
    }
  },
  components: {
    videos
  },
  computed: mapState({
    video: state => state.videos.videos.essentials
  })
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  .videos {
    margin: 45px 0 0 0;
  }
</style>
