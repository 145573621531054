<template>
  <section>
    <div class="terms">
      <div class="heading">
        <h3>Terms of Use</h3>
        <p>Updated June 5, 2019</p>
      </div>
      <div class="container wrapper">
        <p>By accessing the website at <a href="https://www.OTCmethod.com">OTCmethod</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
        <h3>Use License</h3>
        <ol type="a">
            <li>Permission is granted to temporarily download one copy of the materials (information or software) on OTCmethod's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
            <ol type="i">
                <li>modify or copy the materials;</li>
                <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                <li>attempt to decompile or reverse engineer any software contained on OTCmethod's website;</li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
            </ol>
            </li>
            <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by OTCmethod at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
        </ol>
        <h3>Disclaimer</h3>
        <ol type="a">
            <li>The materials on OTCmethod's website are provided on an 'as is' basis. OTCmethod makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
            <li>Further, OTCmethod does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
        </ol>
        <h3>Limitations</h3>
        <p>In no event shall OTCmethod or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on OTCmethod's website, even if OTCmethod or a OTCmethod authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
        <h3>Accuracy of materials</h3>
        <p>The materials appearing on OTCmethod's website could include technical, typographical, or photographic errors. OTCmethod does not warrant that any of the materials on its website are accurate, complete or current. OTCmethod may make changes to the materials contained on its website at any time without notice. However OTCmethod does not make any commitment to update the materials.</p>
        <h3>Links</h3>
        <p>OTCmethod has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by OTCmethod of the site. Use of any such linked website is at the user's own risk.</p>
        <h3>Modifications</h3>
        <p>OTCmethod may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
        <h3>Governing Law</h3>
        <p>These terms and conditions are governed by and construed in accordance with the laws of Texas and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        <h3>Cookie Policy</h3>
        <p>OTCmethod uses cookies to store filter data, user settings and preferences and other settings used to improve the user experience. We do not store personally identifying data in cookies.</p>
        
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Terms"
};
</script>

<style lang="less" scoped>
.terms {
  margin: 45px 0 0 0;
  .heading {
    h3 {
      margin: 0 0 15px 0;
      font-size: 24px;
      font-size: 30px;
    }
    p {
      margin: 0 0 0 0;
    }
  }
  .container {
    border-top: 1px solid #EDEDED;
    text-align: left;
    a {
		font-weight: 700;
		color: #104475;
    }
    h3 {
      font-size: 22px;
    }
    p , ul , ol{
      line-height: 30px;
    }
  }
  .wrapper {
    width: 800px;
    margin: 35px auto 0 auto;
    padding: 25px 0 57px;
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
}
</style>
