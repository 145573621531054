<template>
  <section>
    <form v-on:submit.prevent="submitForm">
      <div class="password-card card">
        <div>
          <h3>Change Password</h3>
          <p>Once you change your password, you will not be able to log in from any previous passwords.</p>
          <input v-model="password" type="password" autocomplete="new-password" placeholder="New Password">
          <input v-model="passwordConfirm" type="password" autocomplete="new-password" placeholder="Confirm Password">
        </div>
        <div class="toggle">
          <button :disabled="loading" type="submit">Submit</button>
          <span v-if="error">{{ error }}</span>
          <span v-if="success">{{ success }}</span>
        </div>
      </div>
    </form>
  </section>
</template>


<script>
import axios from 'axios';

export default {
  name: 'password',
  data() {
    return {
      password: null,
      passwordConfirm: null,
      error: null,
      success: null,
      loading: false
    }
  },
  methods: {
    submitForm() {
      this.validateForm();
      this.loading = true;
      if(!this.error) {
        this.submitPassword();
      }
    },
    submitPassword() {
      if(localStorage.getItem('user')) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/user/updatepassword',
          data: {
            password: this.password,
            userID: JSON.parse(localStorage.getItem('user'))['sub']
          }
        }).then((response) => {
            this.success = response.data.updated ? 'Your Password has been Updated' : (typeof response.data.error !== 'undefined' ? response.data.error : 'Something went Wrong');
            this.loading = false;
            this.password = null;
            this.passwordConfirm = null;
        }).catch((err) => {
          this.error = err;
          this.loading = false;
        });
      } else {
        this.error = 'No User Found';
      }
    },
    validateForm() {
      this.success = null;
      if(this.password !== null) {
        this.error = null;

        if(this.password !== '' && this.passwordConfirm) {
          if(this.password !== this.passwordConfirm) {
            this.error = 'Passwords must Match';
            this.loading = false;
          }
          if(this.password.length < 6) {
            this.error = 'Must be longer than 6 Characters';
            this.loading = false;
          }
        } else {
          this.error = 'Both fields are Required';
          this.loading = false;
        }
      }
    } 
  }
}
</script>

<style lang="less" scoped>
  .card {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow:  0 1px 3px rgba(31, 31, 34, 0.11);
    padding: 25px 25px 25px 25px;
  }
  input {
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0 15px 0;
  }
  .password-card {
    text-align: left;
    @media(max-width: 868px) {
      input[type="password"] {
        margin: 5px 15px 15px 0;
        width: 30%;
      }
    }
    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #EDEDED;
      padding: 15px 0 0 0;
      margin: 10px 0 0 0;
      button {
        margin: 0 0 0 0;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        border: none;
        background: none;
        color: #104475;
        cursor: pointer;
        padding: 2px 0 2px 0;
        transition: .2s all;
        &:hover {
          background: #79beff11;
          transition: .2s all;
        }
        span {
          font-weight: 900;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
    }
  }
</style>