<template>
  <div class="videos">
    <div class="wrapper">
      <playlists />
      <!-- <videos :video-data="tags" :hideHeaders="hideHeaders" :ajax-more="false" :limit="4" :section-title="'Filings'" :tag="'filings'" />
      <videos :video-data="tags" :hideHeaders="hideHeaders" :ajax-more="false" :limit="4" :section-title="'Charting'" :tag="'charting'" />
      <videos :video-data="tags" :hideHeaders="hideHeaders" :ajax-more="false" :limit="4" :section-title="'SOS activity'" :tag="'sosactivity'" /> -->
    </div>
    <div v-show="!$auth.isAuthenticated()">
      <landing-footer></landing-footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import playlists from '@/components/videos/playlists.vue'
import landingFooter from '@/components/videos/landing-footer.vue'
import videos from '@/components/home/default/videos.vue'

export default {
  name: 'Videos',
  data() {
    return {
      hideHeaders: false
    }
  },
  components: {
    playlists,
    videos,
    landingFooter
  },
  computed: mapState({
    videos: state => state.videos.videos.latest,
    tags: state => state.videos.tags
  })
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
</style>
