import axios from 'axios'

export default {
  getNotes() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/journal/notes',
      data: {}
    })
  },
  getTrades() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/journal/trades',
      data: {}
    })
  }
}