<template>
  <div class="callback"></div>
</template>

<script>
export default {
  name: 'Callback',
  mounted() {
    this.$auth.handleAuthentication().then(() => {
      this.$router.push('/');
      window.location.pathname = '/';
    });
  }
}
</script>