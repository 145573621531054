<template>
  <nav>
    <section class="main-wrapper" id="nav-wrapper">
      <logo />
      <div class="nav-link-main">
        <navlist />
      </div>
      <div id="nav-user">
        <ul v-show="!$auth.isAuthenticated()">
          <li class="sign-up"><a class="main-button" href="#" @click.prevent="login">Sign Up</a></li>
          <li><a href="#" @click.prevent="login">Sign In</a></li>
        </ul>
        <div id="nav-container" v-show="$auth.isAuthenticated()">
          <ul>
            <li class="sign-up" @click="clickManage"><button class="main-button"><ion-icon name="people-circle"></ion-icon>Account</button></li>
          </ul>
          <div id="nav-dropdown" v-show="showDropdown">
            <ul>
              <li><router-link to="/account"><ion-icon name="cog"></ion-icon>Manage</router-link></li>
              <li><a href="https://twitter.com/kickostocks" target="_blank"><ion-icon name="help-circle"></ion-icon>Support</a></li>
              <li @click.prevent="logout"><ion-icon name="log-out-outline"></ion-icon>Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <navlist class="navlinks-under" />
  </nav>
</template>

<script>
import logo from '@/components/logo.vue';
import navlist from '@/components/navlist.vue';
export default {
  name: 'navbar',
  components: {
    logo,
    navlist
  },
  props: ['element'],
  data() {
    return {
      showDropdown: false
    }
  },
  watch: {
    element: function(newValue , oldValue) {
      if(document.querySelector('#nav-wrapper') !== null && !document.querySelector('#nav-wrapper').contains(newValue)) {
        this.showDropdown = false;
      }
    },
    $route (to, from){
      this.showDropdown = false;
    },
  },
  methods: {
    login() {
      this.$auth.login();
    },
    logout() {
      this.$auth.logout();
    },
    clickManage() {
      if(!this.showDropdown) {
        // Set a click listener for inside the dropdown
        const clickHandler = (event) => {
          // Check if inside #nav-dropdown
          if(document.querySelector('#nav-container') !== null && !document.querySelector('#nav-container').contains(event.target)) {
            this.showDropdown = false;
            document.removeEventListener('click', clickHandler);
          }
        }
        document.addEventListener('click', clickHandler);
      }
      this.showDropdown = !this.showDropdown
    }
  }
}
</script>


<style lang="less" scoped>
  nav {
    // border-bottom: 1px solid #c2c2c2;
    box-shadow: inset -3px -2px 41px 5px rgba(14, 68, 117, 0.02), 0px 1px 2px 0px rgba(62, 62, 62, 0.13);
    background: linear-gradient(334deg, #ffffff, #fafafa);
    position: relative;
    z-index: 99;
    @media(max-width: 768px) {
      border: none;
    }
    #nav-wrapper {
      @media(max-width: 768px) {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #c2c2c2;
      }
    }
    .main-wrapper {
      padding: 0 1rem;
      @media(max-width: 1300px) {
        padding: 0 2rem;
      }
      @media(max-width: 1092px) {
        padding: 0 4rem;
      }
      @media(max-width: 676px) {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
    }
    section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 3px 0 3px 0;
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          text-decoration: none;
          color: #585858;
          font-weight: 700;
          &.router-link-exact-active {
            color: #1c2630 !important;
            font-weight: 600 !important;
          }
        }
      }
      #nav-logo {
        flex: 1 0 0;
        text-align: left;
      }
      #nav-links , .nav-link-main{
        flex: 1 0 0;
        @media(max-width: 768px) {
          display: none;
        }
        ul {
          padding: 0 0 0 0;
          li {
            margin: 0 8px 0 8px;
            a {
              font-weight: 600;
              color: #4E4E4E;
            }
          }
        }
      }
      #nav-user {
        position: relative;
        flex: 1 0 0;
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          li {
            display: flex;
            align-items: center;
            span {
              display: flex;
              align-items: center;
              ion-icon {
                font-size: 1rem;
                margin-right: .25rem;
              }
            }
            a {
              font-size: .825rem;
              font-weight: 500;
              display: flex;
              align-items: center;
            }
            ion-icon {
              margin: 0 .5rem 0 0;
              font-size: 1rem;
            }
          }
        }
        #nav-dropdown {
          position: absolute;
          right: 0;
          top: 38px;
          background: linear-gradient(324deg, #f3f3f3, #ffffff);
          border: 1px solid #c2c2c2;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.173);
          border-radius: 5px;
          text-align: left;
          z-index: 999;
          margin: .75rem 0 0 0;
          overflow: hidden;
          ul {
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            display: flex;
            flex-direction: column;
            li {
              margin: 0 0 0 0;
              padding: .35rem 4rem .35rem .5rem;
              cursor: pointer;
              font-weight: 500;
              transition: all .2s ease-in-out;
              &:hover {
                background: #ebebeb;
                transition: all .2s ease-in-out;
              }
              &:last-child {
                border-top: 1px solid #EAEAEA;
                margin: 0 0 0 0;
                color: #585858;
                padding: .5rem 4.5rem .5rem .5rem;
                font-size: .825rem;
                font-weight: 500;
                align-self: flex-start;
              }
            }
          }
        }
        ul {
          padding: 0 0 0 0;
          margin: 0.75rem 0;
          .sign-up {
            margin: 0 0 0 0;
            & + li {
              order: -1;
            }
          }
          li {
            margin: 0 20px 0 0;
          }
        }
      }
    }
  }
  @media(max-width: 568px) {
    #nav-links {
      li {
        display: none;
      }
      li:first-child {
        opacity: 0;
        display: block;
      }
    }
  }
</style>
