<template>
  <div class="home" v-if="!$auth.isAuthenticated()">
    <landingHeader />
    <landingCall />
    <landingStats />
    <landingVideos />
  </div>
</template>

<script>
import landingHeader from '@/components/home/landing/landing-header.vue'
import landingCall from '@/components/home/landing/landing-call.vue'
import landingStats from '@/components/home/landing/landing-stats.vue'
import landingVideos from '@/components/home/landing/landing-videos.vue'

export default {
  name: "Landing",
  components: {
    landingHeader,
    landingCall,
    landingStats,
    landingVideos
  },
  beforeMount() {
    // Get query string
    let queryString = window.location.search;
    if(queryString && queryString.indexOf('discount=email') !== -1) {
      this.$router.replace('/')
      localStorage.setItem('promo-access' , true);
    }
  },
};
</script>
