<template>
  <footer>
    <section class="main-wrapper">
      <div class="footer">
        <div>
          <span>Copyright &#169; OTCMethod</span>
        </div>
        <div class="links">
          <router-link to="/faq"><span>FAQ</span></router-link>
          <router-link to="/terms"><span>Terms of Use</span></router-link>
          <router-link to="/privacy"><span>Privacy Policy</span></router-link>
          <router-link to="/disclaimer"><span>Disclaimer</span></router-link>
          <a href="https://twitter.com/kickostocks" target="_blank"><span>Contact</span></a>
        </div>
      </div>
    </section>
  </footer>
</template>


<script>
export default {
  name: 'foot'
}
</script>

<style lang="less" scoped>
  footer {
    padding: 20px 0 20px 0;
    background: #000;
    span {
      color: #ffffff;
      font-weight: 600;
    }
    .main-wrapper {
      padding: 0 1rem;
      @media(max-width: 1300px) {
        padding: 0 2rem;
      }
      @media(max-width: 1092px) {
        padding: 0 4rem;
      }
      @media(max-width: 676px) {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      @media(max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
      }
      & > div > span {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #b7b7b7;
        font-weight: 500;
      }
      .links {
        @media(max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        a {
          margin: 0 0 0 15px;
          @media(max-width: 768px) {
            margin: 0 0 1rem 0;
          }
          span {
            font-weight: 500;
          }
          text-decoration: none;
          color: #FFF;
        }
      }
    }
  }
  @media(max-width: 568px) {
    .footer {
      flex-direction: column;
      .links {
        margin: 15px 0 0 0;
      }
    }
  }
</style>

