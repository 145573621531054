import articles from '../../api/articles';

const state = {
  latest: []
}

const actions = {
  getLatestArticles({ commit }) {
    articles.getArticles().then((response) => {
      commit('setArticles' , response.data)
    })
    .catch((err) => {
      this.error = err
    });
  },
  getLatestFreeArticles({ commit }) {
    articles.getFreeArticles().then((response) => {
      commit('setArticles' , response.data)
    })
    .catch((err) => {
      this.error = err
      console.log(err);
    });
  },
}

const getters = {
  getArticleNode(state , getters) {
    let title = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
      
    for(let i = 0, l = state.latest.length; i < l; i++) {
      let regex = state.latest[i].title.toLowerCase().replace(/ /g , '_').replace(/[^a-zA-Z0-9-_]/g, '');
      if(regex === title) {
        return state.latest[i];
      }
    }
  }
}

const mutations = {
  setArticles(state , articles) {
    state.latest = articles
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}