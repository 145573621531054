<template>
  <section>
    <div class="wrapper" v-show="$auth.isAuthenticated()">
      <div class="live">
        <frame />
        <!-- <chat /> -->
      </div>
    </div>    
    <div v-show="!$auth.isAuthenticated()">
      <landing />
    </div>
  </section>
</template>

<script>
import { mapState , mapActions } from 'vuex'
import frame from '@/components/stream/frame.vue'
// import chat from '@/components/stream/chat.vue'
import landing from '@/components/stream/landing.vue'
import videos from '@/components/home/default/videos.vue'

export default {
  name: 'Stream',
  components: {
    frame,
    videos,
    landing
  },
  computed: mapState({
    videos: state => state.videos.videos.latest
  })
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 1200px;
    margin: 55px auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  .live {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media(max-width: 1092px) {
      flex-direction: column;
    }
  }
  #vimeo-frame {
    width: 100%;
    position: relative;
  }
</style>
