<template>
  <div id="app" @click="clickEvent">
    <div id="nav">
      <navbar v-if="fullPage()" :element="clicked" />
    </div>
    <router-view />
    <router-view name="landing" />
    <div id="footer">
      <foot />
    </div>
  </div>
</template>

<script>
import navbar from '@/components/navbar.vue';
import foot from '@/components/footer.vue';

export default {
  name: 'app',
  data() {
    return {
      clicked: null
    }
  },
  components: {
    navbar,
    foot
  },
  created() {
    if(localStorage.getItem('accessToken')) {
      this.$store.dispatch('journal/getLatestNotes');
      this.$store.dispatch('videos/getLatestVideos' , {max: 100, more: null});
      this.$store.dispatch('videos/getLatestVideos' , {max: 100, more: null , playlists: ['dd' , 'essentials' , 'frontload_friday']});
      this.$store.dispatch('videos/getTags');
      this.$store.dispatch('articles/getLatestArticles');
      // this.$store.dispatch('videos/getPlaylists');
      // this.$store.dispatch('audio/getLatestAudio');
      // this.$store.dispatch('journal/getLatestTrades');
    } else {
      this.$store.dispatch('articles/getLatestFreeArticles');
      this.$store.dispatch('videos/getLatestVideos' , {max: 25, more: null});
    }
  },
  methods: {
    fullPage() {
      return window.location.pathname !== '/payment';
    },
    clickEvent(e) {
      this.clicked = e.toElement;
    }
  }
}
</script>


<style lang="less">
.beta {
  align-self: flex-start;
  margin: auto 0 1rem 1rem;
  display: flex;
  border: 1px solid #d26d20;
  border-radius: 5px;
  background: linear-gradient(45deg, #f48225, #f49a25);
  padding: 0.25rem 0.75rem;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  text-shadow: 1px 1px 1px #d06d178c;
  letter-spacing: 1px;
  box-shadow: inset 0px -2px 3px 1px #f47b25;
  font-size: 0.75rem;
}
.main-wrapper {
  padding: 0 2rem;
  max-width: 2000px;
  @media(max-width: 1300px) {
    padding: 0 4rem;
  }
  @media(max-width: 1092px) {
    padding: 0 6rem;
  }
  @media(max-width: 676px) {
    // padding-right: 1.5rem !important;
    // padding-left: 1.5rem !important;
  }
}
.main-button {
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF !important;
  background: #1b568f;
  box-shadow: inset 0px -1px 0px 0px #1f4466, 0px 2px 3px 0px rgba(0, 0, 0, 0.29), inset 0px 1px 0px 0px rgba(15, 116, 214, 0.671);
  padding: 7px 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  border: 1px solid #214b73;
  align-items: center;
  transition: .2s all;
  text-shadow: 1px 1px 1px #1f4466;
  &:hover {
    transition: .2s all;
    background: #1a5083;
    border: 1px solid #152f48;
    text-shadow: 1px 1px 1px #102234;
  }
  &:active {
    transition: .2s all;
    box-shadow: none;
    background: #123455;
    border: 1px solid #103353;
    color: #c0c0c0;
    text-shadow: 1px 1px 1px #09121c;
  }
  ion-icon {
    margin-right: .25rem;
  }
}
body {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
::selection {
  background: #84cdc7;
}
::-moz-selection {
  background: #84cdc7;
}
button:focus, input:focus, select:focus {
	outline: none;
}
div:focus {
  outline: none;
}
.prose {
  flex-grow: 1;
}
.v-md-editor__toolbar-item-save , .v-md-editor__toolbar-item-image , .v-md-editor__toolbar-item-sync-scroll , .v-md-editor__toolbar-item-toc , .v-md-editor__toolbar-item-code {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  animation: fadein 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  opacity: 0
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: black;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: heavy;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/fonts/Proxima-Nova/Proxima-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
p {
  font-weight: 300;
}
h3 {
  font-weight: 600;
}
button , input , textarea {
  font-family: "Inter", Helvetica, Arial, sans-serif;
}
#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  position: relative;
}
// #footer {
//   position: absolute;
//   width: 100%;
//   bottom: 0;
// }
</style>
