<template>
  <div id="nav-logo">
    <router-link to="/">
      <div class="logo">
        <span class="logo-bold">
          <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="black"/>
            <path d="M10.5 25V11L18 18L28 8" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.5 25L25.5 20" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>otc:method</span>
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style lang="less" scoped>
#nav-logo {
  font-family: 'Proxima Nova' , sans-serif;
  display: flex;
  a {
    text-decoration: none;
    color: #000000;
    &:hover .logo {
      transition: 0.3s;
      opacity: 0.7;
    }
  }
  span {
    font-weight: 700;
    font-size: 20px;
    white-space: nowrap;
    line-height: 21px;
  }
  .logo {
    display: flex;
    align-items: center;
    transition: 0.3s;
  }
  .logo-bold {
    display: flex;
    align-items: center;
  }
  .method {
    font-weight: 300;
    padding: 0 0 1px 2px;
  }
  svg {
    height: 20px;
    width: 20px;
    margin: 0 7px 0 0;
  }
}
</style>
