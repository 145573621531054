<template>
  <section>
    <div class="nav-links">
      <ul>
        <!-- Have grey to red live indicator circle next to link. On Stream page, show related videos under large stream video -->
        <!-- <li><a v-bind:class="{'router-link-exact-active': $route.name === 'stream'}" href="/stream">Stream</a></li> -->
        <li><router-link to="/videos"><ion-icon name="film-outline"></ion-icon>Videos</router-link></li>
        <li><router-link v-bind:class="{'router-link-exact-active': $route.name === 'journal'}" to="/journal"><ion-icon name="create-outline"></ion-icon>Journal</router-link></li>
        <!-- <li><router-link to="/trades"><ion-icon name="bar-chart-outline"></ion-icon>Trades</router-link></li> -->
        <li><router-link to="/affiliates"><ion-icon name="newspaper-outline"></ion-icon>Affiliates</router-link></li>
        <li><router-link to="/articles"><ion-icon name="newspaper-outline"></ion-icon>Articles</router-link></li>
        <li v-show="!$auth.isAuthenticated()"><router-link to="/features">Features</router-link></li>
        <li><router-link to="/media"><ion-icon name="tv-outline"></ion-icon>Media</router-link></li>
        <li><router-link to="/chat"><ion-icon name="people-outline"></ion-icon>Chat</router-link></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'navlist',
}
</script>


<style lang="less">
  .wrapper {
    .nav-links {
      @media(max-width: 768px) {
        display: none;
      }
    }
  }
  .navlinks-under {
    border-bottom: 1px solid #EAEAEA;
    @media(min-width: 768px) {
      display: none !important;
    }
    ul {
      margin: 5px 0 5px 0;
    }
  }
  .nav-links {
    flex: 1 0 0;
    ul {
      padding: 0 0 0 0;
      li {
        margin: 0 .25rem;
        a {
          font-weight: 500;
          color: #4E4E4E;
          display: flex;
          align-items: center;
          padding: .25rem .5rem;
          border-radius: 5px;
          transition: .2s all;
          &:hover {
            transition: .2s all;
            background: #e6e6e6;
            color: #5f5f5f;
          }
          ion-icon {
            margin: 0 .25rem 0 0;
            display: none;
          }
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: none;
        color: #2c3e50;
        font-weight: 400;
        &.router-link-exact-active {
          background: #000000;
          color: #ffffff;
          &:hover {
            background: #000000;
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
