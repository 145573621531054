<template>
  <div class="main-wrapper">
    <Modal 
      v-model="showModal" 
      modalStyle="
        font-family: 'Inter';
        width: 350px;
        border-radius: 5px;
        background: linear-gradient(324deg, #f3f3f3, #ffffff);
        border: 1px solid #c2c2c2;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        top: 10rem;
      "
    >
      <template #titlebar>
        <div class="modal-header">
          <h3>Delete Note</h3>
        </div>
      </template>
      <template #content>
        <div class="modal-content">
          <p>Are you sure you want to permanently delete this note? This action cannot be undone.</p>
          <div class="modal-buttons">
            <button @click="showModal = false">Cancel</button>
            <button @click="deleteNote()">Delete</button>
          </div>
        </div>
      </template>
    </Modal>
    <div id="notes-editor">
      <section class="label-bar" v-bind:class="{'mobile-show': showCategories}">
        <button @click="showCategories = !showCategories">Close</button>
        <ul>
          <li 
            v-bind:class="{selected: !categorySelected}"
            @click="categorySelected = null; filteredNoteList = null"
          >
            <ion-icon name="file-tray-full-outline"></ion-icon>All Notes
          </li>
          <li 
            v-for="(cat , index) in categories" 
            v-bind:class="{selected: categorySelected === cat.category_id}"
            :key="index"
            @click="selectCategory(cat.category_id)"
          >
            <ion-icon name="list-circle-outline"></ion-icon>{{ cat.category }}
            <button class="delete" @click="deleteCategory(cat.category_id)"><ion-icon name="trash-outline"></ion-icon></button>
          </li>
          <li class="add-category">
            <ion-icon name="add-circle"></ion-icon>
            <input 
              type="text" 
              :placeholder="categoryPlaceholder"
              maxlength="20"
              @click="addCategory"
              @input="categoryChange"
              v-on:keyup.enter="onEnter"
              v-model="categoryInput"
            />
            <span @click="onEnter" v-if="showSaveCategory"><svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Checkmark Done</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284"/></svg></span>
          </li>
        </ul>

        <div class="symbols" v-if="Object.keys(symbols).length !== 0">
          <h5>Symbols</h5>
          <div>
            <button @click="selectSymbol(symbol)" v-for="(symbol , index) in Object.keys(symbols)" :key="index">{{ symbol }}</button>
          </div>
        </div>
        <div class="symbols symbols-promo" v-if="Object.keys(symbols).length === 0">
          <h5>Stock Symbols</h5>
          <p>Save time by adding stock symbols to your notes with the $ cashtag and sort them in the sidebar.</p>
          <p>Click an example below to add your first one.</p>
          <div>
            <button v-for="(symbol , index) in [
              'FFZY',
              'EEENF',
              'BMBYF',
              'ALPP',
              'ENZC',
              'BLQC',
              'GRNF',
              'ILUS',
              'MJWL',
              'FORW',
              'MTRT',
              'LCLP',
              'BRLL',
              'GVSI',
              'WNFT',
              'DUTV',
              'AABB',
              'QIND',
            ]" :key="index" @click="clickedSymbol = symbol">{{ symbol }}</button>
          </div>
        </div>
        <span class="beta">BETA</span>
      </section>
      <aside v-bind:class="{'mobile-show': showNotes}">
        <button class="close-mobile" @click="showNotes = !showNotes">Close</button>
        <header>
          <h4>Journal</h4>
          <button v-if="noteList.length < 1000" @click="createNote">New <ion-icon name="add-circle-outline"></ion-icon></button>
        </header>
        <div class="empty symbols-promo symbols" v-if="!loading && (filteredNoteList || noteList).length === 0">
          <h5>No Notes Yet</h5>
          <p>Click the button above to create a new note</p>
        </div>
        <div class="logged-out-promo symbols-promo symbols" v-if="!this.$auth.isAuthenticated()">
          <h5>Save Your Notes</h5>
          <p>Create an account to start tracking your trade ideas!</p>
          <button @click.prevent="$auth.login()">Get Full Access</button>
        </div>
        <div id="note-container" v-if="!loading">
          <button v-if="selectedSymbol" class="button-none filter-symbol" @click="filteredNoteList = null; selectedSymbol = null">Filtered By Symbol {{ selectedSymbol }} <ion-icon name="remove-circle-outline"></ion-icon></button>
          <div 
            class="note" 
            v-bind:class="{selected: selectedNoteId === note.note_id}" 
            v-for="(note , index) in (filteredNoteList || noteList)" 
            v-bind:key="index"
            @click="selectNote(note.note_id)"
          >
            <span>{{ new Date(note.timestamp).toDateString() }}</span>
            <h3>{{ selectedNoteId === note.note_id ? selectedTitle : note.title }}</h3>
          </div>
        </div>
        <button v-if="!filteredNoteList && showMore && noteList.length > 99" class="button-none" @click="loadMore()">Load More <ion-icon name="duplicate-outline"></ion-icon></button>
      </aside>
      <section id="notes">
        <div class="mobile-controls">
          <button @click="showCategories = !showCategories">Categories</button>
          <button @click="showNotes = !showNotes">Notes</button>
        </div>
        <header>
          <div class="main-title">
            <input 
              class="title" 
              maxlength="65" 
              rows="1" 
              cols="84" 
              wrap="off" 
              type="text" 
              placeholder="Enter Title" 
              v-model="selectedTitle" 
              @input="updateTitle"
            />
            <div class="meta" id="category-container">
              <span class="datestamp">{{ new Date().toDateString() }}</span>
              <div class="dropdown-container" v-if="categories.length > 0">
                <span class="badge" @click="addCategoryDropdown">Add Category</span>
                <div class="dropdown" v-if="dropdownActive">
                  <h5>Categories</h5>
                  <ul>
                    <li 
                      v-for="(cat , index) in categories" 
                      :key="index" 
                      :class="{active: selectedCategories.indexOf(cat.category_id) !== -1}"
                      @click="toggleCategory(cat.category_id , selectedCategories.indexOf(cat.category_id) !== -1)"
                    >
                      <ion-icon class="selected" name="checkmark-done-circle-outline"></ion-icon>
                      <ion-icon class="unselected" name="ellipse-outline"></ion-icon>
                      <span>{{ cat.category }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <span @click="toggleCategory(cat , selectedCategories.indexOf(cat) !== -1)" v-for="(cat , index) in selectedCategories" :key="index" class="badge active" v-show="categoryReference[cat]"><ion-icon name="close-circle"></ion-icon>{{ categoryReference[cat] }}</span>
            </div>
          </div>
          <div class="button-container" v-if="selectedBody !== ''">
            <button class="button-none" @click="showModal = true" v-show="(filteredNoteList || noteList).length > 0">Delete <ion-icon name="trash-outline"></ion-icon></button>
            <!-- <span>Unsaved Changes</span> -->
            <button :class="{disabled: !selectedTitle || selectedTitle === ''}" @click="saveNote()">Save <ion-icon name="cloud-done-outline"></ion-icon></button>
          </div>
        </header>
        <editor 
          :symbolSelected="clickedSymbol" 
          :savedBody="savedBody" 
          :savedVideo="video"
          :value="selectedBody" 
          v-on:input="selectedBody = $event; savedBody = ($auth.isAuthenticated() ? false : true)" 
        />
      </section>
    </div>
  </div>
    <!-- <div v-show="!$auth.isAuthenticated()">
      <landing />
    </div> -->
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import editor from '@/components/journal/editor.vue'

export default {
  name: 'Journal',
  components: {
    editor
  },
  props: [
    'videoTitle'
  ],
  data() {
    return {
      // Mobile
      showCategories: false,
      showNotes: false,
      // Controls
      page: 0,
      showModal: false,
      showMore: true,
      showSaveCategory: false,
      clickedSymbol: null,

      // Categories
      categoryPlaceholder: 'Add Category',
      categoryInput: '',
      categorySelected: null,
      dropdownActive: false,
      categories: [],
      categoryReference: {},
      categoryCache: {},
      categoryNoteCache: {},

      // Current Note
      selectedTitle: '',
      selectedCategories: [],
      selectedBody: '',
      selectedNoteId: null,
      selectedDate: null,
      selectedCategory: null,
      selectedSymbol: null,
      savedBody: true,

      // Loading
      loading: true,
      loadingData: {
        symbols: true,
        categories: true
      },
      // TODO: Move this to global cache
      cache: {},
      symbols: {},
      video: null,
      symbolCache: {},
      symbolNoteCache: {},
      noteList: this.notes || [],
      filteredNoteList: null,
      uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '')
    }
  },
  watch: {
    notes: function(newVal) {
      if(newVal && newVal.length > 0) {
        this.noteList = newVal
        if(!this.selectedNoteId) this.selectNote(newVal[0].note_id)
      }
      this.loading = false
    },
  },
  mounted () {
    this.resetQueryPath();
    this.requestUserData();
    if(this.noteList.length === 0 && this.notes.length !== 0) {
      this.noteList = this.notes
      this.loading = false
      const videoCreated = this.handleVideoCreate();
      if(!videoCreated && !this.selectedNoteId) this.selectNote(this.noteList[0].note_id)
    }
  },
  computed: mapState({
    notes: state => state.journal.notes
  }),
  methods: {
    async loadMore() {
      this.page++;
      const res = await axios.post(`${this.uri}/api/journal/notes`, {page: this.page});
      if(res.data.notes) {
        this.noteList = this.noteList.concat(res.data.notes)
        if(!res.data.more) this.showMore = false
      }
    },
    handleVideoCreate() {
      if(this.$route.query.video) {
        this.createNote();
        this.selectedTitle = 'Notes for ' + this.$route.query.videoTitle;
        if(this.selectedTitle.length > 65) this.selectedTitle = this.selectedTitle.substring(0, 63) + '...';
        this.video = this.$route.query.video;
        this.$router.replace({ query: {
          saved: false
        }})

        return true
      }
      return false
    },
    requestUserData() {
      if(this.$auth.isAuthenticated()) {
        // User data requests
        axios.post(`${this.uri}/api/journal/symbols`, {}).then(symbolReq => {
          // User data responses
          if(symbolReq.data) {
            for(let i = 0; i < symbolReq.data.symbols.length; i++) {
              this.symbols[symbolReq.data.symbols[i].symbol] = symbolReq.data.symbols[i].note_ids
              this.symbolCache[symbolReq.data.symbols[i].note_ids] = symbolReq.data.symbols[i]
            }
            this.loadingData.symbols = false;
            // Category Data
            axios.post(`${this.uri}/api/journal/categories`, {}).then(categoryReq => {
              if(categoryReq.data) {
                this.categories = categoryReq.data.categories;
                for(let i = 0; i < this.categories.length; i++) {
                  this.categoryReference[this.categories[i].category_id] = this.categories[i].category
                }
                this.loadingData.categories = false;
                this.loading = false;
                if(this.notes.length === 0) {
                  this.createNote();
                }
              }
            })
          }
        });
      }
    },

    // Note Methods
    deleteNote() {
      axios.delete(`${this.uri}/api/journal/notes/delete` , {data: {
        note_id: this.selectedNoteId
      }})
      .then(x => {
        this.noteList = this.noteList.filter(note => note.note_id !== this.selectedNoteId)
        if(this.noteList.length > 0) this.selectNote(this.noteList[0].note_id)
        else {
          this.selectedNoteId = null
          this.selectedTitle = ''
          this.selectedBody = ''
          this.selectedCategories = []
        }
      })
      .catch(err => {
        console.log(err)
      })
      this.showModal = false;
    },
    createNote() {
      this.selectedNoteId = this.generateID('note');
      this.selectedBody = '';
      this.selectedTitle = 'New Journal Entry'
      this.selectedDate = new Date().getTime();
      this.selectedCategories = [];
      this.categoryNoteCache[this.selectedNoteId] = [];
      this.noteList.unshift({
        title: this.selectedTitle,
        body: this.selectedBody,
        timestamp: this.selectedDate,
        note_id: this.selectedNoteId
      })
      this.cache[this.selectedNoteId] = this.selectedBody;
      this.loading = false
      this.savedBody = false
    },
    async selectNote(note_id) {
      if(note_id) {
        const foundNote = (this.filteredNoteList || this.noteList).find(x => x.note_id === (note_id));
        if(foundNote) {
          this.selectedNoteId = foundNote.note_id
          this.selectedTitle = foundNote.title
          this.selectedDate = foundNote.timestamp
          this.selectedCategories = foundNote.categories || []
          if(typeof this.cache[foundNote.note_id] !== 'undefined') {
            this.selectedBody = this.cache[foundNote.note_id]
            if(this.categoryNoteCache[this.selectedNoteId]) this.selectedCategories = this.categoryNoteCache[this.selectedNoteId];
          } else {
            // Get metadata from server
            const body = await axios.post(`${this.uri}/api/journal/metadata` , {
              note_id: note_id,
            })

            if(body.data.metadata) {
              this.selectedBody = body.data.metadata.body
              if(body.data.metadata.categories) this.selectedCategories = body.data.metadata.categories
              this.categoryNoteCache[this.selectedNoteId] = this.selectedCategories;
              this.cache[foundNote.note_id] = this.selectedBody
            }
          }
        }
      }
    },
    generateID(name) {
      let newID = name + '_';
      let selection = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      
      for(let i = 0 , l = 16; i < l; i++) {
        newID += selection.charAt(Math.floor(Math.random() * selection.length));
      }

      return newID
    },
    saveNote() {
      if(!this.savedBody && this.selectedTitle && this.selectedTitle.length > 0) {
        this.savedBody = true;
        this.parseSymbols(this.selectedBody);
        this.parseCategories(this.selectedCategories);
        this.resetQueryPath();
        const noteID = this.selectedNoteId === null ? this.generateID('note') : this.selectedNoteId;
        const newNote = {
          title: this.selectedTitle,
          body: this.selectedBody,
          timestamp: new Date().getTime(),
          note_id: noteID,
          symbols: this.symbolNoteCache[noteID],
          categories: this.selectedCategories
        }
        axios.post(`${this.uri}/api/journal/notes/update`, newNote).then(res => {
          if(!this.selectedNoteId) {
            this.noteList.unshift(newNote)
          } else {
            const nodeIndex = this[this.filteredNoteList ? 'filteredNoteList' : 'noteList'].findIndex(x => x.note_id === this.selectedNoteId)
            this[this.filteredNoteList ? 'filteredNoteList' : 'noteList'].splice(nodeIndex, 1, newNote)
          }
          this.cache[noteID] = this.selectedBody;
        });
      }
    },
    
    // Symbol Methods
    parseSymbols(body) {
      // Get symbol inside <span class="stock-symbol">
      const regex = /<span class="stock-symbol">(.+?)<\/span>/g;
      let matchSymbol = body.match(regex)
          matchSymbol = matchSymbol ? matchSymbol
            .map(x => x.replace(/<span class="stock-symbol">/g, '').replace(/<\/span>/g, ''))
            .filter(x => 
              typeof x === 'string' && 
              x.length <= 7 && 
              x.length >= 3 &&
              /^[\a-zA-Z^.-]*$/.test(x)
            ) : matchSymbol;
      if(matchSymbol) {
        // Set default cache for symbol
        this.symbolNoteCache[this.selectedNoteId] = this.symbolNoteCache[this.selectedNoteId] || [];
        // Remove symbols that are no longer active
        for(let i = 0, l = this.symbolNoteCache[this.selectedNoteId].length; i < l; i++) {
          const oldSymbol = this.symbolNoteCache[this.selectedNoteId][i];
          if(matchSymbol.indexOf(oldSymbol) === -1) {
            // Remove symbol from cache
            this.symbols[oldSymbol] = this.symbols[oldSymbol].filter(x => x !== this.selectedNoteId);
            this.symbolCache[oldSymbol] = this.symbolCache[oldSymbol].filter(x => x.note_id !== this.selectedNoteId);
          }
        }
        // Update symbol reference and cache
        let newSymbols = [];
        for(let i = 0, l = matchSymbol.length; i < l; i++) {
          const symbol = matchSymbol[i].replace(/<span class="stock-symbol">/g, '').replace(/<\/span>/g, '').toUpperCase();
            newSymbols.push(symbol);
            // Update cache with new symbols
          this.symbolCache[symbol] = this.symbolCache[symbol] || [];
          if(this.symbolNoteCache[this.selectedNoteId].indexOf(symbol) === -1) {
            let cacheNote = {
              note_id: this.selectedNoteId,
              title: this.selectedTitle,
              timestamp: this.selectedDate,
              body: this.selectedBody
            }
            this.symbolNoteCache[this.selectedNoteId].push(symbol);
            this.symbolCache[symbol].push(cacheNote);
            this.cache[this.selectedNoteId] = this.selectedBody;
          }
          // Update symbol list with note_id reference
          if(!this.symbols[symbol]) this.symbols[symbol] = [this.selectedNoteId]; 
          else {
            this.symbols[symbol] = this.symbols[symbol].filter(x => x !== this.selectedNoteId);
            this.symbols[symbol].push(this.selectedNoteId);
          }
        }
        this.symbolNoteCache[this.selectedNoteId] = newSymbols;
      }
    },
    selectSymbol(symbol) {
      // Save symbol in cache with key as AAPL: [{note_id: note_id, body: body}]
      if(this.symbolCache[symbol]) {
        this.filteredNoteList = this.symbolCache[symbol];
        this.selectedSymbol = symbol;
      } else {
        // Select symbol, send request to server with node ids
        axios.post(`${this.uri}/api/journal/symbols/notes`, {
          symbol: symbol
        }).then(res => {
          this.selectedSymbol = symbol;
          this.filteredNoteList = res.data.notes;
          this.symbolCache[symbol] = res.data.notes;
        })
      }
      this.categorySelected = null;
    },
    resetQueryPath() {
      if(this.$router.currentRoute.query.saved) {
        this.$router.replace({ query: {
          saved: undefined
        }})
      }
    },

    // Category Methods
    parseCategories(categories) {
      // Remove categories that are no longer active
      if(this.categoryNoteCache[this.selectedNoteId]) {
        for(let i = 0, l = this.categoryNoteCache[this.selectedNoteId].length; i < l; i++) {
          const oldCategory = this.categoryNoteCache[this.selectedNoteId][i];
          if(categories.indexOf(oldCategory) === -1) {
            // Remove category from cache
            if(this.categoryCache[oldCategory]) this.categoryCache[oldCategory] = this.categoryCache[oldCategory].filter(x => x.note_id !== this.selectedNoteId);
          }
        }
      }
      for(let i = 0, l = categories.length; i < l; i++) {
        // if(!this.categoryCache[categories[i]]) this.categoryCache[categories[i]] = [];
        if(this.categoryCache[categories[i]]) {
          const catIndex = this.categoryCache[categories[i]].findIndex(x => x.note_id === this.selectedNoteId);
          let cacheNote = {
            note_id: this.selectedNoteId,
            title: this.selectedTitle,
            timestamp: this.selectedDate,
            body: this.selectedBody
          }
          if(catIndex === -1) {
            this.categoryCache[categories[i]].push(cacheNote);
          } else {
            this.categoryCache[categories[i]].splice(catIndex, 1, cacheNote);
          }
        }
      }
      this.categoryNoteCache[this.selectedNoteId] = categories;
      if(this.filteredNoteList) this.filteredNoteList = this.categoryCache[this.categorySelected];
    },
    addCategoryDropdown() {
      this.dropdownActive = !this.dropdownActive;
      const clickHandler = (event) => {
        // Check if inside #nav-dropdown
        if(document.querySelector('#category-container') !== null && !document.querySelector('#category-container').contains(event.target)) {
          this.dropdownActive = false;
          document.removeEventListener('click', clickHandler);
        }
      }
      document.addEventListener('click', clickHandler);
    },
    addCategory() {
      this.categoryPlaceholder = 'Enter Name';
    },
    deleteCategory(category_id) {
      this.categories = this.categories.filter(x => x.category_id !== category_id);
      delete this.categoryReference[category_id];
      // this.categoryReference[category] = [];
      // this.categoryNoteCache[this.selectedNoteId] = this.categoryNoteCache[this.selectedNoteId].filter(x => x !== category);
      axios.post(`${this.uri}/api/journal/categories/update`, {
        category_id: category_id,
        delete: 'true'
      }).then(res => {
        this.selectedCategory = null;
        this.categorySelected = null;
        this.filteredNoteList = null;
      });
    },
    selectCategory(category_id) {
      this.categorySelected = category_id;
      this.selectedSymbol = null;
      if(this.categoryCache[category_id]) {
        this.filteredNoteList = this.categoryCache[category_id];
      } else {
        if(this.$auth.isAuthenticated()) {
          axios.post(`${this.uri}/api/journal/categories/notes`, {
            category_id: category_id
          }).then(res => {
            this.filteredNoteList = res.data.notes;
            this.categoryCache[category_id] = res.data.notes;
          }).catch(e => {
            this.filteredNoteList = [];
          });
        }
      }
    },
    toggleCategory(category_id , remove) {
      this.savedBody = false;
      if(remove) {
        this.selectedCategories = this.selectedCategories.filter(cat => cat !== category_id)
      } else {
        this.selectedCategories.push(category_id)
      }
    },
    categoryChange(e) {
      if(e.target.value === '') this.showSaveCategory = false;
      else this.showSaveCategory = true;
      this.categoryInput = e.target.value;
    },
    onEnter() {
      if(this.categoryInput !== '') {
        const catInfo = {
          category: this.categoryInput,
          category_id: this.generateID('category')
        }
        this.selectCategory(catInfo.category_id);
        this.categoryReference[catInfo.category_id] = this.categoryInput;
        this.categoryInput = '';
        this.showSaveCategory = false;
        this.categoryPlaceholder = 'Add Category';
        this.categories.push(catInfo);
        if(this.$auth.isAuthenticated()) axios.post(`${this.uri}/api/journal/categories/update`, catInfo);
      }
    },
    updateTitle(e) {
      this.savedBody = false
      if((this.filteredNoteList || this.noteList).length === 0) {
        const body = this.selectedBody;
        this.createNote();
        this.selectedTitle = e.data;
        this.selectedBody = body;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main-wrapper {
  @media (max-width: 1300px) {
    padding: 0 2rem;
  }
}
  .disabled {
    cursor: not-allowed;
  }
  #note-container {
    margin: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .button-none {
      justify-content: center;
      border: 1px solid #f3f3f3;
      align-self: center;
      margin-bottom: 1rem;
      &.filter-symbol {
        background: #e6e6e6;
      }
    }
    .note {
      margin: 0 0 0 0;
      border-radius: 5px;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      cursor: pointer;
      transition: .2s all;
      &:hover {
        background: #dadada;
        transition: .2s all;
      }
      &.selected {
        background: #000000;
        span {
          color: #dfdfdf;
        }
        h3 {
          color: #FFF;
        }
      }
      span {
        align-self: flex-start;
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #3a3a3a;
      }
      h3 {
        margin: 0.25rem 0 0 0;
        text-align: left;
        font-size: .825rem;
        font-weight: 600;
        line-height: 1.25rem;
        overflow-wrap: break-word;
        color: #000;
      }
      p {
        margin: .5rem 0 0 0;
        text-align: left;
        font-size: .825rem;
        font-weight: 400;
        line-height: 1.25rem;
        overflow-wrap: break-word;
      }
    }
    .note-new {
      background: #0e4475;
    }
  }
  .button-container {
    display: flex;
    align-items: center;
    button {
      margin-left: .25rem;
    }
  }

  .label-bar {
    display: flex;
    flex-direction: column;
    flex: 0 0 18%;
    background: #fafafa;
    border-right: 1px solid #c2c2c2;
    box-shadow: inset -10px 0 19px -11px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    & > button {
      display: none;
    }
    @media(max-width: 1300px) {
      flex: 0 0 22%;
    }
    @media(max-width: 1100px) {
      flex: 0 0 26%;
    }
    @media (max-width: 991px) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 999;
      display: none;
      &.mobile-show {
        display: flex;
        & > button {
          margin: 1rem 0 0 1rem;
          align-self: flex-start;
        }
      }
    }
    ul {
      list-style: none;
      display: flex;
      padding: 1rem;
      margin: 0;
      flex-direction: column;
      li {
        padding: .5rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1rem;
        color: #000;
        cursor: pointer;
        transition: .2s all;
        border-radius: 5px;
        .delete {
          margin-left: auto;
          font-size: .825rem;
          color: #5f5f5f;
          display: none;
          padding: 1px .75rem;
          border-radius: 3px;
          transition: .2s all;
          background: none;
          border: none;
          box-shadow: none;
          ion-icon {
            margin: 0 0 0 0;
          }
          &:hover {
            background: #c2c2c2;
            transition: .2s all;
            color: #585858;
          }
        }
        &:hover {
          background: #dadada;
          transition: .2s all;
          .delete {
            display: flex;
            align-items: center;
          }
        }
        &.add-category {
          color: #7b7b7b;
          position: relative;
          &:hover {
            background: initial;
          }
          span {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: absolute;
            right: 0;
            border-radius: 5px;
            padding: 0.125rem;
            font-weight: 600;
            cursor: pointer;
            letter-spacing: 1px;
            color: #ffffff;
            font-size: 0.75rem;
            border: 1px solid #19b096;
            text-shadow: 1px 1px 1px #3a98788c;
            background: linear-gradient(45deg, #2cc498, #1bbb98);
            box-shadow: inset 0px -2px 3px 1px rgb(45 182 177), 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
            // border: 1px solid #19b079;
            // text-shadow: 1px 1px 1px #3a98788c;
            // background: linear-gradient(45deg, #2cc478, #1bbb6d);
            // box-shadow: inset 0px -2px 3px 1px rgb(45 182 116), 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
            svg {
              width: 15px;
              height: 15px;
              filter: drop-shadow( 1px 1px 1px #3a98788c);
            }
          }
          input {
            background: none;
            border: none;
            font-size: 1rem;
            padding: 0;
            font-weight: 500;
          }
        }
        &.selected {
          background: #000000;
          color: #fff;
          .delete {
            color: #FFF;
            &:hover {
              background: #494949;
            }
          }
        }
        ion-icon {
          margin: 0 .5rem 0 0;
        }
      }
    }

    .symbols-promo {
      margin: 1rem;
      p {
        margin: 0 0 1rem 0;
      }
    }
  }
  .symbols {
    display: flex;
    padding: 1rem;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      button {
        margin: 0 0.25rem 0.25rem 0;
        -webkit-box-flex: 0;
        flex: 0 0 calc(20% - 0.2rem);
        display: block;
        text-align: center;
        border-radius: 3px;
        font-weight: 400;
        transition: 0.2s all;
        background: #e6e6e6;
        padding: 0.25rem 0;
        box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.18);
        @media(max-width: 1600px) {
          font-size: .565rem;
        }
        &:hover {
          transition: .2s all;
          background: #dddddd;
          border: 1px solid #b1b1b1;
        }
        &:active {
          transition: .2s all;
          box-shadow: none;
          background: #d0d0d0;
          border: 1px solid #8e8e8e;
          color: #434343;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
  .symbols-promo {
    background: linear-gradient(324deg, #f3f3f3, #ffffff);
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.099);
    margin: 0 0 1rem 0;
    p {
      text-align: left;
      margin: 0 0 0 0;
      font-size: .9rem;
      font-weight: 400;
      color: #6d6d6d;
      line-height: 1.25rem;
    }
  }
  h5 {
    margin: 0 0 0.5rem 0;
    color: #6b6b6b;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
  }
  #notes-editor {
    display: flex;
    margin: 0 -2rem;
    height: calc(100vh - 24px - 62px - 1.5rem);
    overflow: hidden;
    position: relative;
    aside {
      display: flex;
      flex-direction: column;
      margin: 0 1rem 0 0;
      overflow-y: scroll;
      flex: 0 0 15%;
      border-right: 1px solid #c2c2c2;
      background: #f3f3f3;
      padding: 0 1rem 0 1rem;
      box-shadow: inset -10px 0 19px -11px rgba(0, 0, 0, 0.16);
      .close-mobile {
        display: none;
      }
      @media(max-width: 1300px) {
        flex: 0 0 19%;
      }
      @media(max-width: 1100px) {
        flex: 0 0 23%;
      }
      @media (max-width: 991px) {
        position: absolute;
        width: calc(100% - 2rem);
        height: 100%;
        z-index: 999;
        display: none;
        &.mobile-show {
          display: flex;
          & > button {
            margin: 1rem 0 0 0;
            align-self: flex-start;
          }
        }
      }
      header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 0 0;
        margin: 0 0 1rem 0;
        h4 {
          margin: 0 0 0 0;
          font-size: 1.5rem;
          color: #000;
        }
        a {
          text-decoration: none;
        }
      }
      h1 {
        margin: 0 0 0 0;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
      }
      .logged-out-promo {
        button {
          margin-top: 1rem;
          font-weight: 700;
          font-size: 12px;
          color: #FFFFFF !important;
          background: #1b568f;
          box-shadow: inset 0px -1px 0px 0px #1f4466, 0px 2px 3px 0px rgba(0, 0, 0, 0.29), inset 0px 1px 0px 0px rgba(15, 116, 214, 0.671);
          padding: 7px 0.75rem;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          border: 1px solid #214b73;
          align-items: center;
          transition: .2s all;
          text-shadow: 1px 1px 1px #1f4466;
          &:hover {
            transition: .2s all;
            background: #1a5083;
            border: 1px solid #152f48;
            text-shadow: 1px 1px 1px #102234;
          }
          &:active {
            transition: .2s all;
            box-shadow: none;
            background: #123455;
            border: 1px solid #103353;
            color: #c0c0c0;
            text-shadow: 1px 1px 1px #09121c;
          }
        }
      }
    }
  }
  button {
    font-weight: 600;
    font-size: 12px;
    color: #5f5f5f;
    padding: 0.35rem 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    transition: 0.2s all;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.185), 0px 2px 5px rgba(0, 0, 0, 0.073);
    &:hover {
      transition: .2s all;
      background: #f3f3f3;
      border: 1px solid #c3c3c3;
    }
    &:active {
      transition: .2s all;
      box-shadow: none;
      background: #f0f0f0;
      border: 1px solid #a4a4a4;
      color: #4d4d4d;
    }
    ion-icon {
      margin-left: .25rem;
    }
    &.button-none {
      background: none;
      border: 1px solid #FFF;
      box-shadow: none;
      transition: .2s all;
      &:hover {
        transition: .2s all;
        border: 1px solid #e8e8e8;
        box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.185);
      }
      &:active {
        transition: .2s all;
        box-shadow: none;
        background: #f0f0f0;
        border: 1px solid #a4a4a4;
        color: #4d4d4d;
      }
    }
  }
  .badge {
    display: flex;
    font-size: .75rem;
    font-weight: 600;
    padding: .25rem;
    border-radius: 5px;
    background: #e6e6e6;
    color: #5f5f5f;
    align-items: center;
    cursor: pointer;
    &.active {
      // box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.18);
      // border: 1px solid #c2c2c2;
    }
    ion-icon {
      margin: 0 .25rem 0 0;
    }
  }

  #notes {
    flex: 0 0 calc(67% - 4.125rem);
    display: flex;
    flex-direction: column;
    @media(max-width: 1300px) {
      flex: 0 0 calc(59% - 4.125rem);
    }
    @media(max-width: 1100px) {
      flex: 0 0 calc(51% - 4.125rem);
    }
    @media(max-width: 991px) {
      position: absolute;
      background: white;
      padding: 0 1rem;
    }
    .mobile-controls {
      align-items: center;
      margin: 1rem 0 0 0;
      display: none;
      @media(max-width: 991px) {
        display: flex;
      }
      button {
        margin: 0 1rem 0 0;
      }
    }
    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem 0 1rem 0;
      .main-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 100%;
        .meta {
          margin: .5rem 0 0 0;
          display: flex;
          align-items: center;
        }
        .datestamp {
          display: flex;
          align-items: center;
          color: #757575;
        }
      }
      .title {
        resize: none;
        font-size: 1.5rem;
        font-weight: 700;
        background: none;
        border: none;
        width: 80%;
        height: 1.75rem;
        padding: 0;
        outline: none;
      }
    }
  }

  .modal-header {
    padding: 1rem 1rem 0 1rem;
    h3 {
      margin: 0 0 0 0;
      font-weight: 600;
    }
  }
  .modal-content {
    padding: 0 1rem 1rem 1rem;
    p {
      color: #6d6d6d;
      line-height: 1.5rem;
      font-weight: 400;
      margin: .5rem 0 0 0;
    }
    .modal-buttons {
      display: flex;
      align-items: center;
      margin: 1rem 0 0 0;
      button {
        width: 100%;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        justify-content: center;
        &:first-child {
          margin: 0 .5rem 0 0;
        }
        &:last-child {
          color: #FFF;
          margin: 0 0 0 0.5rem;
          border: 1px solid #b01919;
          text-shadow: 1px 1px 1px #983a3a8c;
          background: linear-gradient(45deg, #c42c2c, #ef1515);
          box-shadow: inset 0px -2px 3px 1px rgb(182 45 45);
        }
      }
    }
  }

  .dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    &::before {
      content: "•";
      margin: 0 0.5rem;
      font-size: 0.75rem;
      line-height: 4px;
      transform: translateY(-34%);
    }
    .badge {
      margin: 0 .5rem 0 0;
    }
    .dropdown {
      position: absolute;
      background: linear-gradient(324deg, #f3f3f3, #ffffff);
      border: 1px solid #c2c2c2;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.173);
      border-radius: 5px;
      padding: .5rem;
      z-index: 99;
      right: 0;
      top: .5rem;
      width: 150px;
      display: flex;
      flex-direction: column;
      transform: translateX(100%);
      h5 {
        margin: 0 0 .75rem 0;
        text-align: left;
      }
      ul {
        list-style: none;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        display: flex;
        flex-direction: column;
        li {
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-weight: 500;
          color: #000;
          cursor: pointer;
          transition: .2s all;
          padding: .25rem .25rem;
          border-radius: 5px;
          &:hover {
            background: #dadada;
            transition: .2s all;
          }
          .selected {
            display: none;
          }
          &.active {
            background: #000;
            color: #FFF;
            .selected {
              display: block;
            }
            .unselected {
              display: none;
            }
          }
          ion-icon {
            margin: 0 .5rem 0 0;
          }
        }
      }
    }
  }
</style>
