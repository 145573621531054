<template>
  <section>
    <div class="article-body">
      <div class="latest" v-if="articleData">
        <a :href="'/articles/' + articleData.title.toLowerCase().replace(/ /g , '_').replace(/[^a-zA-Z0-9-_]/g, '')"></a>
        <div class="card-left">
          <h3>{{ articleData.title }}</h3>
          <p>{{ articleData.preview }}</p>
          <button>Read More</button>
        </div>
        <div v-if="articleData.img !== ''" class="card-right" :style="'background-image: url(' + articleData.img + ');'"></div>
        <svg v-if="articleData.img === ''" class="card-right" viewBox="0 0 620 308" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect fill="#F4F4F4"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M357.73 116.943C360.233 114.44 360.233 110.381 357.73 107.878C355.226 105.374 351.168 105.374 348.664 107.878L310.308 146.234L282.784 120.545C280.918 118.803 278.197 118.334 275.855 119.352C273.514 120.369 272 122.678 272 125.231V185.06C272 188.601 274.87 191.471 278.41 191.471C281.951 191.471 284.821 188.601 284.821 185.06V139.982L306.088 159.832C308.613 162.189 312.552 162.121 314.995 159.678L357.73 116.943ZM348.924 163.693C348.924 160.152 346.054 157.282 342.513 157.282C338.973 157.282 336.103 160.152 336.103 163.693V185.06C336.103 188.601 338.973 191.471 342.513 191.471C346.054 191.471 348.924 188.601 348.924 185.06L348.924 163.693Z" fill="#D0D0D0"/>
        </svg>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'latest',
  props: ['articleData']
}
</script>

<style lang="less" scoped>
  .latest {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 30px 30px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    font-weight: 600;
    font-size: 14px;
    height: 300px;
    position: relative;
    @media(max-width: 868px) {
      flex-direction: column;
      height: auto;
      button {
        display: none;
      }
    }
    h3 {
      font-size: 24px;
      text-align: left;
      margin: 0 0 .75rem 0;
    }
    p {
      text-align: left;
      font-size: 1rem;
      line-height: 2rem;
      font-weight: 400;
      color: #2c3e50e0;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
    }
    .card-left {
      flex: 0 0 50%;
      position: relative;
      margin-right: 1rem;
      button {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px 35px 10px 35px;
        color: #FFF;
        background: #104475;
        font-weight: 700;
        font-size: 14px;
        border: none;
        border-radius: 4px;

      }
    }
    .card-right {
      flex: 0 0 calc(50% - 1rem);
      background-size: cover;
      background-position: center;
      background-color: #F4F4F4;
    }
  }
</style>
