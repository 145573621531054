<template>
  <div>
    <h6>As low as $49.99/month</h6>
    <h2>Get Started Now</h2>
    <p class="tagline">Don't waste any more time searching for the knowledge necessary to succeed in the OTC.</p>
    <div>
      <button @click.prevent="login">Join OTC:Method</button>
      <router-link class="more-info" to="/features"><button>More Info</button></router-link>
    </div>
  </div>
</template>


<script>
export default {
  name: 'landingCall',
  methods: {
    login() {
      this.$auth.login();
    }
  }
}
</script>

<style lang="less" scoped>
  h2 {
    font-size: 2.5rem;
    margin: 0 0 1rem 0;
    color: #000;
  }
  h6 {
    margin: 0 0 .5rem 0;
    color: #818181;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .825rem;
  }
  .tagline {
    margin: 0 auto 1.25rem auto;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    width: 45%;
    @media(max-width: 991px) {
      width: 65%;
    }
    @media(max-width: 568px) {
      width: 100%;
    }
  }
  button {
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    background: #58AAA3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 10px 25px 10px 25px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    @media(max-width: 1100px) {
      margin: 0 auto;
    }
  }
  .more-info {
    button {
      margin: 0 0 0 1rem;
      background: #000;
      color: #FFF;
    }
  }
</style>
