import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=87d13f38&scoped=true&"
import script from "./landing.vue?vue&type=script&lang=js&"
export * from "./landing.vue?vue&type=script&lang=js&"
import style0 from "./landing.vue?vue&type=style&index=0&id=87d13f38&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87d13f38",
  null
  
)

export default component.exports