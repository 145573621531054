<template>
  <div class="faq">
    <h1>Frequently Asked Questions</h1>
    <div class="faq-body wrapper">
      <div class="faq-item">
        <h2>What is the purpose of OTCMethod?</h2>
        <p>OTCMethod is an education platform created to provide you with a structure to trade Over the Counter stocks (Pink Sheets) as well as all other securities on larger markets. We offer a time-tested step system to give you the structure you need to navigate the market and avoid the common traps that end new traders’ careers before they start.</p>
      </div>
      <div class="faq-item">
        <h2>What type of content does OTCMethod provide?</h2>
        <p>OTCmethod provides a historical video library with over 70 hours of content covering every topic relevant to trading, an articles section with tutorials and though pieces, a private chat room to access Patrick when you need help, and a community of like-minded traders all working toward the same goal.</p>
      </div>
      <div class="faq-item">
        <h2>Does OTCMethod offer alerts?</h2>
        <p>Over the course of each trading day and in the nightly videos you will learn why Patrick is interest in certain securities and trade alongside him but OTCMethod is not about getting alerted to tickers. One of the hardest lessons a trader will learn is that buying alerted stocks without being equipped to handle them is the quickest way to an empty account.</p>
      </div>
      <div class="faq-item">
        <h2>Do you offer refunds?</h2>
        <p>OTCMethod does not offer refunds, but you can cancel at any time without being charged for an additional month.</p>
      </div>
      <div class="faq-item">
        <h2>Does OTCMethod offer a free trial?</h2>
        <p>OTCMethod does not offer free trials, subscribers immediately unlock the entire library of data.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ"
};
</script>

<style lang="less" scoped>
  .wrapper {
    width: 700px;
    margin: 55px auto;
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  .faq {
    h1 {
      margin: 3rem 0 1rem 0;
      font-size: 65px;
      line-height: 77px;
      font-weight: 900;
      text-align: center;
    }
    .faq-body {
      .faq-item {
        margin: 0 0 2rem 0;
        h2 {
          color: #414141
        }
        p {
          line-height: 35px;
        }
      }
    }
  }
</style>