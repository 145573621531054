<template>
  <section class="videos">
    <div class="wrapper">
      <h3 v-if="!hideHeaders">{{ sectionTitle }}</h3>
      <div v-if="!videoData || videoData.length === 0" style="width: 100%; height: 100vh; background: white;"></div>
      <div v-if="videoData.length > 0" class="videos-card-wrapper">
        <div v-for="video in checkTags(videoData , tag)" v-bind:key="video.id" class="video-card">
          <router-link :to="'/videos/watch/' + video.vid_id.replace('vid_' , '') + '/' + encodeURIComponent(video.title.replace(/\s+/g , '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase())"></router-link>
          <img :src="video.thumbnail">
          <h4>{{ video.title }}</h4>
          <span>{{ filterDate(video.timestamp) }}</span>
        </div>
      </div>
      <div v-if="videoData.length > 0 && !ajaxMore && !hideMore">
        <router-link :to="{path: ajaxOverride || '/videos/' + getCategory()}">
          <button class="main-button">{{ viewOverride || 'View More'}}</button>
        </router-link>
      </div>
      <div style="display: flex; justify-content: center;" v-if="ajaxMore" v-show="showLoad">
        <button class="main-button" @click="loadMore()">View More</button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'videos',
  data() {
    return {
      videoList: null,
      showLoad: true,
      initialLimit: null
    }
  },
  props: ['videoData' , 'hideHeaders' , 'viewOverride' , 'ajaxMore' , 'hideMore' , 'limit' , 'ajaxRoute' , 'ajaxBody' , 'sectionTitle' , 'tag' , 'playlist' , 'ajaxOverride'],
  created() {
    this.initialLimit = this.limit;
  },
  methods: {
    // Using filter removed View More Button
    // Also breaks playlists
    loadMore() {
      if((this.initialLimit * 2) < this.videoData.length) {
        this.initialLimit += this.initialLimit;
      } else {
        this.initialLimit = this.videoData.length;
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos',
          data: {
            more: this.videoData[this.videoData.length - 1].timestamp
          }
        }).then((response) => {
          this.checkTags(this.videoData.concat(response.data.latest));
        }).catch((err) => {
          console.log(err);
        });
      }
      this.checkMax();
    },
    checkMax() {
      if(this.videoData.length > this.initialLimit) {
        this.showLoad = true;
      } else {
        this.showLoad = false;
      }
    },
    checkTags(videos , tag) {
      this.checkMax();
      if(typeof tag !== 'undefined' && typeof videos[tag] !== 'undefined') { 
        this.videoList = videos[tag].slice(0 , this.initialLimit);
        return videos[tag].slice(0 , this.initialLimit);
      }
      this.videoList = videos.slice(0 , this.initialLimit);
      return videos.slice(0 , this.initialLimit);
    },
    getCategory() {
      if(typeof this.playlist !== 'undefined') {
        return this.playlist !== 'latest' ? 'playlist/' + this.playlist : 'latest';
      } else if(typeof this.tag !== 'undefined') {
        return 'tags/' + this.tag;
      }
    },
    filterDate(date) {
      return (new Date(date).getMonth() + 1) + '/' + new Date(date).getDate() + '/' + new Date(date).getFullYear();
    },
  }
}
</script>


<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 1092px) {
      width: 768px;
  }
  @media(max-width: 868px) {
      width: 576px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
h4 , h3 {
  text-align: left;
}
.videos {
  padding-bottom: 25px;
}
.videos-card-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  @media(max-width: 1300px) {
    justify-content: space-between;
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  // &::after {
  //   content: '';
  //   flex: auto;
  //   @media(max-width: 1300px) {
  //     display: none;
  //   }
  // }
  .video-card {
    max-width: 17rem;
    margin: 0 2rem 2rem 0;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    transition: .2s all;
    border: 1px solid #d4d4d4;
    &:hover {
      transition: .2s all;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, .13);
    }
    h4 {
      font-weight: 600;
      margin: 0;
      padding: 1rem 1rem;
      line-height: 27px;
      font-weight: 700;
      margin-bottom: 3rem;
    }
    @media(max-width: 1300px) {
      &:nth-child(4n) {
        margin: 0 2rem 2rem 0 !important;
      }  
    }
    &:nth-child(4n) {
      margin: 0 0 2rem 0;
    }
    @media(max-width: 1100px) {
      max-width: 40%;
      &:nth-child(2n) {
        margin: 0 0 2rem 0 !important;
      }
    }
    @media(max-width: 868px) {
      max-width: 100% !important;
      margin: 0 0 2rem 0 !important;
      &:nth-child(4n) {
        margin: 0 0 0 0 !important;
      }
    }
    .video-body {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
    span {
      margin-top: auto;
      align-self: flex-start;
      font-weight: 800;
      font-size: .75rem;
      text-transform: uppercase;
      letter-spacing: 0px;
      color: #FFF;
      padding: .25rem .5rem;
      margin: 1rem;
      border-radius: 5px;
      background: #758797;
          position: absolute;
    bottom: 0;
    left: 0;
    }
    img {
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: .2s all;
    }
  }
}
a {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
</style>
