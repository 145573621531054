<template>
  <section>
    <div class="wrapper">
      <div class="main-media">
        <div class="about">
          <h1>Find the latest posts and interviews from Patrick across social media</h1>
          <p>Pat spends his time in the OTCMethod discord chat. However, you can get his latest social media content by following his Twitter, YouTube and other media appearances here.</p>
          <div>
            <a href="mailto:support@otcmethod.com"><button><ion-icon name="mail"></ion-icon>Email Us</button></a>
            <a href="https://twitter.com/KickoStocks" target="_blank"><span><ion-icon name="logo-twitter"></ion-icon></span></a>
            <a href="https://www.youtube.com/c/KickoStocks" target="_blank"><span><ion-icon name="logo-youtube"></ion-icon></span></a>
          </div>
        </div>
        <div class="podcast-container">
          <h2>Podcasts</h2>
          <a href="https://open.spotify.com/episode/1D7NPN91UBgj9AloRPJw3H" target="_blank" class="podcast">
            <img src="https://i.scdn.co/image/02ac944621f2d1f4abe87eb3d480e8b1dc80f414" />
          </a>
          <a href="https://www.youtube.com/watch?v=ajp-XsOavI0&t=2s" target="_blank" class="podcast">
            <img src="https://i.ytimg.com/vi/ajp-XsOavI0/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB3gKAAqACigIMCAAQARhyIEkoNzAP&rs=AOn4CLDjcoovXjQXPLhAHAxXebBfjPkuWA" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Media"
};
</script>

<style lang="less" scoped>
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  
  .main-media {
    margin: 0 0 2rem 0;
    display: flex;
    align-items: flex-start;
    @media(max-width: 991px) {
      flex-direction: column;
    }
    .podcast-container {
      width: calc(50% - 2rem);
      display: flex;
      flex-direction: column;
      @media(max-width: 991px) {
        width: 100%;
      }
    }
    .podcast {
      margin: 0 0 2rem 0;
      display: flex;
      align-items: flex-start;
      background: #55c68885;
      border-radius: 8px;
      padding: 2rem;
      align-self: flex-start;
      @media(max-width: 991px) {
        width: auto;
      }
      h3 {
        margin: 0 0 0 0;
        font-size: 3rem;
        text-align: left;
        margin: 0 0 0 1rem;
        font-weight: 800;
        color: #FFF;
      }
      img {
        border-radius: 5px;
      }
      &:nth-child(3) {
        background: #1b1b1b;
        width: 100%;
        @media(max-width: 991px) {
          width: auto;
          margin-right: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    a {
      text-decoration: none;
    }
    .about {
      width: 50%;
      margin: 2rem 2rem 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #F2F2F2;
      border-radius: 8px;
      padding: 2rem;
      @media(max-width: 991px) {
        width: auto;
        margin-right: 0;
      }
      div {
        display: flex;
        align-items: center;
        margin: 7rem 0 0 0;
        button {
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          background: #000000;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          padding: 10px 25px 10px 25px;
          border-radius: 50px;
          border: none;
          cursor: pointer;
          margin: 0 .5rem 0 0;
          display: flex;
          align-items: center;
          ion-icon {
            margin: 0 .5rem 0 0;
          }
        }
        span {
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          background: #000000;
          border-radius: 50px;
          cursor: pointer;
          width: 37px;
          height: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 .5rem 0 0;
        }
      }
    }
    h1 , h2 {
      font-size: 2.75rem;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
      color: #000;
      position: relative;
      -webkit-transition: 0.2s all;
      transition: 0.2s all;
      text-align: left;
      line-height: 3.5rem;
    }
    h2 {
      font-size: 1.75rem;
      margin: 2rem 0 1rem 0;
      line-height: initial;
    }
    p {
      text-align: left;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem
    }
  }
</style>
