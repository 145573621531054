<template>
  <section>
    <div class="terms">
      <div class="heading">
        <h3>Disclaimer</h3>
        <p>Updated January 5, 2020</p>
      </div>
      <div class="container wrapper">
        <p>PATRICK MCKAY IS NOT A FINANCIAL ADVISOR AND NOTHING CONTAINED HEREIN IS INTENDED TO BE OR TO BE CONSTRUED AS FINANCIAL ADVICE. THIS IS NOT AN INVESTMENT ADVISORY SERVICE, IS NOT INVESTMENT ADVICE, AND DOES NOT PROVIDE PERSONALIZED FINANCIAL ADVICE OR ACT AS A FINANCIAL ADVISOR. OTCMETHOD EXISTS FOR EDUCATIONAL PURPOSES ONLY, AND THE MATERIALS AND INFORMATION CONTAINED HEREIN ARE FOR GENERAL INFORMATIONAL PURPOSES ONLY. NONE OF THE INFORMATION PROVIDED IN THE WEBSITE IS INTENDED AS INVESTMENT, TAX, ACCOUNTING OR LEGAL ADVICE, AS AN OFFER OR SOLICITATION OF AN OFFER TO BUY OR SELL, OR AS AN ENDORSEMENT, RECOMMENDATION OR SPONSORSHIP OF ANY COMPANY, SECURITY, OR FUND.</p>

        <p>The information on the website should NOT be relied upon for purposes of transacting securities or other investments. You hereby understand and agree that we do NOT offer or provide tax, legal or investment advice and that you are responsible for consulting tax, legal, or financial professionals before acting on any information provided herein. The content provided herein is not intended as a promotion of any particular products or investments and neither the company nor any of its officers, directors, employees or representatives, in any way recommends or endorses any company, product, investment or opportunity which may be discussed herein. The education and information presented herein is intended for a general audience and does not purport to be, nor should it be construed as specific advice tailored to any individual. You are encouraged to discuss any opportunities with your attorney, accountant, financial professional or other advisors. Your use of the information contained herein is at your own risk. the content is provided ‘as is’ and without warranties of any kind, either expressed or implied.</p>

        <p>The Company disclaims all warranties, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, or non-infringement. The Company does not promise or guarantee any income or particular result from your use of the information contained herein. The Company assumes no liability or responsibility for errors or omissions in the information contained herein. The Company may derive fees or other compensation as a result of purchases from service providers or vendors referenced in this material. The Company will not be liable for any incidental, direct, indirect, punitive, actual, consequential, special, exemplary, or other damages, including, but not limited to, loss of revenue or income, pain and suffering, emotional distress, or similar damages, even if We have been advised of the possibility of such damages. In no event will the collective liability of the group to any party (regardless of the form of action, whether in contract, tort, or otherwise) exceed the greater of $100 or the amount you have paid to the Company for the information, product or service out of which liability arose.</p>

        <p>Under no circumstances will the Company be liable for any loss or damage caused by your reliance on the information contained herein. It is your responsibility to evaluate the accuracy, completeness or usefulness of any information, opinion, advice or other content contained herein. please seek the advice of professionals, as appropriate, regarding the evaluation of any specific information, opinion, advice or other content.</p>

        <p>Patrick McKay, a spokesperson of the Company, communicates content and editorials on this site. Statements regarding his, or other contributors’ “commitment” to share their personal investment strategies should not be construed or interpreted to require the disclosure of investments and strategies that are personal in nature, part of their estate or tax planning or immaterial to the scope and nature of the Company’s educational philosophy.</p>

        <p>Every effort has been made to accurately represent this product and it’s potential. Even though this industry is one of the few where one can write their own check in terms of earnings, there is no guarantee that you will earn any money using the techniques and ideas in these materials. examples in these materials are not to be interpreted as a promise or guarantee of earnings. Earning potential is entirely dependent on the person using our product, ideas, and techniques. We do not purport this as a “get rich scheme.”</p>

        <p>Your level of success in attaining the results claimed in our materials depends on the time you devote to the program, ideas, and techniques mentioned, your finances, knowledge and various skills. Since these factors differ according to individuals, we cannot guarantee your success or income level. Nor are we responsible for any of your actions.</p>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Terms"
};
</script>

<style lang="less" scoped>
.terms {
  margin: 45px 0 0 0;
  .heading {
    h3 {
      margin: 0 0 15px 0;
      font-size: 24px;
      font-size: 30px;
    }
    p {
      margin: 0 0 0 0;
    }
  }
  .container {
    border-top: 1px solid #EDEDED;
    text-align: left;
    a {
		font-weight: 700;
		color: #104475;
    }
    h3 {
      font-size: 22px;
    }
    p , ul , ol{
      line-height: 30px;
    }
  }
  .wrapper {
    width: 800px;
    margin: 35px auto 0 auto;
    padding: 25px 0 57px;
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
}
</style>
