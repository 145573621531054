<template>
  <section class="playlist-titles">
    <div class="logged-out" v-show="!$auth.isAuthenticated()">
      <landing-call></landing-call>
    </div>
    <div id="filter-container">
      <div id="playlist-header" v-show="$auth.isAuthenticated()">
        <div id="playlist-container">
          <div>
            <h1 v-bind:class="{active: selected === -1}" @click="selectVideos('latest'); selected = -1">Latest Videos</h1>
          </div>
          <div v-for="(playlist , index) in playlistData" v-bind:key="index">
            <h2 v-bind:class="{active: selected === index}" @click="selectVideos(playlist , index)">{{ playlist }}</h2>
          </div>
        </div>
        <!-- <p>Select which playlist you want to view or filter by video tags</p> -->
      </div>
      <filters v-show="$auth.isAuthenticated()" v-on:filtered="filter = $event" />
    </div>
    <div>
      <videos :hideHeaders="hideHeaders" :video-data="filter.length !== 0 ? filter : videos[selectedPlaylist]" :limit="12" :playlist="selectedPlaylist" :ajax-more="true" />
    </div>
  </section>
</template>

<script>
import { mapState , mapActions, mapGetters } from 'vuex'
import videos from '@/components/home/default/videos.vue'
import filters from '@/components/videos/filters.vue'
import landingCall from '@/components/home/landing/landing-cta.vue'

export default {
  name: 'playlists',
  components: {
    videos,
    filters,
    landingCall
  },
  data() {
    return {
      hideHeaders: true,
      selected: -1,
      selectedPlaylist: 'latest',
      selectedVideos:  this.videos,
      filter: [],
      playlistData: [
        'Essentials',
        'DD'
      ]
    }
  },
  computed: mapState({
    videos: state => state.videos.videos
  }),
  methods: {
    selectVideos(item , counter) {
      this.filter = [];
      if(this.selected !== item) {
        this.selected = counter;
        this.selectedPlaylist = item.toLowerCase().replace(' ' , '_');
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .logged-out {
    margin: 2rem 0 0 0;
  }
  #filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 45px 0 25px 0;
    @media(max-width: 868px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  #playlist-header {
    display: flex;
    flex-direction: column;
    text-align: left;
    @media(max-width: 868px) {
      width: 100%;
      margin: 0 0 1rem 0;
    }
    p {
      margin: 10px 0 0 0;
      font-size: 14px;
    }
  }
  #playlist-container {
    text-align: left;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #d4d4d4;
    background: #f0f0f0;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    height: 38px;
    padding: 0 .5rem;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
    h1 , h2 {
      cursor: pointer;
      color: #737a82;
      margin: 0 1rem 0 0;
      transition: .2s all;
      font-weight: 600;
      font-size: .825rem;
      // &::after {
      //   content: '';
      //   width: 75%;
      //   height: 2px;
      //   position: absolute;
      //   bottom: -5px;
      //   // left: 50%;
      //   // transform: translate(-50% , 0);
      //   left: 0;
      //   border-radius: 50px;
      //   background: #2c3e50;
      // }
    }
    .active {
      margin: 0 1rem 0 0;
      color: #2c3e50;
      position: relative;
      transition: .2s all;
      background: #FFF;
      padding: .25rem .5rem;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0,0,0,.1);
    }
  }
</style>

