<template>
  <section id="vimeo-frame">
    <div>
      <div style='padding:56.25% 0 0 0;position:relative;background-color: black; border-radius: 5px;'>
        <iframe src='https://vimeo.com/event/8436/embed' webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;border-radius: 5px;'></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'frame'
}
</script>

<style lang="less" scoped>

</style>
