import Vue from "vue";
import Router from "vue-router";
import Landing from "./views/Landing.vue";
import Callback from './views/Callback.vue';
import Dashboard from './views/Dashboard.vue';
import Features from './views/Features.vue';
import Videos from './views/Videos.vue';
import VideosList from './views/VideosList.vue';
import VideosNode from './views/VideosNode.vue';
import Journal from './views/Journal.vue';
import Trades from './views/Trades.vue';
import Stream from './views/Stream.vue';
import Account from './views/Account.vue';
import Chat from './views/Chat.vue';
import Affiliates from './views/Affiliates.vue';
import Articles from './views/Articles.vue';
import Audio from './views/Audio.vue';
import ArticlesNode from './views/ArticlesNode.vue';
import Disclaimer from './views/Disclaimer.vue';
import Payment from './views/Payment.vue';
import FAQ from './views/FAQ.vue';
import Terms from './views/Terms.vue';
import Media from './views/Media.vue';
import Privacy from './views/Privacy.vue';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        default: Dashboard,
        landing: Landing
      }
    },
    {
      path: "/features",
      name: "features",
      component: Features
    },
    {
      path: "/media",
      name: "media",
      component: Media
    },
    {
      path: "/journal",
      name: "journal",
      component: Journal,
      meta: {
        title: 'Trading Journal | OTC:Method'
      },
      props: true
    },
    {
      path: "/trades",
      name: "trades",
      component: Trades,
      meta: {
        title: 'Trade History | OTC:Method'
      }
    },
    // {
    //   path: "/journal/notes",
    //   name: "notes",
    //   component: Notes,
    //   props(route) {
    //     return {
    //       newNote: route.query.newNote,
    //       selected: route.query.selected
    //     }
    //   }
    // },
    {
      path: '/affiliates',
      name: 'affiliates',
      component: Affiliates
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat
    },
    {
      path: "/stream",
      name: "stream",
      component: Stream,
      meta: {
        title: 'Live Stream | OTC:Method'
      }
    },
    {
      path: "/disclaimer",
      name: "Disclaimer",
      component: Disclaimer
    },
    {
      path: "/audio",
      name: "audio",
      component: Audio
    },
    {
      path: "/videos",
      name: "videos",
      component: Videos,
      meta: {
        title: 'Video Library | OTC:Method'
      }
    },
    {
      path: "/videos/watch/*/*",
      name: "videosNode",
      component: VideosNode,
      meta: {
        title: 'Watch Video | OTC:Method'
      }
    },
    {
      path: "/videos/playlists/essentials",
      alias: "/videos/latest",
      name: "videosList",
      component: VideosList
    },
    {
      path: "/articles",
      name: "articles",
      component: Articles,
      meta: {
        title: 'Articles | OTC:Method'
      }
    },
    {
      path: "/articles/*",
      name: "articlesNode",
      component: ArticlesNode
    },
    {
      path: "/callback",
      name: "Callback",
      component: Callback
    },
    {
      path: "/payment",
      name: "payment",
      component: Payment
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy
    },
    {
      path: "/account",
      name: "Account",
      component: Account
    },
    {
      path: "*",
      redirect: {
        name: 'home'
      }
    }
  ]
});

router.beforeEach((to , from , next) => {
  document.title = to.meta.title || 'OTC:Method | Learn to trade penny stocks now!';
  // Redirect these routes
  const restrictedList = ['Account' , 'payment']
  // Handle journal saved changes
  if(from.name === 'journal') {
    if(from.query.saved === 'false' && to.name !== 'journal') {
      if(window.confirm('You haven\'t saved your changes yet. Are you sure you want to leave?')) {
        router.replace({
          ...from,
          query: {
            saved: undefined
          }
        });
        next(to);
      } else {
        next(from);
      }
    }
  }
  // Redirect Payments
  if(from.name === 'payment') next(from);
  if(from.name === 'about') next('/features');
  // Restricted Redirect
  if(!localStorage.getItem('accessToken') && restrictedList.indexOf(to.name) !== -1) next('/');
  // Normal Navigation
  next();
})

export default router