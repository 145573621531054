<template>
  <section class="dashboard">
    <div class="wrapper">
      <h1>Dashboard</h1>
      <div class="dashboard-cards">
        <div class="main-card">
          <div class="main-wrap">
            <router-link to="/videos/playlists/essentials"></router-link>
            <span class="started">Getting Started</span>
            <h2>Watch the Essentials</h2>
            <p>The best videos to get you started. <span>Get familiar with the core trading concepts we use every day in chat.</span></p>
            <div class="watch">
              <span>Watch Now</span>
              <ion-icon name="play"></ion-icon>
            </div>
          </div>
        </div>
        <div class="sub-card">
          <div class="sub-wrap">
            <router-link to="/chat"></router-link>
            <svg viewBox="0 0 176 199" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M105.9 83.2C100.2 83.2 95.7002 88.1 95.7002 94.2C95.7002 100.3 100.3 105.2 105.9 105.2C111.6 105.2 116.1 100.3 116.1 94.2C116.1 88.1 111.5 83.2 105.9 83.2ZM69.4002 83.2C63.7002 83.2 59.2002 88.1 59.2002 94.2C59.2002 100.3 63.8002 105.2 69.4002 105.2C75.1002 105.2 79.6002 100.3 79.6002 94.2C79.7002 88.1 75.1002 83.2 69.4002 83.2Z" fill="#D0D0D0"/>
              <path d="M154.5 0H20.5C9.2 0 0 9.2 0 20.5V154.5C0 165.8 9.2 175 20.5 175H133.9L128.6 156.7L141.4 168.5L153.5 179.6L175.1 198.3V20.5C175 9.2 165.8 0 154.5 0ZM115.9 129.5C115.9 129.5 112.3 125.2 109.3 121.5C122.4 117.8 127.4 109.7 127.4 109.7C123.3 112.4 119.4 114.3 115.9 115.6C110.9 117.7 106.1 119 101.4 119.9C91.8 121.7 83 121.2 75.5 119.8C69.8 118.7 64.9 117.2 60.8 115.5C58.5 114.6 56 113.5 53.5 112.1C53.2 111.9 52.9 111.8 52.6 111.6C52.4 111.5 52.3 111.4 52.2 111.4C50.4 110.4 49.4 109.7 49.4 109.7C49.4 109.7 54.2 117.6 66.9 121.4C63.9 125.2 60.2 129.6 60.2 129.6C38.1 128.9 29.7 114.5 29.7 114.5C29.7 82.6 44.1 56.7 44.1 56.7C58.5 46 72.1 46.3 72.1 46.3L73.1 47.5C55.1 52.6 46.9 60.5 46.9 60.5C46.9 60.5 49.1 59.3 52.8 57.7C63.5 53 72 51.8 75.5 51.4C76.1 51.3 76.6 51.2 77.2 51.2C83.3 50.4 90.2 50.2 97.4 51C106.9 52.1 117.1 54.9 127.5 60.5C127.5 60.5 119.6 53 102.6 47.9L104 46.3C104 46.3 117.7 46 132 56.7C132 56.7 146.4 82.6 146.4 114.5C146.4 114.4 138 128.8 115.9 129.5Z" fill="#D0D0D0"/>
            </svg>
            <div>
              <h2>Join Discord</h2>
              <p>Access the private chat group</p>
            </div>
          </div>
        </div>
        <div class="sub-card">
          <div class="sub-wrap">
            <a href='/affiliates'></a>
            <ion-icon name="wallet-outline"></ion-icon>
            <div>
              <h2>Affiliate Program</h2>
              <p>Earn 30% commission on each user you sign up</p>
            </div>
          </div>  
        </div>
        <div class="sub-card">
          <div class="sub-wrap">
            <router-link to="/journal"></router-link>
            <ion-icon name="newspaper-outline"></ion-icon>
            <div>
              <h2>Trade Journal</h2>
              <p>Save your most important trading ideas and notes</p>
            </div>
          </div>
        </div>
        <div class="sub-card">
          <div class="sub-wrap">
            <router-link to="/account"></router-link>
            <ion-icon name="card-outline"></ion-icon>
            <div>
              <h2>Manage Account</h2>
              <p>Billing, User Settings and More</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'dashboard'
}
</script>


<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 1092px) {
      width: 768px;
  }
  @media(max-width: 868px) {
      width: 576px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.dashboard {
  background: #F2F8FF;
  padding: 45px 0 calc(45px + 1rem) 0;
  border-bottom: 1px solid #C5D4DC;
  .dashboard-cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 868px) {
      flex-wrap: wrap;
      &::after {
        content: "";
        flex: auto;
      }
    }
  }
  h1 {
    text-align: left;
    font-size: 55px;
    font-weight: 800;
    margin: 0 0 1rem 0;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @keyframes fadeInAnim {
		0% {
		   opacity: 0;
		   transform: translateY(100%);
		}
		100% {
		   opacity: 1;
		   transform: translateY(0);
		}
	 }
  .main-card {
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    @media(max-width: 1300px) {
      width: calc(((992px - 5rem) / 3) - 2.5rem);
    }
    @media(max-width: 1092px) {
      width: calc(((768px - 5rem) / 3) - 2.5rem);
    }
    @media(max-width: 868px) {
      width: calc(((576px - 5rem) / 1.2) - 2.5rem);
    }
    @media(max-width: 676px) {
      width: 100%;
    }
    .main-wrap {
      display: flex;
      height: 140px;
      flex-direction: column;
      background: #204471;
      padding: 1.25rem;
      border-radius: 4px;
      transition: transform 0.2s ease-in;
      width: calc(((1200px - 5rem) / 3) - 2.5rem);
      transform: translateY(100%);
      animation-name: fadeInAnim;
			-webkit-animation-duration: .4s;
			animation-duration: .4s;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
      animation-delay: .1s;
      @media(max-width: 1300px) {
        width: calc(100% - 2.5rem);
      }
    }
    .started {
      margin: 0 0 .25rem 0;
      font-weight: 800;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      text-align: left;
      display: block;
      color: #214572;
      background: #a4b8d0;
      align-self: self-start;
      padding: .125rem .25rem;
      border-radius: 3px;
    }
    .watch {
      align-self: flex-end;
      margin-top: auto;
      @media(max-width: 1092px) {
        display: none;
      }
      @media(max-width: 676px) {
        display: initial;
      }
      span , ion-icon {
        font-weight: 800;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #FFFFFF;
      }
      ion-icon {
        margin: -2px 0 0 .25rem;
      }
    }
    h2 {
      margin: 0 0 .5rem 0;
      font-size: 1.25rem;
      text-align: left;
      color: #FFFFFF;
      @media(max-width: 1092px) {
        font-size: .83rem;
      }
      @media(max-width: 868px) {
        font-size: 1.25rem;
      }
      @media(max-width: 676px) {
        font-size: 1.5rem;
      }
    }
    p {
      margin: 0 0 0 0;
      font-size: .9rem;
      text-align: left;
      line-height: 1.25rem;
      font-weight: 400;
      color: #FFFFFF;
      width: 90%;
      @media(max-width: 1092px) {
        font-size: .8rem;
        width: auto;
        span {
          display: none;
        }
      }
      @media(max-width: 868px) {
        font-size: .85rem;
        span {
          display: inline;
        }
      }
      @media(max-width: 676px) {
        font-size: 1.1rem;
      }
    }
  }
  .sub-card {
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    &:nth-child(2) {
      .sub-wrap {
        animation-name: fadeInAnim;
        -webkit-animation-duration: .4s;
			  animation-duration: .4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: .2s;
      }
    }
    &:nth-child(3) {
      .sub-wrap {
        background: #2E95AC;
        animation-name: fadeInAnim;
        -webkit-animation-duration: .4s;
			  animation-duration: .4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: .3s;
      }
    }
    &:nth-child(4) {
      .sub-wrap {
        background: #2EACA4;
        animation-name: fadeInAnim;
        -webkit-animation-duration: .4s;
			  animation-duration: .4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: .4s;
      }
    }
    &:nth-child(5) {
      .sub-wrap {
        background: #2EAC8E;
        animation-name: fadeInAnim;
        -webkit-animation-duration: .4s;
			  animation-duration: .4s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: .5s;
      }
    }
    @media(max-width: 1300px) {
      width: calc((((992px - 5rem) / 2) - 2.5rem) / 2 - 2rem);
    }
    @media(max-width: 1092px) {
      width: calc((((768px - 5rem) / 2) - 3.5rem) / 2 - 1rem);
    }
    @media(max-width: 868px) {
      width: calc(33% - 1rem);
      &:nth-child(3) , &:nth-child(4) , &:nth-child(5) {
        margin-top: 1.65rem;
      }
      &:nth-child(4) , &:nth-child(5) {
        margin-left: 1.65rem;
      }
    }
    .sub-wrap {
      width: calc((((1200px - 5rem) / 3) - 2.5rem) / 2 - 2rem);
      display: flex;
      height: 140px;
      flex-direction: column;
      padding: 1.25rem;
      border-radius: 4px;
      background: #205471;
      @media(max-width: 1300px) {
        width: calc(100% - 2.5rem);
      }
      @media(max-width: 1092px) {
        width: calc(100% - 2rem);
        padding: 1.25rem 1rem;
      }
      @media(max-width: 868px) {
        padding: 1.25rem;
      }
    }
    @media(max-width: 676px) {
      width: 100%;
      margin-top: 2rem !important;
      &:nth-child(3) , &:nth-child(4) , &:nth-child(5) {
        margin-top: 0;
      }
      &:nth-child(4) , &:nth-child(5) {
        margin-left: 0;
      }
    }
    ion-icon {
      font-size: 30px;
      margin: 0 0 .75rem 0;
      color: #FFFFFF;
    }
    svg {
      width: 30px;
      margin: 0 0 .75rem 0;
      path {
        fill: #FFFFFF;
      }
    }
    h2 {
      margin: 0 0 .5rem 0;
      font-size: 1rem;
      text-align: left;
      color: #FFFFFF;
      @media(max-width: 1300px) {
        font-size: .83rem;
      }
      @media(max-width: 1092px) {
        font-size: .8rem;
      }
      @media(max-width: 868px) {
        font-size: 1rem;
      }
      @media(max-width: 676px) {
        font-size: 1.5rem;
      }
    }
    p {
      margin: 0 0 0 0;
      font-size: .9rem;
      text-align: left;
      line-height: 1.25rem;
      font-weight: 400;
      color: #FFFFFF;
      @media(max-width: 1300px) {
        font-size: .8rem;
      }
      @media(max-width: 1092px) {
        font-size: .75rem;
      }
      @media(max-width: 868px) {
        font-size: .9rem;
      }
      @media(max-width: 676px) {
        font-size: 1.1rem;
      }
    }
  }
}
</style>
