<template>
  <section id="landing-stats">
    <div id="features-container">
      <div class="wrapper">
        <div id="feature-text">
          <h2>Tools That Help You Succeed</h2>
          <p>OTCMethod is more than just another discord chat. We offer a custom dashboard with access to all of our educational content in one place.</p>
        </div>
        <div id="feature-box">
          <img src="../../../assets/img/dash.jpg" />
        </div>
      </div>
    </div>
    <div id="history-container">
      <div class="wrapper">
        <h2>Everybody’s Talking About It</h2>
        <p class="tagline">A history of success with hundreds of satisfied customers across the lifetime of the program.</p>
        <div id="review-container">
          <div class="col">
            <div class="review">
              <div>
                <p>Truly #1 in the OTC. If you’re tired from the weight of bag holding and want to develop a set of tools to become a self sufficient trader then look no further. Thank you @KickoStocks!!</p>
              </div>
              <div>
                <h6>Cordage</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>Shoutout !! To @KickoStocks I’m learning soo much from his OTCmethod courses. Incredible amounts of information. You can become a self sufficient Trader learning from Mr. PAT👍</p>
              </div>
              <div>
                <h6>Francois</h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="review">
              <div>
                <p>Although I have not been in the trading game for quite some time I will never forget what @KickoStocks did for me. An amazing trader really, always helpful and looks out for others. Nothing but love always for him. Forever thankful.</p>
              </div>
              <div>
                <h6>Mike Huncho</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>If you need help with charts, etc I would highly recommend following @KickoStocks he’s the best mentor you can ask for. Reasonably priced courses as well. But he’ll turn ya into a beast</p>
              </div>
              <div>
                <h6>OTC Chairman</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>Only paid service I have ever stood by is @KickoStocks because it has nothing to do with call outs and everything to do with learning/understanding how the game we are playing, actually functions. He is a mentor and teaches you to fly solo, not follow blindly with hope on imos</p>
              </div>
              <div>
                <h6>shhhhh</h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="review">
              <div>
                <p>Get yourself a great mentor if you want to trade the OTC!! Hands down the best out there @KickoStocks. In no time you will be a self sufficient trader. Creme de la creme!!</p>
              </div>
              <div>
                <h6>oo1</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>And already have made enough to pay for months of subscriptions and have been taught enough to change my life forever thank you OTCMethod for being the absolute best group on the OTC @KickoStocks</p>
              </div>
              <div>
                <h6>Tieuleor</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>I gotta say thank you Pat. What you have taught me is amazing. About share structure, dilution and general history of tickers. Otc is a wild ride but I love it. If you are reading this and struggling with trading come and join</p>
              </div>
              <div>
                <h6>Trader Colby</h6>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="review">
              <div>
                <p>OTCMethod helped me in all way of my life. VERY GOOD place to join for learning to trade on our own. I highly recommend @KickoStocks. Great guy.</p>
              </div>
              <div>
                <h6>Baskar</h6>
              </div>
            </div>
            <div class="review">
              <div>
                <p>If you are learning OTC, can’t be a better person to learn it from than @KickoStocks. Dude works tirelessly every day to get his people the best setups and chances to make money in this exchange no matter what. Really no other option. </p>
              </div>
              <div>
                <h6>The Stock Savant</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'landingStats'
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 991px) {
      width: 868px;
  }
  @media(max-width: 868px) {
      width: 676px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
  #landing-stats {
    #features-container {
      background: #fbfbfb;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding: 6rem 0;
      .wrapper {
        display: flex;
        align-items: center;
        @media(max-width:868px) {
          flex-direction: column;
        }
      }
      #feature-text {
        width: 50%;
        @media(max-width:868px) {
          width: 100%;
        }
        h2 {
          text-align: left;
          font-size: 3rem;
          margin: 0 0 1rem 0;
          width: 65%;
          color: #000;
          @media(max-width: 1200px) {
            width: 80%;
          }
          @media(max-width: 991px) {
            width: 100%;
          }
          @media(max-width:868px) {
            text-align: center;
            font-size: 2.5rem;
            line-height: 3.5rem;
          }
        }
        p {
          text-align: left;
          margin: 0 0 0 0;
          width: 65%;
          font-size: 1.25rem;
          line-height: 2rem;
          @media(max-width: 1200px) {
            width: 75%;
          }
          @media(max-width:868px) {
            text-align: center;
            width: 100%;
          }
        }
      }
      #feature-box {
        width: 50%;
        position: absolute;
        right: 0;
        bottom: -8rem;
        @media(max-width: 1600px) {
          bottom: -4rem;
        }
        @media(max-width: 1300px) {
          bottom: -2rem;
        }
        @media(max-width: 991px) {
          bottom: -.75rem;
        }
        @media(max-width:868px) {
          position: static;
        }
        @media(max-width:868px) {
          width: 100%;
          margin: 2rem 0 0 0;
        }
        img {
          width: 100%;
          border-radius: 13px;
          box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
        }
      }
    }
  }
  #history-container {
    display: flex;
    flex-wrap: wrap;
    background: #CBE5E3;
    padding: 5rem 0;
    #review-container {
      display: flex;
      margin: 2rem 0 0 0;
      @media(max-width: 991px) {
        flex-direction: column;
      }
      .col {
        display: flex;
        flex-direction: column;
        flex: 0 0 calc(25% - 1rem);
        margin: 0 .5rem 0 .5rem;
        @media(max-width: 991px) {
          flex-direction: row;
        }
        @media(max-width: 768px) {
          flex-direction: column;
          margin: 0;
        }
      }
    }
    h2 {
      font-size: 2.5rem;
      margin: 0 0 1rem 0;
      color: #000;
      @media(max-width: 991px) {
        width: auto;
      }
    }
    .tagline {
      margin: 0 auto;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      width: 50%;
      @media(max-width: 991px) {
        width: auto;
      }
    }
    .review {
      border-radius: 12px;
      background: #9FC7C4;
      padding: .75rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 1rem 0;
      align-self: flex-start;
      // &:first-child {
      //   -webkit-filter: blur(5px);
      //   -moz-filter: blur(5px);
      //   -o-filter: blur(5px);
      //   -ms-filter: blur(5px);
      //   filter: blur(5px);
      // }
      @media(max-width: 991px) {
        margin: 0 .5rem 1rem .5rem;
      }
      @media(max-width: 768px) {
        margin: 0 0 1rem 0;
      }
      p {
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0 0 0 0;
        text-align: left;
      }
      div + div {
        h6 {
          margin: 2rem 0 0 0;
          font-size: .825rem;
          padding: .25rem;
          border-radius: 5px;
          background: #69a19c;
          color: white;
        }
      }
    }
  }
</style>

