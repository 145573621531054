<template>
  <section id="stream-landing">
    <div id="call-to-action">
      <h1>Trade Journal</h1>
      <p>Save your trading history and notes to track your learning progress all in one place. Keep track of what you learn in the nightly streams so you never forget important details.</p>
      <button @click.prevent="login">Get Access</button>
    </div>
    <section id="about-call">
      <div class="call-frame">
        <h3>Get started with your penny stock education now!</h3>
        <p> The information is available to anyone willing to look and OTCMethod was created to give you the skills to find it first as opposed to receiving it from a guru.</p>
        <button @click.prevent="login">Get Started Now</button>
      </div>
      <div class="call-frame">
        <h3>Learn More.</h3>
        <p>Get a preview of some of our exclusive content.</p>
        <router-link to="/articles"><button>Articles</button></router-link>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'landing',
  methods: {
    login() {
      this.$auth.login();
    }
  }
}
</script>

<style lang="less" scoped>
  #call-to-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 10rem 0 10rem 0;
    h1 {
      font-size: 65px;
      line-height: 77px;
      font-weight: 900;
      width: 80%;
      margin: 0 0 0 0;
      text-align: center;
      @media(max-width: 868px) {
        font-size: 3rem;
      }
      @media(max-width: 568px) {
        font-size: 2rem;
      }
    }
    p {
      width: 60%;
      line-height: 36px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      @media(max-width: 868px) {
        width: 90%;
      }
      @media(max-width: 868px) {
        font-size: 18px;
      }
      @media(max-width: 568px) {
        font-size: 14px;
      }
    }
    button {
      font-weight: 700;
      font-size: 14px;
      color: #FFFFFF;
      background: #104475;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      padding: 10px 25px 10px 25px;
      border-radius: 5px;
      cursor: pointer;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      li {
        position: relative;
        margin: 0 0 15px 0;
        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          right: -10px;
          bottom: 0;
          height: 10px;
          width: 100%;
          background: rgba(121, 191, 255, 0.27);
        }
      }
    }
    @media(max-width: 1300px) {
      h1 {
        width: 80%;
      }
      li {
        font-size: 15px;
      }
    }
  }
  #about-call {
    display: flex;
    justify-content: center;
    @media(max-width: 1100px) {
      flex-direction: column;
    }
    .call-frame {
      width: 50%;
      padding: 5rem 7rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @media(max-width: 1100px) {
        width: auto;
      }
      @media(max-width: 868px) {
        width: auto;
        padding: 3rem 5rem;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 800;
        text-align: left;
        color: #FFFFFF;
        margin: 0 0 1rem 0;
        @media(max-width: 868px) {
          font-size: 1.5rem;
        }
      }
      p {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 500;
        text-align: left;
        line-height: 35px;
        margin: 0 0 2rem 0;
        @media(max-width: 868px) {
          font-size: 18px;
        }
      }
      button {
        float: left;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
        background: #104475;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 15px 27px 15px 27px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
      }
      &:first-child {
        background:#5b7b98;
        button {
          background: #FFFFFF;
          color: #1d1d1d;
        }
      }
      &:last-child {
        background: #f0f0f0;
        h3 , p {
          color: #2c3e50;
        }
      }
    }
  }
</style>