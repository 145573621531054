<!-- Show bots and features of chat -->
<!-- Show Pat's content -->
<!-- Add discord invite link -->
<template>
  <section class="main-wrapper">
    <div class="discord">
      <form v-if="$auth.isAuthenticated()" v-on:submit.prevent="submitForm">
        <h3>Join Discord Chat</h3>
        <h4>Enter your discord username below to get access to the private chat.</h4>
        <input required type="text" v-model="discord" placeholder="Discord Username" />
        <button v-if="!link" class="main-button" :disabled="loading" type="submit"><ion-icon name="chatbubbles"></ion-icon>{{ loading ? 'Loading...' : 'Join Now' }}</button>
        <a :href="link"><button v-if="link" class="main-button"><ion-icon name="chatbubbles"></ion-icon>Click to Join</button></a>
        <span v-if="error" class="error">{{ error }}</span>
      </form>
      <form v-if="!$auth.isAuthenticated()" v-on:submit.prevent="submitForm">
        <h3>Join Discord Chat</h3>
        <h4>Become a member today to join the private chat.</h4>
        <button class="main-button" @click.prevent="$auth.login()"><ion-icon name="chatbubbles"></ion-icon>Join Now</button>
      </form>
    </div>
    <!-- <h1>Chat Features</h1>
    <div class="features-showcase wrapper"></div> -->
    <div class="features">
      <!-- <div class="wrapper">
        <h1>The Best Chat For OTC Traders</h1>
      </div> -->
      <div class="wrapper">
        <div class="feature-card">
          <img src="../assets/img/chat/chat.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Daily Trade Ideas From Pat</strong>
          <p>Get access to Pat's live thoughts as a day trading mentor every day. Unlike most Discord stock groups, the main chat is read-only during trading hours except for our veteran members. This keeps the feed distraction free and let's members get the most important news and insights without any stress. Feel free to share ideas and ask questions in any of the many other helpful channels in our stock trading discord.</p>
        </div>
        <div class="feature-card">
          <img src="../assets/img/chat/recap.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Pat Recap Channel</strong>
          <p>Every time Pat tags a stock in the main chat it gets archived in the special <span>#pat-recap</span> channel so members can look back at exactly what major events happened in the market each day. It's easy to look back at the countless wins the OTCMethod discord community has had and make trade ideas much easier to sift through. Pat works hard to bring all the latest events and plays in the otc right here in an easy to digest feed.</p>
        </div>
        <div class="feature-card">
          <img src="../assets/img/chat/ss.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Custom Bots for Stock Data</strong>
          <p>Get on the fly access to share structure and other stock information with our custom Discord stock bot. Because of Discord's great search, you can see how much a company has diluted in the past by searching for historical share information right from the penny stock Discord. There's also other useful chart, pivot point and stock info available from our custom stock bots.</p>
        </div>
        <div class="feature-card">
          <img src="../assets/img/chat/community.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Great Community to Share Ideas</strong>
          <p>Engage with hundreds of like minded traders in our day trading group who share their ideas and insight. OTCMethod is not just a stock trading mentorship program but also a community to find the best ideas possible from an otc stock discord. Leverage the contributions of our members to make informed decisions in the otc stock market and grow your knowledge whether you're a beginner or veteran.</p>
        </div>
        <div class="feature-card">
          <img src="../assets/img/chat/research.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Exclusive Resources and Research</strong>
          <p>Years of success for OTCMethod members has led to the chat being filled with extensive knowledge from traders. We offer tons of channels that are categorized to help you find the topics and questions you're looking for. Learn penny stock patterns, troubleshoot your broker issues and get valuable penny stock research reports in the best place to research penny stocks.</p>
        </div>
        <div class="feature-card">
          <img src="../assets/img/chat/questions.png" />
          <strong><ion-icon name="information-circle"></ion-icon>Answer Your Trading Questions</strong>
          <p>Use our great community to find the best penny stocks with potential to run. Unlike other penny stock trading chat rooms, OTCMethod isn't a place to get spammed with alerts. Our goal is to help you become a self sufficient trader who doesn't have to rely on "alerts" to make a good trade. It's the best penny stock community to learn how to trade.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'chat',
    data() {
      return {
        discord: null,
        loading: false,
        link: null,
        error: null
      }
    },
    methods: {
      submitForm() {
        if(this.discord && this.discord.length > 0) {
          this.loading = true;
          this.error = null;
          if(localStorage.getItem('user')) {
            axios({
              method: 'post',
              url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/user/discord',
              data: {
                discord: this.discord,
                userID: JSON.parse(localStorage.getItem('user'))['sub'],
                custID: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['https://www.otcmethod.com/stripe_customer_id'] : null
              }
            }).then((response) => {
              this.loading = false;
              this.link = response.data.invite;
              if(this.link) window.open(response.data.invite);
              else if (response.data.error) this.error = response.data.error;
            }).catch((err) => {
              this.error = err;
              this.loading = false;
            });
          } else {
            this.error = 'No User Found';
            this.loading = false;
          }
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .main-wrapper {
    display: flex;
    padding: 0;
    overflow: hidden;
    flex-direction: column;
  }
  .error {
    margin: .75rem 0 0 0;
    display: flex;
    font-size: .825rem;
    color: red;
    font-weight: 500;
  }
  .features-showcase {
    background-color: #c2c2c2;
    height: 75vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  a {
    text-decoration: none;
  }
  .features {
    // flex: 0 0 50%;
    // flex-direction: column;
    // align-items: flex-end;
    // border-right: 1px solid #c2c2c2;
    // box-shadow: inset -10px 0 19px -11px rgba(0, 0, 0, 0.16);
    background: #fafafa;
    border-top: 1px solid #c2c2c2;
    box-shadow: inset 0 10px 19px -11px rgba(0, 0, 0, 0.16);
    margin: 4rem 0 0 0;
    padding: 4rem 0 0 0;
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    h1 {
      font-size: 2.5rem;
      margin: 3rem 0 3rem 0;
      color: #000;
      text-align: center;
      line-height: 4rem;
      font-weight: 800;
      text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    }
    .feature-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // width: 50%;
      border-radius: 10px;
      margin: 0 1rem 2rem 0;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border: 1px solid #d4d4d4;
      background-color: #ffffff;
      padding: 2rem;
      // flex: 0 0 calc(20% - 4.35rem);
      width: calc(50% - 4.65rem);
      &:nth-child(even) {
        margin-right: 0;
      }
      @media(max-width: 991px) {
        width: 100%;
        margin: 0 0 2rem 0;
      }
      strong {
        margin: 0 0 .5rem 0;
        color: #000;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        line-height: 2rem;
        text-align: left;
        font-weight: 600;
        ion-icon {
          margin: 0 .5rem 0 0;
          display: none;
        }
      }
      img {
        width: 100%;
        height: 250px;
        margin: 0 0 1rem 0;
        border-radius: 7px;
        object-fit: cover;
      }
      h2 {
        font-size: 1.25rem;
        margin: 0 0 .75rem 0;
        color: #000;
        align-self: center;
      }
      p {
        font-size: 1rem;
        margin: 0 0 0 0;
        font-weight: 400;
        color: #666;
        line-height: 1.75rem;
        text-align: left;
        span {
          border-radius: 5px;
          background: #f5f5f5;
          padding: 2px 5px;
          font-weight: 600;
        }
      }
    }
  }
  .discord {
    // flex: 0 0 100%;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    position: relative;
    .join-history {
      // position: fixed;
      bottom: 0;
      transform: translateY(calc(-50% + 149px + 1.5rem));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 250px;
      span {
        font-size: 0.75rem;
        color: #666;
        margin: 0 0 0.5rem 0;
      }
    }
    form {
      // position: fixed;
      top: 50%;
      // transform: translateY(calc(-50%));
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      background: linear-gradient(324deg, #fafafa, #ffffff);
      border: 1px solid #c2c2c2;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.173);
      border-radius: 5px;
      align-self: center;
      h3 {
        font-size: 1.5rem;
        margin: 0 0 .5rem 0;
        color: #000;
      }
      h4 {
        font-size: .825rem;
        font-weight: 400;
        margin: 0 0 2rem 0;
        color: #666;
      }
      input {
        width: 50%;
        padding: 1rem;
        margin: 0 0 1rem 0;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }
</style>
