<template>
  <section>
    <form  v-on:submit.prevent="submitForm" id="profile-form">
      <div id="creditcard-card" class="card">
        <div>
          <h3>Update Card</h3>
          <p>Change your current card on file for next billing cycle.</p>
          <stripe form-id="profile-form"  v-on:stripeToken="setToken" v-on:stripeError="loading = false" ref="stripeForm" />
        </div>
        <div class="toggle">
          <button :disabled="loading" type="submit">Update Card Info</button>
          <span v-if="error">{{ error }}</span>
          <span v-if="success">{{ success }}</span>
        </div>
      </div>
    </form>
  </section>
</template>


<script>
import axios from 'axios';
import stripe from '@/components/payment/stripe.vue';

export default {
  name: 'creditcard',
  components: {
    stripe
  },
  data() {
    return {
      token: null,
      error: null,
      success: null,
      loading: false
    }
  },
  methods: {
    submitForm() {
      this.loading = true;
      if(!this.error) {
        this.$refs.stripeForm.stripeTokenHandler();
      } else {
        this.loading = false;
      }
    },
    setToken(token) {
      console.log(token);
      this.token = token;
      this.submitPayment(token);
    },
    submitPayment(token) {
      console.log(token);
      if(token !== '' && token !== null) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/user/updatecard',
          data: {
            token: token
          }
        }).then((response) => {
          if(typeof response.data.error !== 'undefined') {
            this.error = response.data.error;
            this.loading = false;
          } else {
            this.success = 'Card Updated';
          }
        }).catch((err) => {
          this.error = err;
          this.loading = false;
        });
      } else {
        this.error = 'Card Info Required';
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .card {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow:  0 1px 3px rgba(31, 31, 34, 0.11);
    padding: 25px 25px 25px 25px;
  }
  input {
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0 25px 0;
  }
  #creditcard-card {
    text-align: left;
    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #EDEDED;
      padding: 15px 0 0 0;
      margin: 18px 0 0 0;
      button {
        margin: 0 0 0 0;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        border: none;
        background: none;
        color: #104475;
        cursor: pointer;
        padding: 2px 0 2px 0;
        transition: .2s all;
        &:hover {
          background: #79beff11;
          transition: .2s all;
        }
        span {
          font-weight: 900;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
    }
  }
</style>
