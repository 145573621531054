<template>
  <section>
    <form v-on:submit.prevent="submitForm" @click="validateForm">
      <div class="email-card card">
        <div>
          <h3>Change Email</h3>
          <p>Change your login and billing email.</p>
          <input v-model="email" type="text" placeholder="Email">
        </div>
        <div class="toggle">
          <button :disabled="loading" type="submit">Submit</button>
          <span v-if="error">{{ error }}</span>
          <span v-if="success">{{ success }}</span>
        </div>
      </div>
    </form>
  </section>
</template>


<script>
import axios from 'axios';

export default {
  name: 'email',
  data() {
    return {
      email: null,
      error: null,
      success: null,
      loading: false
    }
  },
  methods: {
    submitForm() {
      this.validateForm();
      this.loading = true;
      if(!this.error) {
        this.submitEmail();
      }
    },
    submitEmail() {
      if(localStorage.getItem('user')) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/user/updateemail',
          data: {
            email: this.email,
            userID: JSON.parse(localStorage.getItem('user'))['sub']
          }
        }).then((response) => {
            this.success = response.data.updated ? 'Email Updated' : (typeof response.data.error !== 'undefined' ? response.data.error : 'Something Went Wrong');
            this.loading = false;
        }).catch((err) => {
          this.error = err;
          this.loading = false;
        });
      } else {
        this.error = 'No User Found';
      }
    },
    validateForm() {
      this.success = null;
      if(this.email !== null) {
        this.error = null;

        if(this.email !== '') {
          if(!this.validateEmail(this.email)) {
            this.error = 'Invalid Email'
            this.loading = false;
          }
        } else {
          this.error = 'Email is Required';
          this.loading = false;
        }
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  }
}
</script>

<style lang="less" scoped>
  .card {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow:  0 1px 3px rgba(31, 31, 34, 0.11);
    padding: 25px 25px 25px 25px;
  }
  input {
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0 25px 0;
  }
  .email-card {
    text-align: left;
    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #EDEDED;
      padding: 15px 0 0 0;
      button {
        margin: 0 0 0 0;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        border: none;
        background: none;
        color: #104475;
        cursor: pointer;
        padding: 2px 0 2px 0;
        transition: .2s all;
        &:hover {
          background: #79beff11;
          transition: .2s all;
        }
        span {
          font-weight: 900;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
    }
    p {
      width: 400px;
      @media(max-width: 676px) {
        width: 100%;
      }
    }
  }
</style>
