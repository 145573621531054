<template>
  <section id="trades">
    <table></table>
    <!-- <Modal v-model="showModal" v-bind:title="selectedTrade === null ? 'Add Trade' : 'Edit Trade'" modalStyle="font-family: 'Inter'">
      <span class="errors">{{ error }}</span>
      <form @submit.prevent="addTrade">
        <div class="form-header">
          <input class="symbol" maxlength="7" v-model="symbol" type="text" placeholder="Stock Symbol" />
          <input class="shares" maxlength="15" v-model="shares" type="text" placeholder="Total Shares" />
        </div>
        <div class="button-toggles">
          <button v-bind:class="{active: tradeType === 'buy'}" @click.prevent="tradeType = 'buy'">Buy</button>
          <button v-bind:class="{active: tradeType === 'sell'}" @click.prevent="tradeType = 'sell'">Sell</button>
        </div>
        <div class="type-info">
          <input v-model="buyPrice" type="text" placeholder="Buy Price" />
          <input v-if="tradeType === 'sell'" v-model="sellPrice" type="text" placeholder="Sell Price" />
        </div>
        <div class="textarea">
          <textarea maxlength="500" v-model="note" placeholder="Add an optional note..." />
          <span>{{ note ? note.length : '0' }} / 500</span>
        </div>
        <div class="buttons">
          <button @click.prevent="deleteTrade" class="delete" v-if="selectedTrade != null">Delete</button>
          <button type="submit">{{ buttonLoading ? '...' : 'Save'}}</button>
        </div>
      </form>
    </Modal>
    <div class="wrapper">
      <div class="loading" v-if="loading">
        <div class="lds-ripple"><div></div><div></div></div>
      </div>
      <div class="empty-trades" v-if="tradeList && tradeList.length === 0 && !loading">
        <h3>Add trades to your journal</h3>
        <p>Start tracking your trades by adding a new trade to your journal.</p>
        <button class="add-trades" @click="createTrade()"><ion-icon name="add-circle"></ion-icon>Add Trade</button>
      </div>
      <header class="table-header" v-if="tradeList && tradeList.length > 0">
        <div>
          <h2>Trade Log</h2>
          <div class="trade-filters">
            <ul>
              <li v-bind:class="{'active': selectedButton === null}" @click="filterTrade(null)"><ion-icon name="list-circle"></ion-icon>All Trades</li>
              <li v-bind:class="{'active': selectedButton === 'buy'}" @click="filterTrade('buy')"><ion-icon name="card"></ion-icon>Buys</li>
              <li v-bind:class="{'active': selectedButton === '+'}" @click="filterTrade('+')"><ion-icon name="arrow-up-circle"></ion-icon>Gains</li>
              <li v-bind:class="{'active': selectedButton === '-'}" @click="filterTrade('-')"><ion-icon name="arrow-down-circle"></ion-icon>Losses</li>
            </ul>
          </div>
        </div>
        <button class="add-trades" @click="createTrade()"><ion-icon name="add-circle"></ion-icon>Add Trade</button>
      </header>
      <div class="empty-trades" v-if="filteredTrades && filteredTrades.length === 0">
        <h3>No trades found</h3>
      </div>
      <div class="table" v-if="(tradeList && tradeList.length > 0 && (!filteredTrades || filteredTrades.length > 0))">
        <header>
          <ul>
            <li>Trade</li>
            <li>Gain</li>
            <li>Time</li>
            <li>Notes</li>
          </ul>
        </header>
        <div>
          <div v-for="(trade , index) in (filteredTrades ? filteredTrades : tradeList)" v-bind:key="index" class="row" @click="selectTrade(index)">
            <div class="transaction">
              <span v-bind:class="{up: trade.tradeType === 'sell' && trade.buyPrice < trade.sellPrice, down: trade.tradeType === 'sell' && trade.sellPrice < trade.buyPrice}">
                <ion-icon v-if="trade.tradeType === 'buy'" name="card"></ion-icon>
                <ion-icon v-if="trade.tradeType === 'sell' && trade.buyPrice < trade.sellPrice" name="trending-up"></ion-icon>
                <ion-icon v-if="trade.tradeType === 'sell' && trade.sellPrice < trade.buyPrice" name="trending-down"></ion-icon>
              </span>
              <div>
                <h4>{{ trade.tradeType === 'buy' ? 'Bought' : 'Sold'}} {{ trade.symbol }}</h4>
                <h5>{{ trade.shares }} Shares {{ trade.symbol }} @ {{ trade.tradeType === 'buy' ? trade.buyPrice : trade.sellPrice }}</h5>
              </div>
            </div>
            <div class="gain-loss">
              <h4 v-if="trade.tradeType === 'sell'" class="percentage">+{{ (((trade.sellPrice - trade.buyPrice) / trade.buyPrice) * 100).toFixed(2) }}%</h4>
              <h4 v-if="trade.tradeType === 'buy'" class="percentage">--</h4>
            </div>
            <h4 class="timestamp">{{ formatDate(trade.updated) }}</h4>
            <p class="trade-note">{{ trade.notes || 'No notes for this trade' }}</p>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>
<script>
import axios from 'axios'

export default {
  name: 'TradeList',
  data() {
    return {
      hideHeaders: false,
      showModal: false,
      symbol: '',
      buyPrice: '',
      sellPrice: '',
      shares: '',
      note: '',
      tradeType: 'buy',
      trade_id: null,
      error: null,
      selectedTrade: null,
      tradeList: this.trades || [],
      filteredTrades: null,
      selectedButton: null,
      loading: true,
      buttonLoading: false,
      custID: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['https://www.otcmethod.com/stripe_customer_id'] : null,
      uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '')
    }
  },
  props: ['trades'],
  components: {},
  mounted() {
    if(this.tradeList.length > 0) {
      this.loading = false
    }
  },
  watch: {
    trades: function(newVal) {
      if(this.tradeList.length === 0) {
        this.tradeList = newVal
      }
      this.loading = false
    },
    symbol: function(newVal) {
      if(newVal) this.symbol = String(newVal).replaceAll(/[&/\\#=,()~%'":\s\t*?<>{}]/g, '').toUpperCase()
    },
    shares: function(newVal) {
      // Shares only allow numbers
      if(newVal) this.shares = String(newVal).replace(/[^0-9]/g, '')
    },
    buyPrice: function(newVal) {
      // Buy price only allow numbers and one decimal point and replace commas with decimals
      if(newVal) this.buyPrice = String(newVal).replace(/[^0-9.,]/g, '').replace(/,/g, '.').replace(/(\..*)\./g, '$1')
    },
    sellPrice: function(newVal) {
      // Sell price only allow numbers and one decimal point and replace commas with decimals
      if(newVal) this.sellPrice = String(newVal).replace(/[^0-9.,]/g, '').replace(/,/g, '.').replace(/(\..*)\./g, '$1')
    },
    note: function(newVal) {
      // Notes only allow letters, numbers and some special characters
      if(newVal) this.note = String(newVal).replaceAll(/[\\#~%'":\s\t*?<>{}]/g, '')
    }
  },
  methods: {
    createTrade() {
      this.trade_id = null;
      this.symbol = '';
      this.buyPrice = '';
      this.sellPrice = '';
      this.shares = '';
      this.note = '';
      this.tradeType = 'buy';
      this.error = null;
      this.selectedTrade = null;
      this.showModal = true;
    },
    selectTrade(index) {
      this.selectedTrade = index
      this.symbol = this.tradeList[index].symbol
      this.buyPrice = this.tradeList[index].buyPrice
      this.sellPrice = this.tradeList[index].sellPrice
      this.shares = this.tradeList[index].shares
      this.note = this.tradeList[index].notes
      this.tradeType = this.tradeList[index].tradeType
      this.trade_id = this.tradeList[index].trade_id
      this.showModal = true
    },
    formatDate(date) {
      // Format date to MM/DD/YYYY HH:MM:SS
      return new Date(date).toLocaleString()
    },
    filterTrade(filter) {
      // Submit post request for filtered data
      this.selectedButton = filter
      if(filter === null) {
        this.filteredTrades = null
      } else {
        axios.post(`${this.uri}/api/journal/trades`, { filter })
          .then(res => {
            this.filteredTrades = res.data.trades
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    deleteTrade() {
      axios.delete(`${this.uri}/api/journal/trades/delete` , {data: {
        trade_id: this.tradeList[this.selectedTrade].trade_id,
        custID: this.custID
      }})
      .then(x => {
        this.tradeList.splice(this.selectedTrade, 1)
        this.selectedTrade = null
        this.showModal = false
      })
      .catch(err => {
        console.log(err)
      })
    },
    addTrade() {
      this.error = null;
      if(this.symbol === '') {
        this.error = 'Please enter a stock symbol'
        return
      }
      if(this.buyPrice === '') {
        this.error = 'Please enter a trade price'
        return
      }
      if(this.shares === '') {
        this.error = 'Please enter the number of shares'
        return
      }
      // this.price only integer and decimal numbers
      if(!this.buyPrice.match(/^[0-9]+(\.[0-9]{1,2})?$/)) {
        this.error = 'Please enter a valid trade price'
        return
      }
      // this.shares only integer numbers
      if(!this.shares.match(/^[0-9]+$/)) {
        this.error = 'Please enter a valid number of shares'
        return
      }
      if(this.symbol !== '' && this.buyPrice !== '' && this.shares !== '' && this.error === null) {
        const newTrade = {
          symbol: this.symbol,
          buyPrice: this.buyPrice,
          sellPrice: this.sellPrice,
          sellProfit: this.tradeType === 'sell' ? (this.sellPrice > this.buyPrice ? '+' : '-') : null,
          shares: this.shares,
          note: this.note,
          tradeType: this.tradeType,
          trade_id: this.selectedTrade,
          custID: this.custID,
          updated: new Date().getTime()
        }
        // add trade to database
        this.buttonLoading = true;
        axios.post(`${this.uri}/api/journal/trades/update`, newTrade).then(res => {
          this.tradeList = [newTrade , ...this.tradeList];
          this.showModal = false;
          this.selectedButton = null;
          this.filteredTrades = null;
          this.buttonLoading = false;
        }).err(err => {
          console.log(err);
          this.buttonLoading = false;
          this.error = 'There was an error adding your trade. Please try again.'
        });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 1092px) {
      width: 768px;
  }
  @media(max-width: 868px) {
      width: 576px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
.add-trades {
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  background: #58AAA3;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 7px;
  padding: 7px 0.75rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  &:hover {
    background: #4c9c96;
  }
  ion-icon {
    margin-right: 0.25rem;
  }
}
#trades {
  height: 100%;
  margin-bottom: 1rem;
  table {
    width: 100%;
    height: 100%;
    background: linear-gradient(324deg, #f3f3f3, #ffffff);
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.099);
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 0 0 0;
    h2 {
      margin: 0 0 0.5rem 0;
      color: #000;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
    }
    .trade-filters {
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0 0 0 0;
        margin: 0 0 0.75rem 0;
        font-size: 1rem;
        li {
          display: flex;
          align-items: center;
          margin: 0 0.5rem 0 0;
          color: #848484;
          cursor: pointer;
          transition: 0.2s all;
          padding: 0.25rem 0.5rem;
          border-radius: 5px;
          &.active {
            background: #848484;
            color: #FFFFFF;
          }
          &:hover {
            transition: 0.2s all;
            background: #e7e7e7;
          }
          ion-icon {
            margin: 0 0.25rem 0 0;
          }
        }
      }
    }
    .table {
      width: 100%;
      background: #F2F2F2;
      border-radius: 10px;
      margin: 0 0 2rem 0;
      header {
        width: 100%;
        background: #e7e7e7;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        ul {
          display: flex;
          align-items: center;
          padding: 0.5rem 0.75rem;
          margin: 0 0 0 0;
          list-style: none;
          li {
            font-size: .825rem;
            font-weight: 600;
            text-align: left;
            &:nth-child(1) {
              flex: 0 0 20%;
            }
            &:nth-child(2) {
              flex: 0 0 25%;
              text-align: center;
            }
            &:last-child {
              margin-left: auto;
            }
          }
        }
      }
      .row {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid #d2d2d2;
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
          background: #e7e7e7;
        }
        &:last-child {
          border-bottom: none;
        }
        .transaction {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 0 0 20%;            
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            h4 {
              margin: 0 0 0.25rem 0;
              font-weight: 600;
              color: #000;
            }
            h5 {
              margin: 0 0 0 0;
              font-weight: 500;
              color: #4f4f4f;
            }
          }
          span {
            width: 2.25rem;
            height: 2.25rem;
            border-radius: 5px;
            margin: 0 0.75rem 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #a7a7a776;
            &.up {
              background: #58aa6276;
            }
            &.down {
              background: #FF5C5C76;
            }
          }
        }
        .gain-loss {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 0 0 25%;
          h4 {
            margin: 0 0 0.25rem 0;
            font-weight: 600;
            color: #000;
          }
          h5 {
            margin: 0 0 0 0;
            font-weight: 500;
            color: #4f4f4f;
          }
        }
        .timestamp {
          margin: 0 0 0 0;
          padding: 0.25rem 0.5rem;
          font-weight: 600;
          font-size: .825rem;
          border-radius: 5px;
          background: #d2d2d2;
          color: #4f4f4f;
        }
        .trade-note {
          margin-left: auto;
          font-size: .825rem;
          font-weight: 400;
          color: #848484;
        }
      }
    }
  }
}
.errors {
  margin: 0 0 0.5rem 0;
  display: block;
  color: #d01414;
  font-weight: 500;
}
form {
  display: flex;
  flex-direction: column;
  input {
    border: 1px solid #e3e3e3;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
  }
  .form-header {
    display: flex;
    align-items: center;
    margin: 0 0 0.75rem 0;
    .symbol {
      width: 100px;
      margin: 0 1rem 0 0;
    }
    .shares {
      width: 100%;
    }
  }
  .button-toggles {
    text-align: left;
    display: flex;
    align-items: center;
    align-self: flex-start;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    // border: 1px solid #d4d4d4;
    // background: #f0f0f0;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    // padding: 0.5rem 0.5rem;
    margin: 0 0 0.75rem 0;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar { width: 0 !important; height: 0 !important; }
    button {
      border: 1px solid #e3e3e3;
      cursor: pointer;
      color: #737a82;
      margin: 0 0 0 0;
      transition: 0.2s all;
      font-weight: 600;
      font-size: .825rem;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      margin: 0 0.75rem 0 0;
    }
    .active {
      color: #FFF;
      position: relative;
      transition: 0.2s all;
      background: #2E95AC;
      border-radius: 5px;
      border: 1px solid #2E95AC;
    }
  }
  .type-info {
    margin: 0 0 0.75rem 0;
    display: flex;
    align-items: center;
    input {
      width: calc(50% - 1rem);
    }
    input:first-child {
      margin-right: 0.5rem;
    }
    input:nth-child(2) {
      margin-left: 0.5rem;
    }
  }
  .textarea {
    display: flex;
    position: relative;
    span {
      position: absolute;
      right: 0.25rem;
      bottom: 1rem;
      font-size: 0.75rem;
      padding: .25rem;
      color: #848484;
    }
  }
  textarea {
    width: 100%;
    margin: 0 0 0.75rem 0;
    height: 75px;
    border: 1px solid #e3e3e3;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    resize: none;
  }
  button[type="submit"] , .delete {
    padding: 0.5rem 0;
    background: #58AAA3;
    border: none;
    border-radius: 5px;
    color: #FFF;
  }
  .buttons {
    display: flex;
    align-items: center;
    button {
      width: 100%;
      cursor: pointer;
      transition: 0.2s all;
      &:hover {
        opacity: 0.8;
      }
      &:nth-child(2) {
        margin-left: 0.5rem;
      }
      &.delete {
        background: #FF5C5C ;
        margin-right: 0.5rem;
      }
    }
  }
}
.empty-trades {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0 0 0;
  h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
  }
  p {
    margin: 0 0 1.5rem 0;
    font-size: 1rem;
  }
  button {
    align-self: center;
  }
}

.loading {
  width: 100%;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  background: #878787;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
