<template>
  <section class="wrapper" v-if="showPromo === true">
    <slot />
    <button @click="close">Close</button>
  </section>
</template>

<script>
export default {
  name: 'promo',
  props: ['storageKey' , 'showArgs'],
  data() {
    return {
      showPromo: false,
    }
  },
  created() {
    if(!this.showArgs) {
      this.showPromo = false;
    }
    let hide = JSON.parse(localStorage.getItem(this.storageKey));

    if(hide === null && this.showArgs !== false) {
      this.showPromo = true;
    }
  },
  methods: {
    close() {
      this.showPromo = false;
      localStorage.setItem(this.storageKey , true);
    },
  }
}
</script>


<style lang="less" scoped>
  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(25, 136, 112);
    padding: .5rem 1rem;
    border-radius: 4px;
    box-sizing: border-box;
    @media(max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 1rem;
      width: 90% !important;
      h5 {
        text-align: left;
      }
      button {
        margin: .75rem 1rem 0 0 !important;
      }
    }
    &.wrapper {
      width: 1200px;
      margin: 0 auto;
      @media(max-width: 1300px) {
          width: 992px;
      }
      @media(max-width: 1092px) {
          width: 768px;
      }
      @media(max-width: 868px) {
          width: 576px;
      }
      @media(max-width: 676px) {
        width: auto;
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }
    margin-top: 1rem !important;
    h5 {
      margin: 0 0 0 0;
      color: #FFFFFF;
      text-align: left;
    }
    button {
      margin: 0 0 0 1rem;
      cursor: pointer;
      background: none;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
      border-radius: 3px;
      font-weight: 600;
      padding: 5px 10px;
      &:first-child {
        background: #FFFFFF;
        color: #000000;
      }
    }
  }
</style>
