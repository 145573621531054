import auth from './auth';
import router from "./router";
import store from "./store";
import Vue from "vue";
import App from "./App.vue";

import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css'

import "./registerServiceWorker";

Vue.use(auth);
// Components
Vue.component('Modal', VueModal)

// Configs
Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/]

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");