<template>
  <section id="landing-call">
    <div class="wrapper">
      <div id="call-header">
        <div id="symbol-list">
          <div v-for="(stock , i) in symbols" v-bind:key="i" class="symbol-row">
            <div v-for="(ticker , i) in stock" v-bind:key="i" class="symbol-badge">
              <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.67383 16.8809H14.3174C16.2246 16.8809 17.209 15.8965 17.209 14.0156V3.31934C17.209 1.43848 16.2246 0.454102 14.3174 0.454102H3.67383C1.77539 0.454102 0.782227 1.42969 0.782227 3.31934V14.0156C0.782227 15.8965 1.77539 16.8809 3.67383 16.8809ZM11.874 11.0537C11.4082 11.0537 11.1094 10.7373 11.1094 10.2275V8.78613L11.2324 7.47656L10.0195 8.8125L6.85547 11.9766C6.67969 12.1523 6.46875 12.2402 6.21387 12.2402C5.74805 12.2402 5.40527 11.915 5.40527 11.4229C5.40527 11.2031 5.50195 10.9834 5.66895 10.8252L8.85059 7.64355L10.1953 6.42188L8.95605 6.5625H7.42676C6.92578 6.5625 6.5918 6.26367 6.5918 5.79785C6.5918 5.33203 6.91699 5.0332 7.41797 5.0332H11.6895C12.2695 5.0332 12.6299 5.33203 12.6299 5.97363V10.21C12.6299 10.7109 12.3311 11.0537 11.874 11.0537Z" fill="#1C1C1E"/>
              </svg>
              <span class="symbol">{{ ticker.symbol }}</span>
              <span class="percent">{{ ticker.percent }}</span>
            </div>
          </div>
          <svg width="626" height="1003" viewBox="0 0 626 1003" fill="none" xmlns="http://www.w3.org/2000/svg" id="flare">
            <g opacity="0.7">
              <path opacity="0.7" d="M-264 619.883L262.695 2.67346" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-259.779 670.226L300.014 13.1616" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-242.873 705.267L332.777 29.1356" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-219.076 732.052L369.45 40.4306" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-200.548 765.156L406.435 51.3218" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-178.753 794.335L445.684 59.4973" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-157.126 823.728L475.729 78.7255" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-135.476 853.068L512.497 89.8857" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-113.155 881.601L542.4 109.302" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-93.4336 913.307L567.296 134.742" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-71.0674 941.786L595.386 156.337" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-47.3408 968.625L623.07 178.443" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
              <path opacity="0.7" d="M-27.335 999.954L619.374 238.279" stroke="#969696" stroke-width="4.76906" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0.72 0.72"/>
            </g>
          </svg>
        </div>
        <div id="history-table">
          <h3>Mastering the OTC</h3>
          <p>Returns for many of the top trades from OTCMethod have dramatically outpaced market returns in the same period</p>
          <div id="history-container">
            <div id="trading-years">
              <div>
                <h5>2019</h5>
                <span>+388.26% Gain</span>
              </div>
              <div>
                <h5>2020</h5>
                <span>+968.85% Gain</span>
              </div>
              <div>
                <h5>2021</h5>
                <span>+2,235.37% Gain</span>
              </div>
            </div>
            <div id="stock-bar">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'landingCall',
  data() {
    return {
      symbols: [
        [{symbol: 'ALPP', percent: '+60,000%'}, {symbol: 'ENZC', percent: '+57,000%'}],
        [{symbol: 'BLQC', percent: '+30,000%'}, {symbol: 'GRNF', percent: '+29,000%'}, {symbol: 'ILUS', percent: '+16,000%'}],
        [{symbol: 'MJWL', percent: '+12,000%'}, {symbol: 'FORW', percent: '+12,000%'}, {symbol: 'MTRT', percent: '+10,000%'}],
        [{symbol: 'LCLP', percent: '+9,200%'}, {symbol: 'BRLL', percent: '+4,700%'}, {symbol: 'GVSI', percent: '+4,500%'}],
        [{symbol: 'WNFT', percent: '+4,250%'}, {symbol: 'DUTV', percent: '+3,300%'}, {symbol: 'AABB', percent: '+3,000%'}],
        [{symbol: 'QIND', percent: '+1,500%'},{symbol: 'WSRC', percent: '+1,600%'}, {symbol: 'VAPR', percent: '+1,500%'}],
        [{symbol: 'ILUS', percent: '+16,000%'}, {symbol: 'QIND', percent: '+1,500%'}, {symbol: 'GDVM', percent: '+1,000%'}],
      ]
    }
  },
  methods: {
    login() {
      this.$auth.login();
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 991px) {
      width: 868px;
  }
  @media(max-width: 868px) {
      width: 676px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
  #landing-call {
    overflow: hidden;
    position: relative;
  }
  #flare {
    position: absolute;
    left: -9%;
    z-index: 1;
    opacity: .7;
  }
  #history-table {
    width: 50%;
    margin-left: auto;
    margin-top: 8rem;
    padding: 1.5rem;
    z-index: 10;
    position: relative;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    background: #FDFDFD;
    border-top: 1px solid #C0C0C0;
    border-left: 1px solid #C0C0C0;
    border-right: 1px solid #C0C0C0;
    box-shadow: 0px 4.47419px 31.3193px rgba(0, 0, 0, 0.06);
    animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      animation-delay: .4s;
    @media(max-width: 991px) {
      width: 75%;
    }
    @media(max-width: 768px) {
      width: auto;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    h3 {
      text-align: left;
      margin: 0 0 .75rem 0;
      font-weight: 600;
      font-size: 1.5rem;
      color: #000;
    }
    p {
      text-align: left;
      margin: 0 0 2.5rem 0;
      font-size: .825rem;
      font-weight: 400;
      line-height: 1.5rem;
      width: 80%;
    }
    #history-container {
      display: flex;
      flex-direction: column;
      #trading-years {
        display: flex;
        align-items: flex-start;
        margin: 0 0 .75rem 0;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          &:first-child {
            flex: 0 0 25%;
          }
          &:nth-child(2) {
            flex: 0 0 35%;
          }
          h5 {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.5rem;
            color: #000;
            margin: 0 0 .25rem 0;
            @media(max-width: 568px) {
              font-size: 1.25rem;
            }
          }
          span {
            font-size: .825rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: #818181;
            @media(max-width: 568px) {
              font-size: .75rem;
            }
          }
        }
      }
      #stock-bar {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        span {
          height: 35px;
          width: 33%;
          background: #7ACF92;
          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background: #B9D8C2;
            width: 25%;
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background: #58AA6F;
            width: 100%;
          }
        }
      }
    }
  }
  #symbol-list {
    position: absolute;
    left: 3rem;
    top: 3.5rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media(max-width: 1600px) {
      left: -2rem;
      z-index: 2;
    }
    @media(max-width: 1400px) {
      left: -7rem;
      z-index: 2;
    }
    .symbol-row {
      display: flex;
      align-items: center;
      margin: 0 0 1rem 0;
      z-index: 10;
      &:nth-child(1) {
        margin-right: -1rem;
      }
      &:nth-child(2) {
        margin-right: -4rem;
      }
      &:nth-child(3) {
        margin-right: -8rem;
      }
      &:nth-child(4) {
        margin-right: -3rem;
      }
      &:nth-child(5) {
        margin-right: 2rem;
      }
      &:nth-child(6) {
        margin-right: -4rem;
      }
      &:nth-child(7) {
        margin-right: -2rem;
      }
      .symbol-badge {
        display: flex;
        align-items: center;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        background: #A3D1C2;
        border: 1px solid #58A98F;
        align-self: flex-start;
        padding: .25rem;
        margin: 0 0 0 1rem;
        animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation-delay: .1s;
        &:nth-child(3n) {
          animation-delay: .3s;
        }
        &:nth-child(2n) {
          animation-delay: .2s;
        }
        svg {
          margin: 0 .25rem 0 0;
          path {
            fill: #50967F;
          }
        }
        .symbol {
          font-weight: 800;
          color: #50967F;
          padding: 0 .5rem 0 0;
          margin: 0 .5rem 0 0;
          border-right: 1px dashed #58A98F;
        }
        .percent {
          padding: .125rem .25rem;
          border-radius: 3px;
          background: #50967F;
          color: #FFF;
          font-size: .825rem;
        }
      }
    }
  }
</style>
