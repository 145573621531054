import audio from '../../api/audio';

const state = {
  latest: []
}

const actions = {
  getLatestAudio({ commit }) {
    audio.getAudio().then((response) => {
      commit('setAudio' , response.data)
    })
    .catch((err) => {
      this.error = err
    });
  }
}

const mutations = {
  setAudio(state , audio) {
    state.latest = audio
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}