<template>
  <section id="article-node">
    <div class="article-header" v-if="article">
      <div class="wrapper">
        <h1>{{ article.title }}</h1>
        <p>{{ article.preview }}</p>
      </div>
    </div>
    <div class="wrapper article" v-if="article">
      <div v-bind:class="{ 'logged-out': !$auth.isAuthenticated() }">
        <div class="article-body" v-html="formatBody(article.body , article.title)">
          {{ formatBody(article.body , article.title) }}
        </div>
      </div>
      <aside>
        <div class="otcre-promo">
          <a href="https://www.filingre.com" target="_blank"></a>
          <span class="otcre-logo">
            <svg viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard-Copy-3" transform="translate(-197.000000, -54.000000)">
                  <g id="Group-4" transform="translate(197.000000, 54.000000)">
                    <g id="Group-2-Copy-2" fill="#000000">
                      <rect id="Rectangle-Copy" x="0" y="0" width="36" height="36" rx="7"></rect>
                    </g>
                    <g id="Group-3" transform="translate(14.000000, 7.000000)" stroke="#FFFFFF">
                      <circle id="Center" stroke-width="2.71428571" cx="4" cy="13" r="5.35714286"></circle>
                      <path d="M4,7 L4,0" id="Path-3" stroke-width="3" stroke-linecap="round"></path>
                      <path d="M4,22 L4,18.4644661" id="Path-3-Copy" stroke-width="3" stroke-linecap="round"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span>filing:re</span>
          </span>
          <p>Get the most important Penny Stock Data Streamed Live to your Browser!</p>
          <button @click.prevent="login">Learn More</button>
        </div>
        <div class="read-more" v-if="!$auth.isAuthenticated()">
          <div>
            <h2>Get Full Access</h2>
            <p>Enjoy our exclusive trading tips and insight when you subscribe!</p>
            <button @click.prevent="login">Access Now</button>
          </div>
        </div>
      </aside>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ArticlesNode',
  computed: mapGetters('articles' , {
    article: 'getArticleNode'
  }),
  methods: {
    formatBody(body , title) {
      let stripped = String(body).replace(/<img /g , '<img style="width: 100%" ');

      return stripped;
    },
    login() {
      this.$auth.login();
    }
  }
}
</script>

<style lang="less">
#article-node {
  .main-title {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
  }
  .read-more {
    width: 100%; 
    text-align: center; 
    margin: 0; padding: 30px 0; 
    background-image: linear-gradient(to bottom, transparent, white);
    display: flex;
    justify-content: center;
    div {
      border-radius: 4px;
      background: #5b7b98;
      // width: 60%;
      margin: 0 0 5rem 0;
      padding: 2rem 3rem;
      h2 {
        margin: 0 0 1rem 0;
        color: #FFFFFF;
      }
      p {
        width: 350px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        @media(max-width: 400px) {
          width: 90%;
        }
      }
      button {
        margin: 1rem 0 0 0;
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        padding: 10px 25px 10px 25px;
        color: #000000;
        background: #FFFFFF;
        box-shadow: 0 4px 5px rgba(31, 31, 34, 0.11), 0 1px 2px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        border: none;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .article {
    display: flex;
    justify-content: space-between;
    .otcre-promo {
      background: #FFFFFF;
      border-radius: 4px;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, .2);
      align-self: flex-start;
      margin: 3rem 0 0 0;
      flex: 0 0 320px;
      text-align: left;
      padding: 2rem 2rem 2rem 2rem;
      position: relative;
      @media(max-width: 1300px) {
        flex: 0 0 250px;
      }
      @media(max-width: 1100px) {
        display: none;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
      }
      h4 {
        margin: 0 0 1rem 0;
        color: #000000;
        font-size: 1.5rem;
      }
      .otcre-logo {
        display: flex;
        align-items: center;
        font-size: 18px;
        white-space: nowrap;
        svg {
          width: 20px;
          height: 20px;
          margin: 0 7px 0 0;
        }
        span {
          height: 18px;
          line-height: 16px;
          font-weight: 700;
          font-size: 20px;
          color: #000000;
          font-family: 'Proxima Nova';
        }
      }
      p {
        color: #000000;
        line-height: 30px;
        font-weight: 500;
      }
      button {
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        padding: 10px 25px 10px 25px;
        color: #FFFFFF;
        background: #56CC85;
        box-shadow: 0 4px 5px rgba(31, 31, 34, 0.11), 0 1px 2px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        border: none;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .wrapper {
      width: 1200px;
      margin: 0 auto;
      @media(max-width: 1300px) {
          width: 992px;
      }
      @media(max-width: 1092px) {
          width: 768px;
      }
      @media(max-width: 868px) {
          width: 576px;
      }
      @media(max-width: 676px) {
        width: auto;
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
    }
    .article-header {
      padding: 4rem 0 4rem 0;
      text-align: left;
      background: #5b7b98;
      h1 {
        font-size: 65px;
        line-height: 77px;
        font-weight: 900;
        margin: 0 0 0 0;
        text-align: left;
        color: #FFFFFF;
        @media(max-width: 690px) {
          font-size: 42px;
        }
      }
      p {
        line-height: 36px;
        font-size: 22px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 0;
        @media(max-width: 690px) {
          font-size: 18px;
        }
      }
    }
    .article-body {
      text-align: left;
      width: 750px;
      padding: 25px 0 0 0;
      margin: 0 0 25px 0;
      @media(max-width: 1300px) {
        width: 600px;
      }
      @media(max-width: 1100px) {
        width: 100%;
      }
      strong {
        font-size: 2rem;
        line-height: 45px;
      }
      p {
        font-size: 1rem;
        line-height: 30px;
        font-weight: 500;
      }
      @media(max-width: 991px) {
        width: 100%;
        padding: 25px 0 0 0;
      }
    }
}
</style>
