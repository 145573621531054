<template>
  <section>
    <div class="audio-list" v-for="(audio , index) in timestampSort.slice(0 , 25)" v-bind:key="index">
      <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" :src="'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/' + audio.track_id + '&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'"></iframe>
    </div>
  </section>
</template>


<script>
import _ from 'lodash';

export default {
  name: 'list',
  props: ['audioData'],
  computed: {
    timestampSort: function() {
      return _.orderBy(this.audioData , 'timestamp' , 'desc')
    }
  }
}
</script>

<style lang="less" scoped>
  .audio-list {
    margin-bottom: 2rem;
  }
</style>
