<template>
  <section class="payment">
    <div class="wrapper">
      <div class="payment-logo">
        <logo />
        <h6 class="logout" @click="logout">Logout</h6>
      </div>
      <div class="payment-main">
        <div class="features">
          <features />
        </div>
        <div class="form">
          <signup :verify-error="error" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

import logo from '@/components/logo.vue';
import features from '@/components/payment/features.vue';
import signup from '@/components/payment/signup.vue';
export default {
  name: 'payment',
  components: {
    logo,
    features,
    signup
  },
  data() {
    return {
      error: null
    }
  },
  created() {;
    this.verify();
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    verify() {
      axios({
        method: 'post',
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/payment/verify',
        data: {

        }
      }).then((response) => {
        if(typeof response.data.error !== 'undefined') {
          this.error = response.data.error;
        } else if (typeof response.data.redirect !== 'undefined' && response.data.redirect === '/') {
          window.location.href = '/';
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    width: 800px;
    margin: 0 auto 0 auto;
    padding-top: 59px;
    height: calc(100vh - 59px);
    display: flex;
    flex-direction: column;
    @media(max-width: 868px) {
        width: 576px;
        flex-direction: column;
        height: auto;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  .payment-logo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    #nav-logo {
      margin: 0 0 0 30px;
    }
  }
  .payment-main {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  .form {
    @media(max-width: 868px) {
      width: 100%;
    }
  }
  .logout {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    margin: 25px 35px 25px 0;
  }
</style>
