import Vue from 'vue'
import Vuex from 'vuex'
import videos from './modules/videos'
import articles from './modules/articles'
import audio from './modules/audio'
import journal from './modules/journal'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    videos,
    articles,
    audio,
    journal
  }
});