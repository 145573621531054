<template>
  <section>
    <div class="video">
      <div class="wrapper title" v-if="metadata">
        <h1>{{ metadata.title }}</h1>
      </div>
      <div class="wrapper">
        <aside v-if="metadata">
          <div class="row">
            <div>
              <h6>Duration</h6>
              <h5>{{ filterDuration(metadata.duration) }}</h5>
            </div>
            <div>
              <h6>Upload Date</h6>
              <h5>{{ filterDate(metadata.timestamp) }}</h5>
            </div>
          </div>
          <div v-if="nodeId" v-show="$auth.isAuthenticated()" class="create-note">
            <router-link :to="{
              path: '/journal?video=' + nodeId,
              params: { videoTitle: metadata.title },
              query: { videoTitle: metadata.title }
            }"><button><ion-icon name="create"></ion-icon>Create Note</button></router-link>
          </div>
          <div class="chapters" v-if="chapters && chapters.length > 0">
            <h3>Chapters</h3>
            <div class="chapter-table">
              <div v-for="chapter , index in chapters" class="chapter-row" :key="index" @click="selectTime(chapter.startTime)">
                <ion-icon name="book"></ion-icon>
                <span>{{ chapter.title }}</span>
                <span>{{ Math.trunc(chapter.startTime / 60) }}:{{ chapter.startTime % 60 }}</span>
              </div>
            </div>
          </div>
          <div v-if="!$auth.isAuthenticated()">
            <div class="logged-out-player">
              <div class="row">
                <h3>Log in to watch this video</h3>
                <button @click.prevent="login">Log In</button>
              </div>
              <div class="row">
                <h3>Not a member? Join today!</h3>
                <button @click.prevent="login">Join Now</button>
              </div>
            </div>
            <span>Access Private Chat and Video Library For As Low as $49.99/month</span>
          </div>
        </aside>
        <vue-vimeo-player 
          v-if="$auth.isAuthenticated() && nodeId"
          ref="player"
          :options="{
            controls: true,
            title: false,
            byline: false,
            portrait: false
          }"
          :video-url="'https://player.vimeo.com/video/' + nodeId"
          @ready="onReady"
        />
        <img v-if="metadata && !$auth.isAuthenticated()" class="logged-out-image" :src="metadata.thumbnail" />
      </div>
    </div>
    <div class="wrapper" style="margin-top: 2rem" v-if="!$auth.isAuthenticated() && metadata">
      <div class="logged-out-description" v-if="metadata.description">
        <h2>What topics does this lesson cover? <span>AI Description</span></h2>
        <h3>This video description was generated by AI. If it says something wild, let us know at support@otcmethod.com</h3>
        <p v-html="metadata.description"></p>
      </div>
    </div>
    <landing-footer v-if="!$auth.isAuthenticated()"></landing-footer>
    <div class="view-more" v-if="$auth.isAuthenticated()">
      <videolist :video-data="videos" :ajax-more="ajaxMore" ajax-override="/videos" :limit="4" :section-title="'Latest Videos'" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import axios from 'axios';
import videolist from '@/components/home/default/videos.vue'
import landingFooter from '@/components/videos/landing-footer.vue'

export default {
  name: 'VideosNode',
  props: ['embed'],
  components: {
    videolist,
    vueVimeoPlayer,
    landingFooter
  },
  data() {
    // Take url 
    return {
      nodeId: null,
      nodeTitle: this.$route.path.split('/')[this.$route.path.split('/').length - 1],
      metadata: null,
      ajaxMore: false,
      player: null,
      chapters: []
    }
  },
  watch: {
    $route(to, from) {
      this.nodeId = null
      this.nodeTitle = to.path.split('/')[to.path.split('/').length - 1]
      this.getMetadata();
    }
  },
  mounted () {
    this.getMetadata();
  },
  computed: mapState({
    videos: state => state.videos.videos.latest
  }),
  methods: {
    login() {
      this.$auth.login();
    },
    async getMetadata() {
      try {
        let req = await axios.post((process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos/metadata' , {nodeId: this.$route.path.split('/')[this.$route.path.split('/').length - 2]});
        // If req.data === null redirect to /videos
        if (req.data === null || encodeURIComponent(String(req.data.title).replace(/\s+/g , '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()) !== this.nodeTitle) {
          this.$router.push('/videos');
        } else {
          // Set Metadata
          this.metadata = req.data;
          this.nodeId = req.data.uri;
        }
      } catch(e) {
        console.log(e);
        this.chapters = [];
      }
    },
    async onReady() {
      // Get chapters
      setTimeout(async () => {
        if(this.$refs.player) this.chapters = await this.$refs.player.player.getChapters();
       }, 250);
    },
    filterDate(date) {
      return (new Date(date).getMonth() + 1) + '/' + new Date(date).getDate() + '/' + new Date(date).getFullYear();
    },
    filterDuration(duration) {
      return (Math.floor((duration / 60) * 100) / 100) + ' Minutes';
    },
    filterPlaylist(playlist) {
      return playlist.slice(0 , 1).toUpperCase() + playlist.slice(1)
    },
    selectTime(timestamp) {
      if(this.$refs.player) this.$refs.player.player.setCurrentTime(timestamp);
    }
  }
}
</script>

<style lang="less">
  .view-more {
    width: 100%;
    border-top: 1px solid #EAEAEA;
    background: #f0f0f0;
    margin: 3rem 0 0 0;
    h1 {
      margin: 0 0 0 0;
    }
    .videos {
      padding: 1rem 0 1rem 0;
      button {
        display: none;
      }
    }
  }
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
    &:empty {
      height: 90vh;
    }
  }
  .video {
    margin: 45px 0 0 0;
    .title {
      h1 {
        font-size: 1.75rem;
        font-weight: 700;
        margin: 0 0 1.5rem 0;
        color: #2c3e50;
        position: relative;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        text-align: left;
        line-height: 2.5rem;
      }
    }
    .logged-out-player {
      box-shadow: 0px 2px 6px rgba(94, 94, 94, 0.28);
      border: 1px solid #d4d4d4;
      width: 100%;
      margin-right: 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .row {
        padding: 0 1rem;
        h3 {
          margin: 0.5rem 0.5rem 0.5rem 0;
          text-align: left;
          font-size: 1rem;
          line-height: 1.5rem;
        }
        &:first-child {
          border-bottom: 1px solid #d4d4d4;
          margin-bottom: 0;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0;
          align-items: center;
        }
      }
      & + span {
        display: block;
        margin: .75rem 0 .5rem 0;
        color: #818181;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .75rem;
        line-height: 1.5rem;
        font-weight: 700;
      }
      button {
        background-color: #a4b8d0;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        margin: 10px 0;
        font-size: .825rem;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        &:hover {
          transition: 0.2s all;
          opacity: .7;
        }
      }
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      @media(max-width: 1200px) {
        flex-direction: column-reverse;
      }
      aside {
        flex: 0 0 23%;
        margin: 0 1rem 0 0;
        @media(max-width: 1300px) {
          flex: 0 0 30%;
        }
        @media(max-width: 1200px) {
          margin: 1rem 0 0 0;
        }
        .create-note {
          margin: 0 0 1rem 0;
          a {
            display: flex;
            width: 100%;
            text-decoration: none;
            button {
              width: 100%;
              font-weight: 600;
              font-size: .825rem;
              color: #ffffff;
              padding: 0.35rem 0.5rem;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              transition: 0.2s all;
              background: #000000;
              border: 1px solid #383838;
              box-shadow: inset 0px -1px 0px 0px rgb(63 63 63), 0px 2px 5px #00000012;
              &:hover {
                transition: .2s all;
                background: #1b1b1b;
                border: 1px solid #1c1c1c;
              }
              &:active {
                transition: .2s all;
                box-shadow: none;
                background: #272727;
                border: 1px solid #0e0e0e;
                color: #e4e4e4;
              }
              ion-icon {
                margin-right: .25rem;
              }
            }
          }
        }
        .chapters {
          flex: 0 0 23%;
          box-shadow: 0px 2px 6px rgba(94, 94, 94, 0.28);
          border: 1px solid #d4d4d4;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-content: flex-start;
          padding: .75rem 0;
          align-self: flex-start;
          max-height: 100%;
          overflow-y: hidden;
          max-height: calc(462px - 41px - 3rem);
          h3 {
            font-weight: 700;
            margin: 0 0 0 .75rem;
            align-self: flex-start;
          }
          .chapter-table {
            overflow-y: scroll;
            overscroll-behavior: contain;
            margin: .75rem .75rem 0 .75rem;
            display: flex;
            flex-direction: column;
            .chapter-row {
              display: flex;
              align-items: center;
              padding: .5rem 0;
              width: 100%;
              border-bottom: 1px solid #d4d4d4;
              cursor: pointer;
              transition: .2s all;
              &:hover {
                transition: .2s all;
                background-color: #f0f0f0;
              }
              &:last-child {
                border-bottom: none;
              }
              ion-icon {
                margin: 0 .25rem 0 0;
                font-size: .825rem;
                height: 0.75rem;
              }
              span {
                font-size: .825rem;
                text-align: left;
              }
              span:last-child {
                margin-left: auto;
                color: #214572;
                background: #a4b8d0;
                align-self: self-start;
                padding: .125rem .25rem;
                border-radius: 3px;
                font-weight: 700;
                font-size: .825rem;
              }
            }
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 0 1rem 0;
          @media(max-width: 1200px) {
            justify-content: space-between;
            flex: 0 0 25%;
          }
          .tag-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: row;
          }
          div {
            display: flex;
            flex-direction: column;
            .tag , .playlist {
              background: #79bfff45;
              color: #2c3e50;
              padding: 2px 10px;
              border-radius: 4px;
              margin: 0 5px 5px 0;
            }
            h5 {
              align-self: flex-start;
              font-weight: 800;
              font-size: .75rem;
              text-transform: uppercase;
              text-align: left;
              letter-spacing: 0px;
              color: #FFF;
              padding: .25rem .5rem;
              margin: 0 0 0 0;
              border-radius: 5px;
              background: #758797;
            }
            h6 {
              margin: 0 0 5px 0;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-weight: 700;
              color: #5a5a5a;
              text-align: left;
            }
          }
          .tag-list {
            width: 100%;
          }
        }
        h1 {
          margin: 0 0 1rem 0;
        }
      }
      div[data-vimeo-initialized="true"] {
        width: 100%;
      }
      iframe {
        width: 100%;
        height: 500px;
        @media(max-width: 1300px) {
          height: 450px;
        }
        @media(max-width: 768px) {
          height: 250px;
        }
        #player {
          min-height: 350px;
        }
      }
    }
  }
  .logged-out-image {
    width: 100%;
  }
  .logged-out-description {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin: 0 0 2rem 0;
    padding: 2rem 0 0 0;
    border-top: 1px solid #d4d4d4;
    p {
      margin: 1rem 0 0 0;
      line-height: 1.5rem;
      font-size: 1rem;
      font-weight: 400;
      color: #2c3e50e0;
    }
    h2 {
      margin: 0 0 .5rem 0;
      font-weight: 600;
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        text-transform: uppercase;
        font-size: .75rem;
        margin: 0 0 0 .5rem;
      }
    }
    h3 {
      margin: 0 0 0 0;
      font-weight: 400;
      font-size: .825rem;
    }
  }
</style>
