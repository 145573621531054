<template>
  <section>
    <div>
      <div id="subscription-card" class="card">
        <div class="container">
          <div class="card-left">
            <h3>Toggle Autopay <span>{{ !noRenew === true ? 'Enabled' : 'Disabled' }}</span></h3>
            <p>If you turn Autopay off, your subscription will remain until the beginning of the next billing cycle.</p>
          </div>
          <div class="card-right">
            <h3 class="error" v-if="error">{{ error }}</h3>
            <h3 class="message" v-if="message">{{ message }}</h3>
          </div>
        </div>
        <div class="toggle">
          <h6 class="toggle-switch" @click="toggleRenew(!noRenew)">Toggle Autopay</h6>
          <h6 v-if="!noRenew">Your subscription will renew <span>{{ endPeriod }}</span></h6>
          <h6 v-if="noRenew">Your Subscription will end on <span>{{ endPeriod }}</span></h6>
        </div>
      </div>
      <div id="yearly-upgrade" class="card" v-if="showYearly">
        <div class="container">
          <h3>Upgrade to Yearly</h3>
          <button @click="showModal = !showModal">Upgrade Now</button>
        </div>
      </div>
    </div>
    <section class="yearly-modal" v-if="showModal" @click="showModal = false"></section>
    <div class="modal card" v-bind:class="{hover: showModal}">
      <div>
        <h3>Confirm</h3>
        <p>You will be upgraded to a yearly subscription and charged $599.99.</p>
      </div>
      <div>
        <button @click="upgradeYearly" v-bind:class="{loading: loading}" :disabled="loading">{{ loading ? '...' : 'Pay $599.99' }}</button>
        <span @click="showModal = !showModal">Cancel</span>
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';

export default {
  name: 'subscription',
  data() {
    return {
      error: null,
      message: null,
      loading: null,
      endPeriod: null,
      noRenew: null,
      showYearly: false,
      showModal: false
    }
  },
  created() {
    axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/subscription/status',
      data: {
        
      }
    }).then((response) => {
      if(typeof response.data.error !== 'undefined') {
        this.error = response.data.error
      } else {
        this.noRenew = response.data.noRenew;
        this.endPeriod = (new Date(response.data.endPeriod).getMonth() + 1) + '/' + new Date(response.data.endPeriod).getDate() + '/' + new Date(response.data.endPeriod).getFullYear();
        this.showYearly = response.data.plan.toLowerCase().indexOf('yearly') === -1;
      }
    }).catch((err) => {
      this.error = err;
    });
  },
  methods: {
    toggleRenew(status) {
      axios({
        method: 'post',
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/subscription/toggle',
        data: {
          renew: status
        }
      }).then((response) => {
        if(typeof response.data.error !== 'undefined') {
          this.error = response.data.error.raw.message;
        } else {
          this.noRenew = response.data.noRenew;
          this.endPeriod = (new Date(response.data.endPeriod).getMonth() + 1) + '/' + new Date(response.data.endPeriod).getDate() + '/' + new Date(response.data.endPeriod).getFullYear();
        }
      }).catch((err) => {
        this.error = err;
      });
    },
    upgradeYearly() {
      this.loading = true;
      axios({
        method: 'post',
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/subscription/upgrade',
        data: {}
      }).then((response) => {
        if(typeof response.data.error !== 'undefined') {
          this.error = response.data.error.raw.message;
          this.showYearly = false;
          this.showModal = false;
        } else {
          this.noRenew = response.data.noRenew;
          this.endPeriod = (new Date(response.data.endPeriod).getMonth() + 1) + '/' + new Date(response.data.endPeriod).getDate() + '/' + new Date(response.data.endPeriod).getFullYear();
          this.showYearly = response.data.plan.toLowerCase().indexOf('yearly') === -1;
          this.showModal = response.data.plan.toLowerCase().indexOf('yearly') === -1;
          this.message = 'Your subscription has been updated to Yearly';
          this.loading = false;
          localStorage.setItem('yearly-sub-1' , true);
        }
      }).catch((err) => {
        this.error = err;
      });
    }
  }
}
</script>

<style lang="less" scoped>
  .card {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow:  0 1px 3px rgba(31, 31, 34, 0.11);
    padding: 25px 25px 25px 25px;
  }
  #subscription-card {
    text-align: left;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .error , .message {
        font-size: 12px;
        margin: 0 0 15px 0;
        background: #cccccc5d;
        padding: 5px 15px 5px 15px;
        border-radius: 4px;
      }
    }
    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #EDEDED;
      padding: 15px 0 0 0;
      .toggle-switch {
        padding: 2px 0 2px 0;
        transition: .2s all;
        cursor: pointer;
      }
      .toggle-switch:hover {
        background: #79beff11;
        transition: .2s all;
      }
      h6 {
        margin: 0 0 0 0;
        font-size: 14px;
        font-weight: 500;
        color: #104475;
        span {
          font-weight: 900;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
      span {
        font-size: 10px;
        color: #104475;
        background: #79bfff45;
        padding: 5px 10px 5px 10px;
        margin: 0 0 0 10px;
        border-radius: 4px;
      }
    }
    p {
      width: 400px;
    }
  }
  button {
    font-weight: 700;
    font-size: 13px;
    color: #104475;
    background: #79bfff45;
    padding: 7px 20px 7px 20px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  #yearly-upgrade {
    margin: 25px 0 0 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0 0 0 0;
        text-align: left;
      }
    }
  }
  .yearly-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #104475;
    opacity: .2;
    top: 0;
    right: 0;
    overflow: hidden;
  }
  .modal {
    width: 300px;
    height: 200px;
    position: fixed;
    z-index: 999;
    top: 50%;
    right: 50%;
    transform: translate(50% , 0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s all;
    visibility: hidden;
    opacity: 0;
    h3 {
      margin: 0 0 0 0;
    }
    span {
      margin: 0 0 0 25px;
      font-size: 12px;
      cursor: pointer;
    }
    &.hover {
      transform: translate(50% , -50%);
      transition: .3s all;
      visibility: visible;
      opacity: 1;
    }
  }
  .loading {
    background-color: #cccccc5d !important;
    color: #636363 !important;
  }
  @media(max-width: 568px) {
    .card-left {
      p {
        width: auto !important;
      }
    }
    .toggle {
      flex-direction: column;
    }
    .modal {
      width: calc(100% - 90px);
    }
  }
</style>
