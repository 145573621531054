<template>
  <section class="video-filters">
    <form @submit.prevent="searchVideos">
      <div>
        <input @input="searchChange" v-model="search" type="text" placeholder="Search Videos by Title">
      </div>
      <div class="filters" id="filter-buttons">
        <button type="submit"><ion-icon name="search"></ion-icon></button>
        <button @click="clickManage" type="button"><ion-icon name="filter"></ion-icon>Filters <span v-if="taglist.length !== 0">| {{ taglist.length }}</span></button>
        <div class="filter-list" v-show="showMenu">
          <ul>
            <li v-for="tag in tags" v-bind:key="tag.canonical">
              <label>{{ tag.tag }}<input type="checkbox" v-model="tagname[tag.canonical]" :value="tag.canonical" v-on:change="filterEvent"></label>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'Filters',
  components: {
    
  },
  data() {
    return {
      showMenu: false,
      search: '',
      tagname: {},
      taglist: []
    }
  },
  computed: mapState({
    tags: state => state.videos.taglist
  }),
  methods: {
    searchChange(e) {
      if(this.search.length === 0) {
        this.$emit('filtered' , []);
      }
    },
    // Hide view more on search
    filterEvent(event) {
      if(event.target.checked) {
        this.selectTag();
        this.searchTags();
      } else {
        this.$emit('filtered' , []);
      }
    },
    selectTag() {
      Object.keys(this.tagname).forEach(element => {
        if(this.tagname[element] !== false && this.taglist.indexOf(element) === -1) {
          this.taglist.push(element);
        } else if(this.tagname[element] === false && this.taglist.indexOf(element) !== -1) {
          this.taglist.splice(this.taglist.indexOf(element) , 1);
        }
      });
    },
    searchVideos() {
      if(this.search.length > 0) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos',
          data: {
            search: this.search
          }
        }).then((response) => {
          this.$emit('filtered' , response.data.latest);
        }).catch((err) => {
          console.log(err);
        });
      } else {
        this.$emit('filtered' , []);
      }
    },
    searchTags() {
      this.search = '';
      axios({
        method: 'post',
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos',
        data: {
          tags: this.taglist
        }
      }).then((response) => {
        this.$emit('filtered' , response.data.latest);
      }).catch((err) => {
        console.log(err);
      });
    },
    clickManage() {
      if(!this.showMenu) {
        // Set a click listener for inside the dropdown
        const clickHandler = (event) => {
          // Check if inside #nav-dropdown
          if(document.querySelector('#filter-buttons') !== null && !document.querySelector('#filter-buttons').contains(event.target)) {
            this.showMenu = false;
            document.removeEventListener('click', clickHandler);
          }
        }
        document.addEventListener('click', clickHandler);
      }
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="less" scoped>
  form {
    display: flex;
    input[type="text"] {
      width: 300px;
      padding: 10px 15px 10px 15px;
      border: 1px solid #d4d4d4;
      font-weight: 600;
      font-size: 14px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      @media(max-width: 1100px) {
        width: 150px;
      }
      @media(max-width: 868px) {
        width: 300px;
      }
      @media(max-width: 600px) {
        width: 175px;
      }
    }
    button {
      // padding: 10px 25px 10px 25px;
      margin-left: 1rem;
      height: 39px;
      background: #d4d4d4;
      border-radius: 5px;
      border: 1px solid #d4d4d4;
      font-weight: 600;
      font-size: .825rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin: 0 0 0 0;
      }
    }
  }
  .filters {
    position: relative;
    display: flex;
    align-items: center;
    button:first-child {
      width: 38px;
      margin: 0;
      border-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background: #104475;
      border-color: #104475;
      color: #FFF;
      font-size: 1rem;
      ion-icon {
        width: 100%;
      }
    }
    button:nth-child(2) {
      padding: 0 .75rem;
      ion-icon {
        margin: 0 .5rem 0 0;
      }
    }
  }
  .filter-list {
    z-index: 99;
    position: absolute;
    right: 0;
    top: 55px;
    box-shadow: 0px 2px 6px rgba(94, 94, 94, 0.28);
    padding: 15px 15px 15px 15px;
    background: #FFFFFF;
    border-radius: 4px;
    width: 200px;
    max-height: 400px;
    overflow-y: scroll;
    ul {
      padding: 0 0 0 0;
      margin: 0 0 0 0;
      list-style: none;
      li {
        display: flex;
        justify-content: space-between;
        label {
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 5px 0 5px 0;
        }
        input {
          // width: 100%;
        }
      }
    }
  }
</style>
