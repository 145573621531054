<template>
  <section>
    <form v-on:submit.prevent="submitForm" @click="validateForm" id="signup-form">
      <p>Create your OTCmethod Account</p>
      <p class="main-error" v-if="verifyError || submitError">
        <!-- <svg width="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="7" fill="#79bfff45"/>
          <path d="M18 21.5V6" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18 30L18 29" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> -->
        {{ submitError || verifyError }}
      </p>
      <div class="payment-selection">
        <div v-if="disabled !== 'monthly'" class="price" v-bind:class="{ active: selected }" @click="changePlan('monthly' , monthlyPlan)">
          <h3>Monthly</h3>
          <h5>{{'$' + Number(monthly).toFixed(2)}}</h5>
        </div>
        <div v-if="disabled !== 'yearly'" class="price" v-bind:class="{ active: !selected }" @click="changePlan('yearly' , yearlyPlan)">
          <h3>Yearly</h3>
          <h5>{{'$' + Number(yearly).toFixed(2)}}<span>{{planList !== null && 'yearly' in planList ? 'Save 20%' : 'Save 23%'}}</span></h5>
        </div>
      </div>
      <div class="form-row">
        <label>Email</label>
        <input v-model="email" type="text" required>
        <span class="error">{{ errors.email }}</span>
      </div>
      <div class="form-row">
        <label>Card Info</label>
        <stripe form-id="signup-form" v-on:stripeToken="setToken" v-on:stripeError="loading = false" ref="stripeForm" />
      </div>
      <div class="form-row promo-row" v-if="showPromo && !promoApplied">
        <label>Promo Code</label>
        <input v-model="promo" type="text" placeholder="Enter Promo Code">
        <span class="button" @click="checkPromo">Apply</span>
        <span class="error">{{ promoError }}</span>
      </div>
      <div class="form-row promo-row" v-if="promoApplied">
        <label>Promo Code</label>
        <span>Discount Applied</span>
      </div>
      <!-- <div class="form-row">
        <span>Sales Tax</span>
        <span>Calculating</span>
      </div>  -->
      <button v-bind:class="{loading: loading}" :disabled="loading" type="submit">{{ loading ? '...' : 'Submit $' + Number(price).toFixed(2) + ' Payment' }}</button>
    </form>
    <h6 class="disclaimer">By signing up you agree to OTCmethod's <router-link :to="{name: 'Terms'}">TOS</router-link>, <router-link :to="{name: 'Disclaimer'}">Disclaimer</router-link> and <router-link :to="{name: 'Privacy'}">Privacy Policy</router-link>. Payments are protected by <a target="_blank" href="https://stripe.com">Stripe.</a></h6>
  </section>
</template>


<script>
import axios from 'axios';
import stripe from '@/components/payment/stripe.vue';

export default {
  name: 'signup',
  components: {
    stripe
  },
  props: ['verifyError'],
  data() {
    return {
      plan: 'price_1HqC5BDCCDc7mzl1PJS7Ll6F',
      selected: true,
      disabled: null,
      price: '64.99',
      email: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user'))['email'] : null,
      promo: '',
      token: null,
      loading: false,
      tax: ' + Tax',
      errors: {},
      promoError: null,
      submitError: null,
      showPromo: false,
      promoApplied: false,
      yearly: '599.99',
      yearlyPlan: 'price_1HqC5VDCCDc7mzl1iGH1yH3w',
      monthly: '64.99',
      monthlyPlan: 'price_1HqC5BDCCDc7mzl1PJS7Ll6F',
      planList: JSON.parse(localStorage.getItem('plans')) || null
    }
  },
  created() {
    try {
      window.rewardful('convert', { email: this.email });
    } catch(e) {
      console.log('Rewardful Failed')
    }
  },
  beforeMount() {
    try {
      const planList = JSON.parse(localStorage.getItem('plans'));
      const promoAccess =JSON.parse(localStorage.getItem('promo-access'));
      if(promoAccess) {
        this.showPromo = true;
      }
      if('monthly' in planList) {
        this.plan = planList.monthly.plan;
        this.price = planList.monthly.price;
        this.monthly = planList.monthly.price;
        this.monthlyPlan = planList.monthly.plan;
        this.yearly = planList.yearly.price;
        this.yearlyPlan = planList.yearly.plan;
      }
    } catch {
      console.log('Error selecting plans');
    }
  },
  methods: {
    checkPromo() {
      if(this.promo.length && !this.promoApplied) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/promo',
          data: {
            promo: this.promo
          }
        }).then((response) => {
          if(typeof response.data.error !== 'undefined') {
            this.promoError = response.data.error;
          } else {
            if(response.data.promo !== false) {
              this.promoError = null;
              this.promo = response.data.promo;
              this.price = this[response.data.promoPlan]
              this.price = this.price - (response.data.discount * (this.price / 100));
              this[response.data.promoPlan] = this.price
              // this.yearly = response.data.discount * (this.yearly / 100);
              this.selected = response.data.promoPlan === 'monthly' ? true : false;
              this.disabled = response.data.promoPlan === 'monthly' ? 'yearly' : 'monthly';
              this.promoApplied = true;
            } else {
              this.promoError = 'Invalid Promo Code';
            }
          }
        }).catch((err) => {
          this.promoError = err;
        });
      }
    },
    changePlan(type , plan) {
      this.plan = plan;
      if(type === 'monthly' && !this.selected) {
        this.selected = !this.selected;
        this.price = this.monthly;
      } else if(type === 'yearly' && this.selected) {
        this.selected = !this.selected;
        this.price = this.yearly;
      }
    },
    submitForm(e) {
      this.loading = true;
      this.validateForm();
      if(this.email !== null && Object.keys(this.errors)) {
        this.$refs.stripeForm.stripeTokenHandler();
      } else {
        this.loading = false;
      }
    },
    validateForm(e) {
      if(this.email !== null) {
        this.errors = {};

        if(this.email !== '') {
          if(!this.validateEmail(this.email)) {
            this.errors.email = 'Invalid Email'
          }
        } else {
          this.errors.email = 'Email is Required';
        }
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setToken(token) {
      this.token = token;
      this.submitPayment(token);
    },
    submitPayment(token) {
      if(token !== '' && token !== null) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/charge',
          data: {
            email: this.email,
            planID: this.plan,
            token: token,
            promo: this.promoApplied && this.promo.length ? this.promo : null
          }
        }).then((response) => {
          if(typeof response.data.error !== 'undefined') {
            this.submitError = response.data.error;
            this.loading = false;
          } else {
            window.location.href = '/';
          }
        }).catch((err) => {
          this.submitError = err;
          this.loading = false;
        });
      } else {
        this.errors.token = 'Card Info Required';
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .main-error {
    font-size: 12px;
    margin: 0 0 15px 0;
    background: #cccccc5d;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    svg {
      width: 15px;
      margin: 0 5px 0 0;
    }
  }
  form {
    box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.15);
    padding: 30px 30px 30px 30px;
    border-radius: 4px;
    width: 300px;
    @media(max-width: 868px) {
      width: 100%;
      box-sizing: border-box;
    }
    .payment-selection {
      border-radius: 4px;
      display: flex;
      .price {
        margin: 0 0 0 0;
        padding: 10px 10px 10px 10px;
        background: #79beff18;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 0 0;
        cursor: pointer;
        h3 {
          margin: 0 0 0 0;
          font-weight: 800;
          font-size: 16px;
        }
        h5 {
          font-weight: 500;
          font-size: 16px;
          margin: 0 0 0 0;
        }
        span {
          font-size: 9px;
          margin: 0 0 0 2px;
        }
      }
      .active {
        background: #FFFFFF;
        box-shadow:  0 1px 5px rgba(31, 31, 34, 0.21);
        border-radius: 4px;
        border: 1px solid #104475ab;
      }
    }
    .promo-row {
      position: relative;
      .button {
        position: absolute;
        right: 1rem;
        top: 50%;
        cursor: pointer;
        transform: translate(0 , -15%);
        text-align: center;
        padding: .25rem .7rem;
        border-radius: 4px;
        border: none;
        background-color: #104475;
        color: #FFFFFF;
        font-size: .75rem;
        font-weight: 600;
      }
    }
    .form-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 15px 0 15px 0;
      label {
        font-size: 14px;
        font-weight: 700;
      }
      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #cccccc5d;
        padding: 15px 10px 15px 10px;
        font-size: 16px;
        box-sizing:border-box;
      }
    }
    button[type="submit"] {
      width: 100%;
      text-align: center;
      padding: 15px 0 15px 0;
      border-radius: 4px;
      border: none;
      background-color: #104475;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      margin: 30px 0 0 0;
      cursor: pointer;
    }
    .loading {
      background-color: #cccccc5d !important;
      color: #636363 !important;
    }
  }
  .disclaimer {
    width: 250px;
    margin: 10px auto 0 auto;
    font-weight: 400;
    a {
      color: inherit;
    }
  }
  
</style>

