import axios from 'axios'

export default {
  getArticles() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/articles',
      data: {
        
      }
    })
  },
  getFreeArticles() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/articles/free',
      data: {
        
      }
    })
  },
}