<template>
  <section id="editor-page">
    <div class="toolbar" v-if="editor">
      <button 
        @click="editor.chain().focus().toggleBold().run()" 
        :disabled="!editor.can().chain().focus().toggleBold().run()" 
        :class="{ 'is-active': editor.isActive('bold') }"
        class="bold"
      >B</button>
      <button 
        @click="editor.chain().focus().toggleItalic().run()" 
        :disabled="!editor.can().chain().focus().toggleItalic().run()" 
        :class="{ 'is-active': editor.isActive('italic') }"
        class="italic"
      >I</button>
      <button 
        @click="editor.chain().focus().toggleUnderline().run()" 
        :disabled="!editor.can().chain().focus().toggleUnderline().run()" 
        :class="{ 'is-active': editor.isActive('underline') }"
        class="underline"
      >U</button>
      <button 
        @click="editor.chain().focus().toggleStrike().run()" 
        :disabled="!editor.can().chain().focus().toggleStrike().run()" 
        :class="{ 'is-active': editor.isActive('strike') }"
        class="strikethrough"
      >S</button>
      <button
        @click="editor.chain().focus().toggleBulletList().run()" 
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <ion-icon name="list-outline"></ion-icon>
      </button>
      <button
        @click="setLink" 
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <ion-icon name="link-outline"></ion-icon>
      </button>
      <!-- <button 
        @click="editor.chain().focus().setHorizontalRule().run()">
        <ion-icon name="remove-outline"></ion-icon>
      </button> -->
      <button 
        @click="editor.chain().focus().toggleBlockquote().run()" 
        :class="{ 'is-active': editor.isActive('blockquote') }"
      >
        <ion-icon name="information-outline"></ion-icon>
      </button>
      <button @click="addImage">
        <ion-icon name="images-outline"></ion-icon>
      </button>
      <button 
        @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
      >
        <ion-icon name="grid-outline"></ion-icon>
      </button>
      <!-- <button>
        <input
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color"
        >
      </button> -->
      <button 
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" 
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        class="heading"
      >Heading</button>
      <button 
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" 
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        class="subheading"
      >Subheading</button>
      <span v-if="!saved" class="unsaved">Unsaved Changes</span>
    </div>
    <editor-content class="editor" spellcheck="false" :editor="editor" />
  </section>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { Mark , markInputRule , markPasteRule , mergeAttributes } from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Iframe from './extensions/iframe'
import axios from 'axios';

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },
})

const CustomLink = Link.extend({
  renderHTML({ HTMLAttributes }) {
    if(HTMLAttributes.href.includes('https://www.otcmethod.com/videos/watch/')) {
      return ['a', {
        class: 'video-link',
        'data-href': HTMLAttributes.href
      } , 0]
    }

    return ['a', {
      ...HTMLAttributes,
      target: '_blank',
      rel: 'noopener noreferrer',
    }, 0]
  },
});

const symbolRegex = /(?:^|\s)((?:\$)((?:[^\$!\n!\s]{4,8}))?[\s\n])$/
const pasteRegex = /(?:^|\s)((?:\$)((?:[^\$!\n!\s]{4,8}))?[\s\n])$/g
// TODO: Remove if spaces added in between
const Symbols = Mark.create({
  name: 'symbolExtension',
  exitable: true,
  spanning: false,
  inclusive: false,
  excludes: '_',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'stock-symbol'
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addInputRules() {
    return [
      markInputRule({
        find: symbolRegex,
        type: this.type
      })
    ]
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type
      })
    ]
  }
})

export default {
  name: 'editor',
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    savedBody: {
      type: Boolean,
      default: true
    },
    symbolSelected: {
      type: String,
      default: ''
    },
    savedVideo: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editor: null,
      saved: true
    }
  },

  watch: {
    symbolSelected(value) {
      if(value) {
        this.editor.chain().focus().insertContent(' <span class="stock-symbol">' + this.symbolSelected + '</span> ').run()
      }
    },
    savedBody(value) {
      this.saved = value
      if(value) {
        window.removeEventListener('beforeunload', this.preventNavigation)
      }
    },
    savedVideo(value) {
      if(value) {
        this.editor.chain().focus().setIframe({ src: `https://player.vimeo.com/video/${value}?h=df809bf906&title=0&byline=0&portrait=0&app_id=122963` }).run()
      }
    },
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    const loggedOutContent = '\
      <p>Everything below is editable!</p>\
      <p>With the OTCMethod Journal, you can keep track of all your trading notes and ideas. Easily tag by stock symbol to make sorting your journal entries super easy!</p>\
      <h2>Nightly Video Notes</h2>\
      <p>Pat goes over the OTC runners of the day. <span class="stock-symbol">BIEI</span> <span class="stock-symbol">AMIH</span> <span class="stock-symbol">GROM</span></p>\
      <p>You can create notes of the nightly <b>OTCMethod</b> videos to watch and takes notes at the same time!</p>\
      <p>See what OTCMethod has to offer 👇</p>\
      <p><div class="iframe-wrapper" contenteditable="false"><iframe src="https://player.vimeo.com/video/341491584?h=df809bf906&amp;title=0&amp;byline=0&amp;portrait=0&amp;app_id=122963" frameborder="0" allowfullscreen="true"></iframe></div></p>\
    ';
    this.editor = new Editor({
      content: !this.$auth.isAuthenticated() ? loggedOutContent : this.value,
      editorProps: {
        attributes: {
          class: 'prose',
        },
      },
      extensions: [
        StarterKit,
        Underline,
        this.$auth.isAuthenticated() ? CustomLink : Link,
        TextStyle,
        Symbols,
        Color,
        Iframe,
        Image,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        CustomTableCell,
        Placeholder.configure({
          placeholder: 'Write something …',
        }),
      ],
      onTransaction({ editor, transaction }) {
        if(transaction.steps && transaction.steps.length > 0) {
          const node = transaction.steps[0].slice.content.content;
          if(node && node.length > 0) {
            const path = node[0].text;
            if(path) {
              const nodeID = path.split('/')[path.split('/').length - 2]
              if(nodeID) {
                axios.post((process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos/metadata' , {nodeId: nodeID}).then(req => {
                  if(req.data) {
                    const videoID = req.data.uri
                    // Replace the iframe src with the video ID
                    editor.chain().focus().setIframe({ src: `https://player.vimeo.com/video/${videoID}?h=df809bf906&title=0&byline=0&portrait=0&app_id=122963` }).run()
                  }
                })
              }
            }
          }
        }
      },
      onUpdate: ({ editor }) => {
        // HTML
        const content = this.editor.getHTML()
        // Send updates to parent and save state
        this.$emit('input', content)
        if(this.saved) {
          this.saved = false;
          if(!this.$router.currentRoute.query.saved) {
            this.$router.replace({ query: {
              saved: false
            }})
          }
          // Prevent navigation without saved
          window.addEventListener('beforeunload', this.preventNavigation)
        } 
        // else if(!this.saved && content.replace('<p></p>', '').length === 0) {
        //   this.saved = true;
        //   this.$router.replace({ query: {
        //     saved: undefined
        //   }})
        //   window.removeEventListener('onbeforeunload', this.preventNavigation)
        // }
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {
    preventNavigation(e) {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) e.returnValue = 'Sure?';
      return 'Sure?';
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
  },
}
</script>

<style lang="less">
  #editor-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: hidden;
    .toolbar {
      background: linear-gradient(324deg, #f3f3f3, #ffffff);
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.099);
      display: flex;
      @media(max-width: 768px) {
        flex-wrap: wrap;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border-right: 1px solid #c2c2c2;
        border-top: none;
        border-bottom: none;
        border-left: none;
        padding: .25rem .75rem;
        font-size: 1.25rem;
        box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.18);
        transition: .2s all;
        cursor: pointer;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &.is-active {
          transition: .2s all;
          box-shadow: inset 0px -14px 29px -12px rgb(40 206 192);
          background: #ededed;
          color: #434343;
        }
        // &:last-child {
        //   border-right: none;
        // }
      }
      .bold , .italic, .underline, .strikethrough {
        font-weight: 600;
        width: 2.5rem;
        color: #5f5f5f;
        @media(max-width: 768px) {
          width: 1.5rem;
        }
      }
      .italic {
        font-style: italic;
        font-family: initial;
      }
      .underline {
        text-decoration: underline;
      }
      .strikethrough {
        text-decoration: line-through;
      }
      .unsaved {
        margin-left: auto;
        font-size: .825rem;
        font-weight: 400;
        margin-right: .5rem;
        color: black;
        align-self: center;
      }

      .heading {
        font-weight: 700;
        font-size: .825rem;
        color: #5f5f5f;
      }
      .subheading {
        font-weight: 600;
        font-size: .75rem;
        color: #5f5f5f;
      }
      .quote {
        font-weight: 600;
        font-size: .875rem;
        color: #5f5f5f;
      }
      ion-icon {
        color: #5f5f5f;
      }

    }
    .editor {
      // width: 75%;
      margin: .5rem 0 0 0;
      text-align: left;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      overflow-y: scroll;
      word-break: break-word;
    }
  }
  .ProseMirror {
    .video-link {
      width: 1px;
      height: 1px;
      display: block;
      font-size: 0;
      visibility: hidden;
    }
    .stock-symbol {
      text-transform: uppercase;
      background: #e6e6e6;
      padding: 0.25rem .5rem;
      color: #5f5f5f;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.18);
      text-align: center;
      border-radius: 3px;
      font-weight: 400;
      margin: 0 .25rem 0 0;
      border: 1px solid #c2c2c2;
      padding: 0.25rem;
      font-size: .825rem;
      // &::after {
      //   content: "\f425";
      //   font-family: "Ionicons";
      // }
    }
    iframe {
      height: 25rem;
      width: 75%;
    }
    h1 , h2 , h3 , h4 , h5 , h6 {
      margin: .5rem 0;
      color: #000;
    }
    h1 {
      font-size: 2rem;
      font-weight: 700;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 700;
    }
    blockquote {
      padding-left: 1rem;
      border-left: 3px solid rgba(#0D0D0D, 0.1);
    }
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }

      p {
        margin: 0;
      }
    }
  }
  .ProseMirror p {
    line-height: 1.5rem;
    font-weight: 400;
    color: #393939;
    margin: .5rem 0;
  }
  .ProseMirror p.is-editor-empty:first-child {
    color: #8b8b8b;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
    height: 0;
  }
</style>