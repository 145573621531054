import axios from 'axios';

export default {
  getLatestVideos(max , more , playlists , tags) {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/videos',
      data: {
        maxResults: max,
        more: more,
        playlist: playlists,
        tags: tags
      }
    });
  },
  getPlaylists() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/playlists',
      data: {

      }
    });
  },
  getTags() {
    return axios({
      method: 'post',
      url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/tags',
      data: {

      }
    });
  }
}