import auth0 from 'auth0-js'
import Vue from 'vue'
import axios from 'axios'

//   audience: 'https://otcmethod.com/api', - For nodejs authorizations
let webAuth = new auth0.WebAuth({
  clientID: 'oJ-dGpRBsMB4ssnBGk2MXbEQ5RnyHUWf',
  domain: 'otcmethod.auth0.com',
  responseType: 'token id_token',
  audience: 'https://www.otcmethod.com/api',
  redirectUri: window.location.origin + '/callback',
  scope: 'openid profile email read:profile'
});

let auth = new Vue({
  computed: {
    token: {
      get: () => {
        return localStorage.getItem('id_token');
      },
      set: (id_token) => {
        localStorage.setItem('id_token' , id_token);
      }
    },
    accessToken: {
      get: () => {
        return localStorage.getItem('accessToken');
      },
      set: (accessToken) => {
        localStorage.setItem('accessToken' , accessToken);
      }
    },
    expiresAt: {
      get: () => {
        return localStorage.getItem('expires_at');
      },
      set: (expiresIn) => {
        let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

        localStorage.setItem('expires_at' , expiresAt);
      }
    },
    user: {
      get: () => {
        return JSON.parse(localStorage.getItem('user'));
      },
      set: (user) => {
        localStorage.setItem('user' , JSON.stringify(user));
      }
    }
  },
  methods: {
    login() {
      webAuth.authorize();
    },
    logout() {
      return new Promise(() => {
        localStorage.removeItem('id_token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user');

        webAuth.logout({
          returnTo: window.location.origin,
          clientID: 'oJ-dGpRBsMB4ssnBGk2MXbEQ5RnyHUWf'
        });
      });
    },
    isAuthenticated() {
      return new Date().getTime() < this.expiresAt;
    },
    handleAuthentication() {
      return new Promise((resolve , reject) => {
        webAuth.parseHash((err , authResult) => {
          if(authResult && authResult.accessToken && authResult.idToken) {
            this.expiresAt = authResult.expiresIn;
            this.accessToken = authResult.accessToken;
            this.token = authResult.idToken;
            this.user = authResult.idTokenPayload;

            resolve();
          } else if(err) {
            this.logout();

            reject(err);
          }
        });
      });
    }
  }
});

axios.interceptors.request.use((config) => {
  let token = localStorage.getItem('accessToken');
  let custID = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['https://www.otcmethod.com/stripe_customer_id'] : null;

  if(token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  if(custID && typeof config.data !== 'undefined') {
    config.data['custID'] = custID;
  }

  return config;
}, (err) => {
  return Promise.reject(err);
});

axios.interceptors.response.use((config) => {
  // Set user plan here
  if(config.data && config.data.plans) {
    localStorage.setItem('plans' , JSON.stringify(config.data.plans))
  }
  if(window.location.pathname !== '/payment') {
    if(config.data && config.data.redirect === true) {
      window.location.href = '/payment';
    }
  }

  return config;
} , (err) => {
  return Promise.reject(err);
});

export default {
  install: (Vue) => {
    Vue.prototype.$auth = auth;
  }
}