<template>
  <div class="home" v-if="$auth.isAuthenticated()">
    <dashboard />
    <promo storageKey="hide-promo-3" :showArgs="showPromo">
      <h5>Did you know? You can <span>Save 20% or more with a yearly subscription. Upgrade with 2 clicks.</span></h5>
      <div class="upgrade-promo">
        <router-link to="/account">
          <button>Upgrade Settings</button>
        </router-link>
      </div>
    </promo>
    <!-- <promo storageKey="hide-promo-filingre">
      <h5>Limited Time: Save $100 on a new yearly plan to Filing:re using group code OTCMETHOD at checkout or email support@otcmethod.com</h5>
      <div class="upgrade-promo">
        <a href="https://www.filingre.com/" target="_blank">
          <button>Open Filing:re</button>
        </a>
      </div>
    </promo> -->
    <videos :video-data="videos" :hide-more="true" :limit="3" :section-title="'Latest Videos'" class="dashboard-videos" />
    <videos :video-data="videos.slice(sliceVal)" :ajax-more="ajaxMore" ajax-override="/videos" :limit="8" class="dashboard-list" view-override="All Videos" />
    <section class="home-discord">
      <!-- <discord /> -->
    </section>
  </div>
</template>

<script>
import { mapState , mapActions } from 'vuex'
import dashboard from '@/components/home/default/dashboard.vue'
import videos from '@/components/home/default/videos.vue'
import promo from '@/components/promo.vue'
import axios from 'axios';

export default {
  name: "Dashboard",
  data() {
    return {
      ajaxMore: false,
      sliceVal: window.innerWidth < 868 ? 0 : 3,
      showPromo: false
    }
  },
  components: {
    dashboard,
    videos,
    promo
  },
  computed: mapState({
    videos: state => state.videos.videos.latest
  }),
  created() {
    let yearly = JSON.parse(localStorage.getItem('yearly-sub-1'));
    let hide = JSON.parse(localStorage.getItem('hide-promo-3'));
    if(hide === null && yearly === null) {
      axios({
        method: 'post',
        url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/subscription/status',
        data: {
          
        }
      }).then((response) => {
        if(typeof response.data.plan !== 'undefined') {
          if(response.data.plan.toLowerCase().indexOf('yearly') === -1 && response.data.plan.toLowerCase().indexOf('free') === -1) {
            this.showPromo = true;
            localStorage.setItem('yearly-sub-1' , false);
          } else {
            localStorage.setItem('yearly-sub-1' , true);
          }
        }
      });
    } else if(yearly === false && hide !== true) {
      this.showPromo = true;
    }
  }
};
</script>

<style lang="less">
  .home {
    .dashboard-videos {
      @media(max-width: 868px) {
        padding: 0;
      }
      h3 {
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 2.5rem;
        @media(max-width: 868px) {
          margin-bottom: 0;
        }
      }
      .video-card:first-child , .video-card:nth-child(2) , .video-card:nth-child(3) {
        max-width: calc(((1194px - 5rem) / 3));
        margin-right: 2.5rem;
        @media(max-width: 1300px) {
          max-width: calc(((992px - 4rem) / 3)) !important;
          margin-right: 0;
        }
        @media(max-width: 1092px) {
          max-width: calc(((768px - 5rem) / 3)) !important;
        }
        @media(max-width: 868px) {
          display: none;
        }
      }
      .video-card:nth-child(3) {
        margin-right: 0;
      }
      .video-card {
        margin: 0 0 0 0;
      }
    }
    .upgrade-promo {
      @media(min-width: 1100px) {
        margin-left: auto;
      }
      button {
        margin: 0 0 0 0;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        font-weight: 600;
        padding: 5px 10px;
        &:first-child {
          background: #FFFFFF;
          color: #000000;
        }
      }
    }
    .dashboard-list {
      .video-card {
        @media(max-width: 1092px) {
          margin: 0 0 43px 0 !important;
        }
      }
      .video-card:nth-child(4n) {
        @media(max-width: 1300px) {
          margin: 0 0 2rem 0 !important;
        }
      }
    }
    .videos {
      .video-card {
        max-width: calc(((1200px - 129px) / 4));
        @media(max-width: 1300px) {
          max-width: calc(((992px - 129px) / 4)) !important;
        }
        @media(max-width: 1092px) {
          max-width: calc(((768px - 5rem) / 3)) !important;
        }
        @media(max-width: 868px) {
          max-width: calc(((576px - 2rem) / 2)) !important;
        }
        @media(max-width: 676px) {
          max-width: 100% !important;
        }
      }
      button {
        margin-bottom: 1rem;
      }
    }
  }  
</style>