<template>
  <div class="main-wrapper">
    <div class="journal" v-show="$auth.isAuthenticated()">
      <!-- <div class="wrapper" v-show="$auth.isAuthenticated()"> -->
      <div class="stats">
        <div class="table-card">
          <h5>Current Trading Streak</h5>
          <h3>12 Green Days</h3>
          <div class="grid-chart">
            <header>
              <span>December</span>
              <span>January</span>
              <span>February</span>
              <span>March</span>
              <span>April</span>
              <span>May</span>
              <span>June</span>
              <span>July</span>
              <span>August</span>
              <span>September</span>
              <span>October</span>
            </header>
            <span 
              v-for="(item , index) in Array(260).fill(1)" 
              :key="index" 
              :class="{
                green: index % 4 === 0 || index % 5 === 0,
                red: index % 3 === 0 || index % 7 === 0,
                day: true
              }"
            ></span>
          </div>
        </div>
        <div class="table-row">
          <div class="table-card"></div>
          <div class="table-card"></div>
        </div>
        <div class="table-row">
          <div class="table-card"></div>
          <div class="table-card"></div>
          <div class="table-card"></div>
        </div>
        <!-- Add bar chart of monthly performance -->
        <!-- VS OTCQB and OTCQX returns (index returns etc.) -->
        <span class="beta">BETA</span>
      </div>
      <aside>
        <header>
          <h4>Trade History</h4>
          <!-- <div class="small-stats">
            <div>
              <h6>Cash</h6>
              <span>1,234.33</span>
            </div>
          </div> -->
          <button class="add-button">Add Trade <ion-icon name="add-circle-outline"></ion-icon></button>
          <button>Import Trades <ion-icon name="cloud-upload-outline"></ion-icon></button>
        </header>
        <tradelist :trades="trades" />
      </aside>
    </div>
    <!-- <div v-show="!$auth.isAuthenticated()">
      <landing />
    </div> -->
  </div>
</template>

<script>
import landing from '@/components/journal/landing.vue'
import tradelist from '@/components/journal/tradelist'
import { mapState } from 'vuex'

export default {
  name: 'Trades',
  data() {
    return {
      hideHeaders: false,
    }
  },
  components: {
    tradelist,
    landing
  },
  computed: mapState({
    notes: state => state.journal.notes,
    trades: state => state.journal.trades
  })
}
</script>

<style lang="less" scoped>
  .journal {
    min-height: calc(100vh - 24px - 62px - 1.5rem);
    display: flex;
    margin: 0 -2rem;
    overflow: hidden;
    aside {
      display: flex;
      flex-direction: column;
      margin: 0 1rem 0 0;
      overflow-y: scroll;
      flex: 0 0 55%;
      header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 0 0;
        margin: 0 0 1rem 0;
        h4 {
          margin: 0 0 0 0;
          font-size: 1.5rem;
          color: #000;
        }
        .add-button {
          margin: 0 .75rem 0 auto;
        }
        .small-stats {
          display: flex;
          align-items: flex-start;
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            span {
              font-size: .75rem;
              width: 100px;
              font-weight: 500;
              display: flex;
              transition: 0.2s all;
              padding: 0.35rem 0.5rem;
              border-radius: 5px;
              background: #f3f3f3;
              border: 1px solid #c2c2c2;
              box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.185), 0px 2px 5px rgba(0, 0, 0, 0.073);
            }
          }
          h6 {
            margin: 0 0 .5rem 0;
            font-size: .825rem;
            font-weight: 500;
            color: #000;
          }
        }
      }
    }
    .stats {
      display: flex;
      flex-direction: column;
      margin: 0 1rem 0 0;
      width: 100%;
      position: relative;
      border-right: 1px solid #c2c2c2;
      box-shadow: inset -10px 0 19px -11px rgba(0, 0, 0, 0.16);
      background: #fafafa;
      padding: 1rem;
      .table-row {
        display: flex;
        align-items: flex-start;
        .table-card {
          width: calc(50% - 1rem);
          height: 150px;
          &:nth-last-child(2) {
            margin-right: 1rem;
            margin-left: 1rem;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .table-card {
        margin: 0 0 1rem 0;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
        padding: 1rem;
        border: 1px solid #d4d4d4;
        height: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .grid-chart {
          display: flex;
          flex-wrap: wrap;
          margin: .5rem 0 0 0;
          header {
            display: flex;
            width: 100%;
            margin: 0 0 .25rem 0;
            justify-content: space-between;
            span {
              font-size: .75rem;
              font-weight: 500;
              color: #7b7b7b;
            }
          }
          .day {
            width: calc((100% / 52) - 3px);
            padding-bottom: calc((100% / 52) - 3px);
            // background-color: #d4d4d4;
            // border: 1px solid #d4d4d4;
            border: 1px solid #19b096;
              background: linear-gradient(45deg, #2cc498, #1bbb98);
              box-shadow: inset 0px -2px 4px 1px rgb(48 169 165);
            margin: 0 1px 1px 0;
            &.green {
              border: 1px solid #19b096;
              background: linear-gradient(45deg, #2cc498, #1bbb98);
              box-shadow: inset 0px -2px 4px 1px rgb(48 169 165);
            }
            &.red {
              border: 1px solid #f443368a;
              background: linear-gradient(45deg, #f44336a0, #f4433687);
              box-shadow: inset 0px -2px 4px 1px rgba(169, 48, 48, 0.545);
            }
          }
        }
        h5 {
          margin: 0 0 0 0;
          font-weight: 400;
          color: #7b7b7b;
        }
        h3 {
          margin: .25rem 0 0 0;
          font-weight: 700;
        }
      }
    }
    button {
      font-weight: 600;
      font-size: 12px;
      color: #5f5f5f;
      padding: 0.35rem 0.5rem;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      transition: 0.2s all;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.185), 0px 2px 5px rgba(0, 0, 0, 0.073);
      &:hover {
        transition: .2s all;
        background: #f3f3f3;
        border: 1px solid #c3c3c3;
      }
      &:active {
        transition: .2s all;
        box-shadow: none;
        background: #f0f0f0;
        border: 1px solid #a4a4a4;
        color: #4d4d4d;
      }
      ion-icon {
        margin-left: .25rem;
      }
      &.button-none {
        background: none;
        border: 1px solid #FFF;
        box-shadow: none;
        transition: .2s all;
        &:hover {
          transition: .2s all;
          border: 1px solid #e8e8e8;
          box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.185);
        }
        &:active {
          transition: .2s all;
          box-shadow: none;
          background: #f0f0f0;
          border: 1px solid #a4a4a4;
          color: #4d4d4d;
        }
      }
    }
  } 
</style>
