var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"video"},[(_vm.metadata)?_c('div',{staticClass:"wrapper title"},[_c('h1',[_vm._v(_vm._s(_vm.metadata.title))])]):_vm._e(),_c('div',{staticClass:"wrapper"},[(_vm.metadata)?_c('aside',[_c('div',{staticClass:"row"},[_c('div',[_c('h6',[_vm._v("Duration")]),_c('h5',[_vm._v(_vm._s(_vm.filterDuration(_vm.metadata.duration)))])]),_c('div',[_c('h6',[_vm._v("Upload Date")]),_c('h5',[_vm._v(_vm._s(_vm.filterDate(_vm.metadata.timestamp)))])])]),(_vm.nodeId)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$auth.isAuthenticated()),expression:"$auth.isAuthenticated()"}],staticClass:"create-note"},[_c('router-link',{attrs:{"to":{
            path: '/journal?video=' + _vm.nodeId,
            params: { videoTitle: _vm.metadata.title },
            query: { videoTitle: _vm.metadata.title }
          }}},[_c('button',[_c('ion-icon',{attrs:{"name":"create"}}),_vm._v("Create Note")],1)])],1):_vm._e(),(_vm.chapters && _vm.chapters.length > 0)?_c('div',{staticClass:"chapters"},[_c('h3',[_vm._v("Chapters")]),_c('div',{staticClass:"chapter-table"},_vm._l((_vm.chapters),function(chapter,index){return _c('div',{key:index,staticClass:"chapter-row",on:{"click":function($event){return _vm.selectTime(chapter.startTime)}}},[_c('ion-icon',{attrs:{"name":"book"}}),_c('span',[_vm._v(_vm._s(chapter.title))]),_c('span',[_vm._v(_vm._s(Math.trunc(chapter.startTime / 60))+":"+_vm._s(chapter.startTime % 60))])],1)}),0)]):_vm._e(),(!_vm.$auth.isAuthenticated())?_c('div',[_c('div',{staticClass:"logged-out-player"},[_c('div',{staticClass:"row"},[_c('h3',[_vm._v("Log in to watch this video")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._v("Log In")])]),_c('div',{staticClass:"row"},[_c('h3',[_vm._v("Not a member? Join today!")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._v("Join Now")])])]),_c('span',[_vm._v("Access Private Chat and Video Library For As Low as $49.99/month")])]):_vm._e()]):_vm._e(),(_vm.$auth.isAuthenticated() && _vm.nodeId)?_c('vue-vimeo-player',{ref:"player",attrs:{"options":{
          controls: true,
          title: false,
          byline: false,
          portrait: false
        },"video-url":'https://player.vimeo.com/video/' + _vm.nodeId},on:{"ready":_vm.onReady}}):_vm._e(),(_vm.metadata && !_vm.$auth.isAuthenticated())?_c('img',{staticClass:"logged-out-image",attrs:{"src":_vm.metadata.thumbnail}}):_vm._e()],1)]),(!_vm.$auth.isAuthenticated() && _vm.metadata)?_c('div',{staticClass:"wrapper",staticStyle:{"margin-top":"2rem"}},[(_vm.metadata.description)?_c('div',{staticClass:"logged-out-description"},[_vm._m(0),_c('h3',[_vm._v("This video description was generated by AI. If it says something wild, let us know at support@otcmethod.com")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.metadata.description)}})]):_vm._e()]):_vm._e(),(!_vm.$auth.isAuthenticated())?_c('landing-footer'):_vm._e(),(_vm.$auth.isAuthenticated())?_c('div',{staticClass:"view-more"},[_c('videolist',{attrs:{"video-data":_vm.videos,"ajax-more":_vm.ajaxMore,"ajax-override":"/videos","limit":4,"section-title":'Latest Videos'}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("What topics does this lesson cover? "),_c('span',[_vm._v("AI Description")])])
}]

export { render, staticRenderFns }