<!-- Show bots and features of chat -->
<!-- Show Pat's content -->
<!-- Add discord invite link -->
<template>
  <section class="main-wrapper">
    <div class="discord">
      <div class="form" v-if="$auth.isAuthenticated()" v-on:submit.prevent="submitForm">
        <h3>Affiliate Program</h3>
        <h4>Sign up using the link below to get started. Once your affiliate account is created, use the dashboard link to access your unique affiliate link and share with friends.</h4>
        <div class="buttons">
          <a href="https://otcmethod.getrewardful.com/signup?_gl=1*6ukku8*_gcl_au*MTI2MTQ4MzMyMi4xNzA0Njc1MjA4*_ga*NzIxOTg3MzAzLjE3MDQ2NjM5OTc.*_ga_YJYFH7ZS27*MTcwNDY3ODMxMi4yLjEuMTcwNDY3ODQwNy4yNS4wLjA.&_ga=2.106870277.76522066.1704663997-721987303.1704663997" target="_blank"><button class="main-button"><ion-icon name="chatbubbles"></ion-icon>Affiliate Sign Up</button></a>
          <a href="https://otcmethod.getrewardful.com/login" target="_blank"><button class="main-button"><ion-icon name="chatbubbles"></ion-icon>Affiliate Dashboard</button></a>
        </div>
      </div>
      <div class="form" v-if="!$auth.isAuthenticated()" v-on:submit.prevent="submitForm">
        <h3>Earn Money With OTCMethod Affiliates</h3>
        <h4>Members can earn money by inviting others to join OTCMethod. Earn 30% per subscription you sign up using your affiliate link</h4>
        <button class="main-button" @click.prevent="$auth.login()"><ion-icon name="chatbubbles"></ion-icon>Join Now</button>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'affiliates',
    data() {
      return {
        discord: null,
        loading: false,
        link: null,
        error: null
      }
    }
  }
</script>

<style lang="less" scoped>
  .main-wrapper {
    display: flex;
    padding: 0;
    height: calc(100vh - 7rem);
    overflow: hidden;
    flex-direction: column;
  }
  .error {
    margin: .75rem 0 0 0;
    display: flex;
    font-size: .825rem;
    color: red;
    font-weight: 500;
  }
  .features-showcase {
    background-color: #c2c2c2;
    height: 75vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
  a {
    text-decoration: none;
  }
  .features {
    // flex: 0 0 50%;
    // flex-direction: column;
    // align-items: flex-end;
    // border-right: 1px solid #c2c2c2;
    // box-shadow: inset -10px 0 19px -11px rgba(0, 0, 0, 0.16);
    background: #fafafa;
    border-top: 1px solid #c2c2c2;
    box-shadow: inset 0 10px 19px -11px rgba(0, 0, 0, 0.16);
    margin: 4rem 0 0 0;
    padding: 4rem 0 0 0;
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    h1 {
      font-size: 2.5rem;
      margin: 3rem 0 3rem 0;
      color: #000;
      text-align: center;
      line-height: 4rem;
      font-weight: 800;
      text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    }
    .feature-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // width: 50%;
      border-radius: 10px;
      margin: 0 1rem 2rem 0;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
      border: 1px solid #d4d4d4;
      background-color: #ffffff;
      padding: 2rem;
      // flex: 0 0 calc(20% - 4.35rem);
      width: calc(50% - 4.65rem);
      &:nth-child(even) {
        margin-right: 0;
      }
      @media(max-width: 991px) {
        width: 100%;
        margin: 0 0 2rem 0;
      }
      strong {
        margin: 0 0 .5rem 0;
        color: #000;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        line-height: 2rem;
        text-align: left;
        font-weight: 600;
        ion-icon {
          margin: 0 .5rem 0 0;
          display: none;
        }
      }
      img {
        width: 100%;
        height: 250px;
        margin: 0 0 1rem 0;
        border-radius: 7px;
        object-fit: cover;
      }
      h2 {
        font-size: 1.25rem;
        margin: 0 0 .75rem 0;
        color: #000;
        align-self: center;
      }
      p {
        font-size: 1rem;
        margin: 0 0 0 0;
        font-weight: 400;
        color: #666;
        line-height: 1.75rem;
        text-align: left;
        span {
          border-radius: 5px;
          background: #f5f5f5;
          padding: 2px 5px;
          font-weight: 600;
        }
      }
    }
  }
  .discord {
    // flex: 0 0 100%;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    position: relative;
    .join-history {
      // position: fixed;
      bottom: 0;
      transform: translateY(calc(-50% + 149px + 1.5rem));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 250px;
      span {
        font-size: 0.75rem;
        color: #666;
        margin: 0 0 0.5rem 0;
      }
    }
    .form {
      // position: fixed;
      top: 50%;
      // transform: translateY(calc(-50%));
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      background: linear-gradient(324deg, #fafafa, #ffffff);
      border: 1px solid #c2c2c2;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.173);
      border-radius: 5px;
      align-self: center;
      .buttons {
        display: flex;
        gap: 1rem;
      }
      h3 {
        font-size: 1.5rem;
        margin: 0 0 .5rem 0;
        color: #000;
      }
      h4 {
        font-size: .825rem;
        font-weight: 400;
        margin: 0 0 2rem 0;
        max-width: 500px;
        line-height: 1.5rem;
        color: #666;
      }
      input {
        width: 50%;
        padding: 1rem;
        margin: 0 0 1rem 0;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }
</style>
