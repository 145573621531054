<template>
  <section>
    <p></p>
    <div class="features-list">
      <ul>
        <li>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="#79bfff45"/>
            <path d="M10 18L14.7222 24L27 13" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Learn how to use charts</span> in a practical way to help you understand market sentiment as opposed to trying to predict the future.</li>
        <li><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="#79bfff45"/>
            <path d="M10 18L14.7222 24L27 13" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg><span>Discover the true meaning</span> of filings, balance sheets, SEC actions, Secretary of State actions, and company announcements. </li>
        <li><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="#79bfff45"/>
            <path d="M10 18L14.7222 24L27 13" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg><span>Start doing DD the right way</span> using techniques that are used in top consulting firms around the world. </li>
        <li><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="#79bfff45"/>
            <path d="M10 18L14.7222 24L27 13" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg><span>Join a community of people</span> that have left behind the toxic environment of chat rooms and are taking trading into their own hands.</li>
        <li><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="7" fill="#79bfff45"/>
            <path d="M10 18L14.7222 24L27 13" stroke="#104475" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg><span>Participate in a livestream</span> every weeknight with a breakdown of the day’s action and watchlists for the coming day.</li>
      </ul>
    </div>
  </section>
</template>


<script>
export default {
  name: 'features'
}
</script>

<style lang="less" scoped>
  #nav-logo {
    text-align: left;
    font-size: 18px;
  }
  .features-list {
    @media(max-width: 868px) {
      display: none;
    }
  }
  ul {
    max-width: 300px;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    text-align: left;
    li {
      margin: 0 0 20px 0;
      position: relative;
      font-weight: 300;
      svg {
        position: absolute;
        left: -30px;
        top: -7px;
        width: 15px;
      }
      span {
        font-weight: 600;
      }
    }
  }
</style>

