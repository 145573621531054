<template>
  <section id="landing-header">
    <div id="landing-header-container" class="wrapper">
      <div id="call-to-action">
        <h1>Learn to Trade OTC’s With Confidence</h1>
        <p>Daily Videos, Private Chat Group, Live Streams and more. Start your trading education now.</p>
        <div>
          <button @click.prevent="login">Join OTC:Method</button>
          <router-link class="more-info" to="/about"><button>More Info</button></router-link>
        </div>
        <span>$64.99 per month, cancel anytime.</span>
      </div>
      <div id="video-section">
        <iframe src="https://player.vimeo.com/video/341491584" width="520" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
    <div id="video-bg"></div>
  </section>
</template>

<script>
export default {
  name: "landingHeader",
  methods: {
    login() {
      this.$auth.login();
    },
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 1200px;
  margin: 0 auto;
  @media(max-width: 1300px) {
      width: 992px;
  }
  @media(max-width: 991px) {
      width: 868px;
  }
  @media(max-width: 868px) {
      width: 676px;
  }
  @media(max-width: 676px) {
    width: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
#landing-header {
  padding: 7rem 0 4rem 0;
  background: #F2F2F2;
  position: relative;
  overflow-x: clip;
  #video-bg {
    position: absolute;
    right: 0;
    top: 5rem;
    width: 65%;
    height: 550px;
    background: #D8EAFF;
    opacity: .8;
    z-index: 1;
  }
  @media(max-width: 1100px) {
    padding: 5rem 0 7rem 0;
  }
  @media(max-width: 868px) {
    padding: 2rem 0 3rem 0;
  }
  #landing-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media(max-width: 868px) {
      flex-direction: column;
      align-items: flex-start;
    }
    #video-section {
      position: absolute;
      right: -7rem;
      top: 8rem;
      z-index: 2;
      animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      animation-delay: .3s;
      @media(max-width: 991px) {
        top: 10rem;
        right: -2rem;
      }
      @media(max-width: 868px) {
        position: static;
        margin: 2rem 0 0 0;
      }
      iframe {
        width: 613px;
        height: 341px;
        background: #e4e4e4;
        border: 1px solid #e3e3e3;
        box-sizing: border-box;
        box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.15);
        border-radius: 13px;
      }
      @media(max-width: 991px) {
        iframe {
          width: 420px;
          height: 257px;
        }
      }
    }
    #call-to-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      z-index: 2;
      @media(max-width: 868px) {
        width: 100%;
      }
      h1 {
        font-size: 5rem;
        line-height: 5.5rem;
        font-weight: 700;
        width: 80%;
        margin: 0 0 0 0;
        text-align: left;
        color: black;
        letter-spacing: -3px;
        animation: fadein 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        @media(max-width: 868px) {
          font-size: 4rem;
          width: 100%;
          line-height: 5rem;
        }
        @media(max-width: 568px) {
          font-size: 2.5rem;
          line-height: 3.5rem;
        }
      }
      p {
        width: 60%;
        line-height: 2.5rem;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: left;
        color: #4D4D4D;
        letter-spacing: -1px;
        animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation-delay: .2s;
        @media(max-width: 868px) {
          width: 100%;
        }
        @media(max-width: 568px) {
          font-size: 1.5rem;
          margin-top: 0;
        }
      }
      button {
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
        background: #58AAA3;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 10px 25px 10px 25px;
        border-radius: 50px;
        border: none;
        cursor: pointer;
        @media(max-width: 1100px) {
          margin: 0 auto;
        }
      }
      div {
        animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation-delay: .3s;
      }
      .more-info {
        button {
          margin: 0 0 0 1rem;
          background: #000;
          color: #FFF;
        }
      }
      span {
        margin: .75rem 0 0 0;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: 600;
        color: #6d6d6d;
        animation: fadein 500ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        animation-delay: .4s;
      }
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        li {
          position: relative;
          margin: 0 0 15px 0;
          &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: -10px;
            bottom: 0;
            height: 10px;
            width: 100%;
            background: rgba(121, 191, 255, 0.27);
          }
        }
      }
    }
    @media(max-width: 568px) {
      #video-section {
        iframe {
          width: 100%;
          height: 181px;
        }
      }
      ul {
        align-items: flex-start !important;
      }
      li {
        text-align: left;
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
