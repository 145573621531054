<template>
  <section>
    <form v-on:submit.prevent="submitForm">
      <div id="discord-card" class="card">
        <div>
          <h3>Join Discord</h3>
          <p>Enter your Discord Username and get an invite to our private chat group.</p>
          <input v-model="discord" type="text" placeholder="Discord Username">
        </div>
        <div class="toggle">
          <button v-if="!link" :disabled="loading" type="submit">Get Discord Link</button>
          <a target="_blank" v-if="link" :href="link">Join Group</a>
          <span v-if="error">{{ error }}</span>
        </div>
      </div>
    </form>
  </section>
</template>


<script>
import axios from 'axios';

export default {
  name: 'discord',
  data() {
    return {
      discord: null,
      error: null,
      success: null,
      loading: false,
      link: null
    }
  },
  methods: {
    submitForm() {
      if(this.discord) {
        this.loading = true;
        this.error = null;
        this.submitDiscord();
      }
    },
    submitDiscord() {
      if(localStorage.getItem('user')) {
        axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '') + '/api/user/discord',
          data: {
            discord: this.discord,
            userID: JSON.parse(localStorage.getItem('user'))['sub'],
            custID: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))['https://www.otcmethod.com/stripe_customer_id'] : null
          }
        }).then((response) => {
          this.link = response.data.invite;
          if(this.link) window.open(response.data.invite);
          else if (response.data.error) this.error = response.data.error;
        }).catch((err) => {
          this.error = err;
        });
      } else {
        this.error = 'No User Found';
      }
      this.loading = false;
    },
  }
}
</script>

<style lang="less" scoped>
  .card {
    border: 1px solid #EDEDED;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow:  0 1px 3px rgba(31, 31, 34, 0.11);
    padding: 25px 25px 25px 25px;
  }
  input {
    padding: 10px 15px 10px 15px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0 25px 0;
  }
  #discord-card {
    text-align: left;
    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #EDEDED;
      padding: 15px 0 0 0;
      button , a {
        margin: 0 0 0 0;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        border: none;
        background: none;
        color: #104475;
        cursor: pointer;
        padding: 2px 0 2px 0;
        transition: .2s all;
        &:hover {
          background: #79beff11;
          transition: .2s all;
        }
        span {
          font-weight: 900;
        }
      }
    }
    h3 {
      margin: 0 0 15px 0;
    }
  }
</style>
