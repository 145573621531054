<template>
  <section>
    <div class="wrapper">
      <h1 class="main-title">Latest Articles</h1>
      <latest :article-data="articles[0]" />
      <list :article-data="articles.slice(1)" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import latest from '@/components/articles/latest.vue'
import list from '@/components/articles/list.vue'

export default {
  name: 'Articles',
  components: {
    latest,
    list
  },
  computed: mapState({
    articles: state => state.articles.latest
  })
}
</script>

<style lang="less" scoped>
.wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1.5rem 0 .5rem 0;
    color: #000;
    position: relative;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    text-align: left;
    line-height: 2.5rem;
  }
  .line {
    width: 250px;
    height: 2px;
    background: #F4F4F4;
    display: block;
    margin: 35px auto 0 auto;
  }
</style>
