<template>
  <section>
    <div id="card-element"></div>
    <div id="card-errors" role="alert"></div>
  </section>
</template>

<script>
export default {
  name: 'stripe',
  props: ['formId'],
  mounted() {
    this.stripe = Stripe('pk_live_IqoZJVVaWhZH4HPjx5Z7KE5d00VUYHRLPB');
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card');
        this.card.mount('#card-element');
        this.card.addEventListener('change', function(event) {
          let displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
  },
  methods: {
    stripeTokenHandler() {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById(this.formId);
      var hiddenInput = document.createElement('input');
      var vm = this;

      this.stripe.createToken(this.card).then(function(result) {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          vm.$emit('stripeError' , result.error.message);
        } else {
          // Send the token to your server.
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('name', 'stripeToken');
          hiddenInput.setAttribute('value', result.token.id);
          form.appendChild(hiddenInput);

          vm.$emit('stripeToken' , result.token.id);
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
section {
  width: 100%;
  #card-element {
    border-radius: 4px;
    border: 1px solid #cccccc5d;
    padding: 15px 10px 15px 10px;
    font-size: 16px;
    box-sizing:border-box;
  }
}
</style>
