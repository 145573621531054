import videos from '../../api/videos';

const state = {
  videos: {
    latest: [],
    essentials: []
  },
  playlists: [],
  tags: [],
  taglist: []
}

const actions = {
  getLatestVideos({ commit } , params) {
    videos.getLatestVideos(params.max , params.more , params.playlists , params.tags).then((response) => {
      commit('setVideos' , response.data);
    })
    .catch((err) => {
      this.error = err;
    });
  },
  getPlaylists({ commit }) {
    videos.getPlaylists().then((response) => {
      commit('setPlaylists' , response.data);
    })
    .catch((err) => {
      this.error = err;
    });
  },
  getTaggedVideos({ commit } , params) {
    videos.getLatestVideos(params.max , params.more , null , params.tags).then((response) => {
      commit('setTags' , response.data);
    }).catch((err) => {
      this.error = err
    });
  },
  getTags({ commit }) {
    videos.getTags().then((response) => {
      commit('setTaglist' , response.data)
    }).catch((err) => {
      this.error = err;
    });
  }
}

const mutations = {
  setVideos(state , videos) {
    Object.keys(videos).forEach((key) => {
      state.videos[key] = typeof state.videos[key] !== 'undefined' ? state.videos[key].concat(videos[key]) : state.videos[key] = videos[key];
    });
  },
  setTags(state , videos) {
    if(Array.isArray(state.tags)) {
      state.tags = {};
    }
    videos.latest.forEach((element) => {
      for(let i = 0, l = element.tags.length; i < l; i++) {
        state.tags[element.tags[i].canonical] = typeof state.tags[element.tags[i].canonical] !== 'undefined' ? state.tags[element.tags[i].canonical].concat([element]) : state.tags[element.tags[i].canonical] = [element];
        console.log(state.tags);
      }
    });
  },
  setPlaylists(state , playlists) {
    // state.playlists = playlists.filter(x => x.title === 'Essentials');
    state.playlists = playlists;
  },
  setTaglist(state , tags) {
    state.taglist = tags;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}