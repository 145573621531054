<template>
  <section>
    <div class="wrapper" v-show="$auth.isAuthenticated()">
      <h2 class="main-title">Latest Audio</h2>
      <list :audio-data="audio" />
    </div>
    <div v-show="!$auth.isAuthenticated()">
      <landing />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import list from '@/components/audio/list.vue'
import landing from '@/components/audio/landing.vue'

export default {
  name: 'Audio',
  components: {
    list,
    landing
  },
  computed: mapState({
    audio: state => state.audio.latest
  })
}
</script>

<style lang="less" scoped>
.main-title {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
}
.wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  h2 {
    font-size: 28px;
    margin-top: 45px;
  }
  .line {
    width: 250px;
    height: 2px;
    background: #F4F4F4;
    display: block;
    margin: 35px auto 0 auto;
  }
</style>
