import journal from '../../api/journal';

const state = {
  notes: [],
  trades: [],
  cache: {}
}

const actions = {
  getLatestNotes({ commit }) {
    journal.getNotes().then((response) => {
      commit('setNotes' , response.data)
    })
    .catch((err) => {
      this.error = err
    });
  },
  getLatestTrades({ commit }) {
    journal.getTrades().then((response) => {
      commit('setTrades' , response.data)
    })
    .catch((err) => {
      this.error = err
    });
  },
}

const mutations = {
  setNotes(state , notes) {
    state.notes = notes.notes
  },
  setTrades(state , trades) {
    state.trades = trades.trades
  },
  setCache(state , notes) {

  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}