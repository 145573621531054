<template>
  <section>
    <div id="about-page">
      <div class="wrapper">
        <section id="about-intro">
          <h1>Your search is over. <br />Learn the method to be a self sufficient trader today.</h1>
          <div>
            <button @click.prevent="login">Register Now →</button>
          </div>
        </section>
      </div>
    </div>
    <section id="about-videos">
      <div class="wrapper">
        <div class="inner">
          <div class="text">
            <h2>Our Rock Bottom Entries Include the Hottest Tickers on the OTC Market.</h2>
          </div>
          <div class="ticker-list">
            <div>
              <span>GRNF</span>
              <span>ALPP</span>
              <span>SECI</span>
              <span>WDBG</span>
            </div>
            <div>
              <span>SIML</span>
              <span>LFAP</span>
              <span>SHMP</span>
              <span>UNRG</span>
            </div>
            <div>
              <span>NNRX</span>
              <span>HDVY</span>
              <span>CRSM</span>
              <span>TTCM</span>
            </div>
            <div>
              <span>CDIX</span>
              <span>LCTZ</span>
              <span>BCCI</span>
              <span>KALY</span>
            </div>
            <div>
              <span>NDYN</span>
              <span>AVOZ</span>
              <span>CYIO</span>
              <span>HENC</span>
            </div>
            <div>
              <span>ARVY</span>
              <span>EMBR</span>
              <span>PCTL</span>
              <span>PURA</span>
            </div>
            <div>
              <span>KDCE</span>
              <span>FLHI</span>
              <span>FZRO</span>
              <span>GLTC</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-service">
      <div>
        <h4>Exclusive Articles</h4>
        <p>Learn key trading concepts that enrich the experience of trading day to day and help you master market principles.</p>
      </div>
      <div>
        <h4>Daily Videos</h4>
        <p>Get a rundown of the market each day with a comprehensive recap and DD covering a wide range of subjects.</p>
      </div>
      <div>
        <h4>Winning Strategy</h4>
        <p>Learn self-reliance through education, the most effective way to read filings, charts, share structure, and catalysts.</p>
      </div>
    </section>
    <section id="about-interface" class="about-card">
      <h2>Video Library and Filters</h2>
      <p>Search through our massive catalogue of past live streams and filter by the topics you want to learn more about. You can follow our pre-made essentials playlists or search through our content library to find the videos you're looking for.</p>
      <img src="../assets/img/library.png">
    </section>
    <section id="about-question">
      <p>The next person to discover the hottest penny stock runner might be you.</p>
    </section>
    <section id="about-call">
      <div class="call-frame">
        <h3>Get started with your penny stock education now!</h3>
        <p> The information is available to anyone willing to look and OTCMethod was created to give you the skills to find it first as opposed to receiving it from a guru.</p>
        <button @click.prevent="login">Get Started Now</button>
      </div>
      <div class="call-frame">
        <h3>Learn More.</h3>
        <p>Get a preview of some of our exclusive content.</p>
        <router-link to="/articles"><button>Articles</button></router-link>
      </div>
    </section>
  </section>
</template>

<script>

export default {
  name: 'About',
  methods: {
    login() {
      this.$auth.login();
    }
  }
}
</script>

<style lang="less" scoped>
.main-title {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
}
#about-page {
  background: #000b16;
}
.wrapper {
    width: 1200px;
    margin: 0 auto;
    @media(max-width: 1300px) {
        width: 992px;
    }
    @media(max-width: 1092px) {
        width: 768px;
    }
    @media(max-width: 868px) {
        width: 576px;
    }
    @media(max-width: 676px) {
      width: auto;
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
  #about-intro {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 150px 0 150px 0;
    h1 {
      font-size: 5rem;
      font-weight: 800;
      margin: 0 0 1rem 0;
      text-align: left;
      color: #FFFFFF;
      @media(max-width: 868px) {
        font-size: 3rem;
      }
      @media(max-width: 568px) {
        font-size: 2rem;
      }
    }
    div {
      button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: #5b7b98;
        font-size: 4rem;
        font-weight: 800;
        font-family: 'Inter', sans-serif;
        padding: 0 0 0 0;
        cursor: pointer;
        @media(max-width: 868px) {
          font-size: 2rem;
        }
        @media(max-width: 568px) {
          font-size: 1.5rem;
        }
      }
    }
  }
  #about-videos {
    padding: 5rem 0 5rem 0;
    .inner {
      width: 70%;
      margin: 0 auto;
      @media(max-width: 868px) {
        width: auto;
      }
      span {
        display: block;
      }
    }
    .ticker-list {
      display: flex;
      justify-content: space-between;
      div {
        span {
          margin: 0 0 2rem 0;
          font-weight: 600;
          font-size: 1.25rem;
          color: #979797;
          @media(max-width: 868px) {
            font-size: 1rem;
          }
          @media(max-width: 568px) {
            font-size: .5rem;
          }
        }
      }
    }
    h2 {
      font-size: 3rem;
      padding: 0 0 1rem 0;
      border-bottom: 1px solid #EAEAEA;
      @media(max-width: 868px) {
        font-size: 2rem;
      }
      @media(max-width: 568px) {
        font-size: 1.5rem;
      }
    }
    .row-two {
      justify-content: space-around;
    }
  }
  .about-card {
    margin: 2rem;
    padding: 2rem;
    background: #f0f0f0;
  }
  #about-interface {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 500;
      font-size: 2rem;
      margin: 1rem 0 1rem 0;
      @media(max-width: 568px) {
        font-size: 1.5rem;
      }
    }
    p {
      width: 800px;
      line-height: 35px;
      font-size: 20px;
      @media(max-width: 868px) {
        width: 90%;
      }
      @media(max-width: 868px) {
        font-size: 18px;
      }
      @media(max-width: 568px) {
        font-size: 14px;
      }
    }
    img {
      width: 80%;
    }
  }
  #about-service {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    margin: 2rem auto 9rem auto;
    @media(max-width: 868px) {
      flex-direction: column;
    }
    div {
      flex: 0 0 25%;
      box-shadow: 0 3px 7px rgba(0,0,0,.1);
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      align-items: flex-start;
      padding: 2rem;
      @media(max-width: 868px) {
        padding: 1rem;
        flex: 0 0 22%;
        margin: 0 0 2rem 0;
      }
      h4 {
        margin: 0 0 1rem 0;
        font-size: 22px;
      }
      p {
        margin: 0 0 0 0;
        text-align: left;
        line-height: 25px;
      }
    }
  }
  #about-question {
    padding: 6rem 0 6rem 0;
    p {
      font-size: 22px;
      font-weight: 500;
      @media(max-width: 868px) {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  #about-call {
    display: flex;
    justify-content: center;
    @media(max-width: 1100px) {
      flex-direction: column;
    }
    .call-frame {
      width: 50%;
      padding: 5rem 7rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @media(max-width: 1100px) {
        width: auto;
      }
      @media(max-width: 868px) {
        width: auto;
        padding: 3rem 5rem;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 800;
        text-align: left;
        color: #FFFFFF;
        margin: 0 0 1rem 0;
        @media(max-width: 868px) {
          font-size: 1.5rem;
        }
      }
      p {
        font-size: 22px;
        color: #FFFFFF;
        font-weight: 500;
        text-align: left;
        line-height: 35px;
        margin: 0 0 2rem 0;
        @media(max-width: 868px) {
          font-size: 18px;
        }
      }
      button {
        float: left;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
        background: #104475;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 15px 27px 15px 27px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
      }
      &:first-child {
        background:#5b7b98;
        button {
          background: #FFFFFF;
          color: #1d1d1d;
        }
      }
      &:last-child {
        background: #f0f0f0;
        h3 , p {
          color: #2c3e50;
        }
      }
    }
  }
</style>
